import { useAuth } from "../context/AuthProvider";
import { useSWR } from "../hooks/useSWR";
import { endpoints } from "../services/api";
import { TenantDetails } from "../stories/Tenants";
import { ITenant } from "../stories/Tenants/api";

export default function TenantsInfoScreen() {
  const { user } = useAuth();
  const { data, loading, error, mutate } = useSWR<{ data: ITenant }>(
    user?.tenantId ? endpoints.tenants.get(user?.tenantId) : null
  );

  return (
    <TenantDetails
      data={data?.data || undefined}
      loading={loading}
      mutate={mutate}
    />
  );
}
