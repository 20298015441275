import { useParams } from "react-router-dom";
import SecurityPolicyDetails from "../stories/PolicyDetails/SecurityPolicyDetails";
import { useFetchPolicy } from "../stories/SecurityPolicy";

export default function PolicyDetailsScreen() {
  const { policyId } = useParams();
  const { data, mutate, loading } = useFetchPolicy(policyId);

  return (
    <div className="flex h-auto min-h-[calc(100vh-144px)] flex-col gap-2 rounded-lg border-0">
      <SecurityPolicyDetails
        loading={loading}
        data={data?.data}
        refetch={mutate}
      />
    </div>
  );
}
