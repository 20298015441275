import { useState, useEffect } from "react";
import { CheckIcon } from "../assets";

export interface ICustomCheckProps {
  label: string;
  onSelect: Function;
  active: boolean;
  selectAll: boolean;
  defaultCheckedItems?: string[];
}

export const CustomCheck = ({
  label,
  onSelect,
  active,
  selectAll,
  defaultCheckedItems = [], // Default value for defaultCheckedItems prop
}: ICustomCheckProps) => {
  const [checked, setChecked] = useState(defaultCheckedItems.includes(label)); // Check if label is in defaultCheckedItems
  const handleCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
    onSelect({ [label]: e.target.checked }, e);
    setChecked(e.target.checked);
  };

  useEffect(() => {
    setChecked(defaultCheckedItems.includes(label));
  }, [defaultCheckedItems, label]);

  return (
    <label
      htmlFor={label}
      className="group flex w-full cursor-pointer items-center gap-3 px-4 py-3 text-left text-sm font-normal capitalize  text-black hover:bg-indigo-50 hover:font-semibold hover:text-theme-purple"
    >
      <div className="flex h-4 w-4 items-center">
        {checked ? (
          <div>
            <CheckIcon />
          </div>
        ) : (
          <div className="h-full w-full rounded-full border border-theme-gray-border" />
        )}
      </div>
      <input
        id={label}
        type="checkbox"
        checked={checked}
        onChange={handleCheck}
        className="hidden"
      />
      {label}
    </label>
  );
};
