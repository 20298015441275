import { useState, useEffect } from "react";
import { Updater } from "@tanstack/react-table";
import clsx from "clsx";
import { Icon, ICON_NAME } from "../../Icon";

export interface IPaginateProps {
  total: number;
  current: number;
  prevLabel?: string;
  nextLabel?: string;
  skip?: number;
  perPage: number;
  paginationHandler: (updater: Updater<number>) => void;
  classNames?: string;
  handleNext: () => void;
  handlePrev: () => void;
  isNextDisabled: boolean;
  isPrevDisabled: boolean;
  showPageNo?: boolean;
}

export function PaginationButton({
  total,
  current = 1,
  perPage = 10,
  prevLabel = "Previous",
  nextLabel = "Next",
  handleNext,
  handlePrev,
  isNextDisabled,
  isPrevDisabled,
  paginationHandler,
  classNames,
  showPageNo = false,
}: IPaginateProps) {
  const [buttonList, setButtonList] = useState<(string | number)[]>([]);

  useEffect(() => {
    const numberOfPages = Math.ceil(total);

    const buttonsArr = Array.from(
      { length: numberOfPages },
      (_, index) => index + 1
    );
    setButtonList(buttonsArr);
  }, [perPage, total]);

  return total > 1 ? (
    <div className={clsx('flex w-full  border-t border-theme-gray-100 mt-auto', showPageNo ? 'justify-between' : 'justify-start gap-4')}>
      <button
        aria-label="Previous page"
        type="button"
        disabled={isPrevDisabled}
        className={clsx(
          "flex cursor-pointer  items-end gap-3 pt-4 text-sm text-theme-gray disabled:cursor-not-allowed disabled:opacity-50",
          classNames
        )}
        onClick={handlePrev}
      >
        <Icon
          name={ICON_NAME.arrowLeft}
          weight="bold"
          className=" h-4 w-4 font-semibold text-theme-gray self-center"
        />
        {prevLabel}
      </button>

      {showPageNo && (
        <div>
          {buttonList?.length > 1 ? (
            buttonList.map((item, index) => (
              <button
                key={index}
                type="button"
                aria-label={`Go to page ${item}`}
                disabled={current === item}
                className={clsx(
                  current === item
                    ? "border-t-2 border-theme-purple font-semibold text-theme-purple"
                    : "bg-white text-theme-gray",
                  "px-4 pb-0 pt-4 text-sm delay-100 ease-in-out",
                  classNames
                )}
                onClick={() => paginationHandler(Number(item))}
              >
                {item}
              </button>
            ))
          ) : (
            <button
              key={current}
              aria-label={`Go to page ${current}`}
              disabled={true}
              type="button"
              className={clsx(
                "border-t-2 border-theme-orange px-4 pb-0 pt-4 text-sm font-semibold text-theme-orange delay-100 ease-in-out",
                classNames
              )}
            >
              {current}
            </button>
          )}
        </div>
      )}

      <button
        aria-label="Next page"
        type="button"
        disabled={isNextDisabled}
        className={clsx(
          "flex items-end gap-3 pt-4 text-sm text-theme-gray disabled:cursor-not-allowed disabled:opacity-50",
          classNames
        )}
        onClick={handleNext}
      >
        {nextLabel}
        <Icon
          name={ICON_NAME.arrowRight}
          weight="bold"
          className="h-4 w-4 text-theme-gray"
        />
      </button>
    </div>
  ) : null;
}
