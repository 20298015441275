export interface ISideBarProfile {
  name: string;
  href: string;
  menu?: Array<{ name: string; href: string }>;
}

export interface IUserDetails {
  id?: number;
  displayName: string;
  last_name?: string;
  email: string;
  userName?: string;
  tenantId: string;
  roles?: string[];
}

const initials = (name: string) =>
  name
    .split(" ")
    .map((words: string) => words[0])
    .join("")
    .toUpperCase();

export interface IProfileAvatarProps {
  user?: Partial<IUserDetails> & Partial<ISideBarProfile>;
  userName?: string;
  imageUrl?: string;
  component?: React.ReactNode;
}

const ProfileAvatar = (props: IProfileAvatarProps) => {
  const { user, imageUrl, component } = props;

  return (
    <div className="flex w-fit cursor-pointer items-center gap-4 text-sm font-medium text-[#141414]">
      <div className="hidden justify-end text-end md:block">
        {(user?.displayName || user?.last_name) && (
          <p>
            {user.displayName && user.last_name
              ? `${user.displayName} ${user.last_name}`
              : user.displayName || user.last_name}
          </p>
        )}
        {/* {user?.tenantId && (
          <p className="text-[10px]">
            <span className="font-bold">Tenant ID:</span> {user.tenantId}
          </p>
        )} */}
      </div>
      <button
        type="button"
        className="inline-flex h-10 w-10 items-center justify-center rounded-full bg-theme-purple md:h-12 md:w-12"
      >
        {imageUrl ? (
          <img
            alt="profile_avatar"
            src={imageUrl}
            className="h-full w-full rounded-full object-cover"
          />
        ) : (
          (user?.displayName || user?.last_name) && (
            <p className="text-center font-semibold leading-none text-white">
              {`${initials(user.displayName!)}${initials(user?.last_name || "")}`}
            </p>
          )
        )}

        {component && component}
      </button>
    </div>
  );
};
export { ProfileAvatar };
