import { Suspense, useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../context/AuthProvider";

export const ProtectedLayout = () => {
  const { auth } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!auth?.token) {
      setTimeout(() => {
        navigate("/");
      }, 100);
    }
  }, [auth, navigate]);

  return (
    <div>
      <Outlet />
    </div>
  );
};
