import { createColumnHelper, Updater } from "@tanstack/react-table";
import { ICON_NAME, Icon } from "../Icon";
import { Table } from "../Table";
import { MenuDropdown } from "../MenuDropdown";
import { TableHeader } from "../TableHeader";
import { useNavigate } from "react-router-dom";
import { notifyError, notifySuccess } from "../../utils/notify";
import { useState } from "react";
import ActionModal from "../ActionModal/ActionModal";
import { ModalType } from "../ActionModal";
import { concat } from "lodash";
import { Loader, LOADER_SIZE } from "../Loader";
import { PaginationButton } from "../Pagination";
import { Placeholder } from "../Placeholder";
import { createPolicy, deletePolicy, IPolicy } from "./api";
import { Formik } from "formik";
import * as Yup from "yup";

export const SecurityPolicy = ({
  data,
  refetch,
  isLoading,
  currentPage,
  totalPages,
  size,
  setCurrentPage,
  hasNext,
  tenantId,
}: {
  data?: IPolicy[];
  refetch: any;
  isLoading: boolean;
  currentPage: number;
  totalPages: number;
  size: number;
  setCurrentPage: (page: number) => void;
  hasNext: boolean;
  tenantId: string;
}) => {
  const [showModal, setShowModal] = useState(false);
  const [currentModalType, setCurrentModalType] = useState<ModalType | null>(
    null
  );
  const [currentRow, setCurrentRow] = useState<IPolicy | null>(null);

  const [submitting, setSubmitting] = useState(false);
  const navigate = useNavigate();

  const columnHelper = createColumnHelper<IPolicy>();
  const columns = [
    columnHelper.accessor("policyName", {
      header: () => "Policy Name",
      cell: (info) => <div className="font-medium">{info.getValue()}</div>,
    }),
    columnHelper.accessor("description", {
      header: () => "Description",
    }),
    columnHelper.accessor("action", {
      header: () => "Action",
      cell: (info) => (
        <MenuDropdown
          onSelect={(item: string[]) => {
            if (item[0] === "Delete") {
              setCurrentRow(info.row.original);
              handleOpenModal(ModalType.DELETE_POLICY);
            }
            // else if (item[0] === "Set as default") {
            // { policyName: "", description: "" }
            //   navigate(`/sites/add-site?id=${siteId}&edit=true`);
            // }
          }}
          base={
            <Icon
              className="h-8 w-8 self-center rounded-lg p-1 text-center hover:cursor-pointer hover:bg-theme-gray-250"
              name={ICON_NAME.menuDots}
            />
          }
          startFrom="right"
          disabledItems={
            info.row.original?.isEnforced || info.row.original?.isDefaultPolicy
              ? ["Delete"]
              : undefined
          }
          items={["Delete"]}
        />
      ),
    }),
  ];

  const handleOpenModal = (modalType: ModalType.DELETE_POLICY) => {
    if (modalType === ModalType.DELETE_POLICY) {
      setCurrentModalType(modalType);
    }
    setShowModal(true);
  };

  const updateQueryParams = (pageNumber: number) => {
    navigate(`?page=${pageNumber}`);
  };

  const handlePageChange = (updater: Updater<number>) => {
    const nextPage =
      typeof updater === "function" ? updater(currentPage) : updater;
    setCurrentPage(nextPage);
    updateQueryParams(nextPage);
  };

  const handleNextPage = () => {
    // if (currentPage < totalPages) {
    const nextPage = currentPage + 1;
    setCurrentPage(nextPage);
    updateQueryParams(nextPage);
    // }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      const prevPage = currentPage - 1;
      setCurrentPage(prevPage);
      updateQueryParams(prevPage);
    }
  };

  const handleConfirm = async (values: IPolicy) => {
    setSubmitting(true);

    if (currentModalType === ModalType.ADD_POLICY) {
      if (values.applyGlobally) {
        setSubmitting(false);
        return setCurrentModalType(ModalType.APPLY_GLOBAL_POLICY);
      } else {
        await addPolicy(values);
      }
    } else if (currentModalType === ModalType.APPLY_GLOBAL_POLICY) {
      await addPolicy(values);
    } else if (
      currentModalType === ModalType.DELETE_POLICY &&
      currentRow?.policyId
    ) {
      await deletePolicy(currentRow.policyId);
      notifySuccess(
        `Security Policy ${values.policyName} was deleted successfully`
      );
      refetch();
    }
    setSubmitting(false);
    setShowModal(false);
    setCurrentRow(null);
  };
  const addPolicy = async (values: IPolicy) => {
    const payload: IPolicy = {
      policyName: values.policyName,
      status: "active",
      protectionMode: values.protectionMode,
      applyGlobally: values.applyGlobally,
      isEnforced: false,
      description: values.description,
      tenantId: tenantId,
      version: "1.0",
    };
    try {
      const { data, error } = await createPolicy(payload);
      if (error) {
        notifyError(
          "We couldn't add the Security Policy. Please review the information and try again."
        );
        return console.log(error);
      }
      notifySuccess(`Security Policy ${payload.policyName} was added successfully`);
      refetch();
      // navigate("/sites", { state: { refresh: true } });
    } catch (error) {
      console.error(error);
    }
  };

  const handleCancel = () => {
    if (currentModalType === ModalType.APPLY_GLOBAL_POLICY) {
      return setCurrentModalType(ModalType.ADD_POLICY);
    }
    setShowModal(false);
    setTimeout(() => {
      setCurrentRow(null);
    }, 300);
  };

  const validationSchema = Yup.object().shape({
    policyName: Yup.string().required("Policy Name is required"),
    description: Yup.string().required("Description is required"),
    applyGlobally: Yup.boolean(),
    protectionMode: Yup.string().oneOf(
      ["quarantine", "inoculate"],
      "Invalid protection mode"
    ),
  });

  return (
    <div className="m-4 flex h-auto min-h-[calc(100vh-230px)] flex-col gap-4 rounded-lg border-0 bg-white p-4 md:m-6 md:p-6">
      {isLoading ? (
        <div className="flex h-full flex-1 flex-col items-center justify-center gap-6">
          <Loader
            size={LOADER_SIZE.lg}
            className="h-10 w-10 self-center text-theme-purple"
          />
        </div>
      ) : (
        <Table
          headerComponent={(e) => (
            <TableHeader
              heading="Security Policies"
              columnData={e}
              headerActionItemsItems={[
                {
                  children: "Add Policy",
                  kind: "secondary",
                  size: "sm",
                  className: "md:!px-12",
                  onClick: () => {
                    setCurrentModalType(ModalType.ADD_POLICY);
                    setShowModal(true);
                  },
                },
              ]}
              showSearchField={false}
            />
          )}
          columns={columns}
          data={data ?? []}
          onClick={(e: any, row: any, cell: any) => {
            if (cell.column.id !== "action") {
              if (row.original?.id) {
                navigate(
                  `/security-policies/${concat(row.original?.policyId)}`
                );
              }
            }
          }}
          emptyTablePlaceholder={
            <Placeholder
              label="No Policies found"
              description="There are no policies created by this tenant"
            />
          }
        />
      )}
      <PaginationButton
        total={1}
        current={currentPage}
        prevLabel="Previous"
        nextLabel="Next"
        // skip: 2,
        perPage={size}
        paginationHandler={handlePageChange}
        handleNext={handleNextPage}
        handlePrev={handlePrevPage}
        isNextDisabled={!hasNext}
        isPrevDisabled={currentPage === 1}
      />
      <Formik
        initialValues={{
          policyName: "",
          description: "",
          applyGlobally: false,
          protectionMode: "quarantine",
          status: "",
        }}
        validationSchema={
          currentModalType === ModalType.DELETE_POLICY
            ? undefined
            : validationSchema
        }
        onSubmit={handleConfirm}
        enableReinitialize={true}
      >
        {(props) => (
          <ActionModal
            modalType={currentModalType}
            isOpen={showModal}
            setIsOpen={setShowModal}
            dialogClassName="!w-full"
            onConfirm={props.submitForm}
            onCancel={handleCancel}
            entityName={
              currentModalType === ModalType.APPLY_GLOBAL_POLICY
                ? props.values.policyName
                : currentRow?.policyName || "this policy"
            }
            props={props}
            loading={submitting}
            actionButtonTitles={
              currentModalType === ModalType.ADD_POLICY
                ? { confirm: "Continue", cancel: "Cancel" }
                : {
                    confirm: "Proceed",
                    cancel: "Cancel",
                  }
            }
          />
        )}
      </Formik>
    </div>
  );
};
