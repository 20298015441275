import { scaleTime } from "d3-scale";
import { timeDay, timeHour } from "d3-time";
import moment from "moment";
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { TickFormatter } from "recharts/types/cartesian/CartesianAxis";
import { graphColors } from "../../../utils/const";
import { formatMemoryValue } from "../../../utils/helper";
import { GraphHeader, IGraphHeader } from "../GraphHeader";
import CustomTooltip from "../Tooltip";

export interface ILineGraph extends IGraphHeader {
  data: Object[];
  xAxisKey: string;
  yAxisKeys: string[];
  showLegend?: boolean;
  yAxisNames?: string[];
  xAxisUnit?: string;
  yAxisUnit?: string;
  yAxisFormatterFxn?: TickFormatter;
  information?: {
    heading: string;
    details: string;
  };
  domain?: any[];
  loading?: Boolean;
}

interface IDataItem {
  [key: string]: number;
}
const getNestedValue = (obj: any, path: string): number => {
  const value = path.split(".").reduce((acc, part) => acc && acc[part], obj);
  return typeof value === "string" ? parseFloat(value) : value || 0;
};
const getMaxValue = (data: IDataItem[], yAxisKeys: string[]): number => {
  const val = Math.max(
    ...data.map((item) =>
      Math.max(...yAxisKeys.map((key) => getNestedValue(item, key) || 0))
    )
  );
  return val;
};

const roundToNearestNiceNumber = (value: number): number => {
  const niceNumbers = [2, 4, 8, 12, 16, 32, 64, 128, 256, 512, 1024];
  const closestNiceNumber = niceNumbers.reduce((prev, curr) => {
    return Math.abs(curr - value) < Math.abs(prev - value) ? curr : prev;
  });
  return closestNiceNumber;
};

const getTicks = (data: any, domain: any[]) => {
  // if (!data || !data.length) {
  //   return [];
  // }
  // To Scale based on hour or day, for now keeping Hours is hours diff is less than 24
  const timeScaleType =
    moment(domain[1]).diff(domain[0], "hours") <= 24 ? timeHour : timeDay;

  const scale = scaleTime().domain(domain).range([0, 1]);
  const ticks = scale.ticks(timeScaleType);

  return ticks.map((entry) => +entry);
};

const dateFormat = (time: any, domain: any[]) => {
  const showHours = Boolean(moment(domain[1]).diff(domain[0], "hours") <= 30);

  return moment(time).format(showHours ? "H:mm" : "MM/DD");
};
const getTicksData = (data: any, ticks: any) => {
  const newData = [...data];
  // if (!data || !data.length) {
  //   return [];
  // }
  const dataMap = new Map(newData.map((i: any) => [i.timestamp, i]));

  ticks.forEach(function (item: any, index: number, array: []) {
    // "Rechart" adjust itself based on the data available .
    // So, Adding fake data of starting and ending date.
    if (!dataMap.has(item)) {
      newData.push({ timestamp: item });
    }
  });

  return newData.sort((a: any, b: any) => a.timestamp - b.timestamp);
};
export const LineGraph = ({
  heading,
  subText,
  total,
  data,
  xAxisKey,
  xAxisUnit = "",
  yAxisUnit = "",
  yAxisKeys,
  yAxisNames,
  showLegend = true,
  yAxisFormatterFxn,
  information,
  domain = [],
  loading,
}: ILineGraph) => {
  const maxValue = getMaxValue(data as any, yAxisKeys);

  // const [isAnimating, setIsAnimating] = useState(true);
  // const yAxisMaxValue = roundToNearestNiceNumber(maxValue);
  const yAxisMaxValue = maxValue === 0 ? 2 : maxValue;
  const ticksArr = getTicks(data, domain);

  // Finding the days between the start and End Date
  const days = moment(domain[1]).diff(domain[0], "days");

  const xAxisInterval =
    days >= 28 ? 3 : days >= 14 ? 1 : days >= 7 ? 0 : days <= 2 ? 3 : 1;

  return (
    <div>
      <GraphHeader
        heading={heading}
        subText={subText}
        total={total}
        information={information}
      />
      <ResponsiveContainer height={450} width="100%">
        <LineChart
          height={300}
          data={getTicksData(data, ticksArr)}
          margin={{
            top: 10,
            right: 30,
            left: 20,
            bottom: 10,
          }}
        >
          {showLegend && (
            <Legend
              align="left"
              verticalAlign="top"
              // iconType="circle"
              iconSize={10}
              layout="horizontal"
              height={40}
              wrapperStyle={{ top: "4px", left: "12px" }}
            />
          )}
          <CartesianGrid strokeDasharray="9" vertical={false} />
          <XAxis
            dataKey={xAxisKey}
            axisLine={false}
            className="text-sm text-theme-gray"
            tickFormatter={(v) => dateFormat(v, domain)}
            // minTickGap={40}
            scale={"linear"}
            tickCount={ticksArr.length}
            ticks={ticksArr}
            // domain={["auto", "auto"]}
            interval={xAxisInterval}
          >
            {/* {Array.isArray(data) && data.length <= 0 && !isAnimating && (
              <Label
                value="No Data available in selected time range"
                offset={200}
                position="top"
              />
            )} */}
          </XAxis>

          {yAxisKeys.map((yAxisKey, index) => (
            <YAxis
              key={index}
              dataKey={yAxisKey}
              domain={[0, yAxisMaxValue]}
              axisLine={false}
              className="text-sm text-theme-gray"
              tickFormatter={(value, index) =>
                yAxisFormatterFxn
                  ? yAxisFormatterFxn(value, index)
                  : formatMemoryValue(value, yAxisUnit)
              }
              allowDecimals={false}

              // onAnimationStart={(e) => {
              //   setIsAnimating(true);
              // }}
            />
          ))}
          <Tooltip
            content={
              <CustomTooltip
                colors={graphColors}
                yAxisUnit={yAxisUnit}
                formatter={(value, index) =>
                  yAxisFormatterFxn
                    ? yAxisFormatterFxn(value, index)
                    : formatMemoryValue(value, yAxisUnit)
                }
              />
            }
          />
          {!loading &&
            Array.isArray(data) &&
            data.length > 0 &&
            yAxisKeys.map((yAxisKey, index) => (
              <>
                {/* <Line
                  key={index + "idle"}
                  connectNulls={true}
                  type="monotone"
                  dataKey={yAxisKey}
                  stroke={graphColors[index % graphColors.length]}
                  activeDot={{ r: 8 }}
                  strokeWidth={1}
                  strokeDasharray="5 4"
                  dot={{ r: 0 }}
                  opacity={1}
                  name={yAxisNames && yAxisNames[index]}
                  legendType="none"
                  tooltipType="none"
                  // onAnimationEnd={() => {
                  //   setIsAnimating(false);
                  // }}
                  // onAnimationStart={() => {
                  //   setIsAnimating(true);

                  // }}
                  // onAnimationStart={(e) => {
                  //   setIsAnimating(true);
                  // }}
                  animationDuration={600}
                  animationEasing="ease"
                  // isAnimationActive={false}
                /> */}
                <Line
                  key={index + "active"}
                  connectNulls={true}
                  type="monotone"
                  dataKey={yAxisKey}
                  stroke={graphColors[index % graphColors.length]}
                  activeDot={{ r: 8 }}
                  strokeWidth={2}
                  dot={{ r: 0 }}
                  opacity={1}
                  name={yAxisNames && yAxisNames[index]}
                  legendType="circle"
                  // onAnimationEnd={() => {
                  //   setIsAnimating(false);
                  // }}
                  // onAnimationStart={() => {
                  //   setIsAnimating(true);

                  // }}
                  // onAnimationStart={(e) => {
                  //   setIsAnimating(true);
                  // }}
                  animationDuration={600}
                  animationEasing="ease"
                  // isAnimationActive={false}
                  // z={100}
                />
              </>
            ))}
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};
