import { ReactNode, useEffect, useState } from "react";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import clsx from "clsx";
import { CustomCheck } from "../CustomCheck";
import { Button } from "../Button";

export interface MenuProps {
  base: ReactNode;
  items: string[];
  disabledItems?: string[];
  checkedItems?: string[];
  onSelect: (selected: string[], e: any) => void;
  isMultiple?: boolean;
  startFrom?: "left" | "right" | "top" | "bottom";
}

interface ItemProps {
  focus: boolean;
  item: string;
  disabled: boolean;
  idx: number;
}

export function MenuDropdown({
  base,
  items,
  onSelect,
  disabledItems,
  isMultiple = false,
  startFrom = "left",
  checkedItems,
}: MenuProps) {
  const [selected, setSelected] = useState<string[]>(checkedItems ?? []);
  const [isSelectAll, setIsSelectAll] = useState<boolean>(false);

  useEffect(() => {
    if (!items) return;
    const allItemsSelected = items.every((item) => selected.includes(item));
    setIsSelectAll(allItemsSelected);
  }, [items, selected]);

  const handleSelect = (val: { [key: string]: boolean }, e: any) => {
    const key = Object.keys(val)[0];
    if (val[key]) {
      const newSelected = [...selected, key];
      setSelected(newSelected);
      onSelect(newSelected, e);
    } else {
      const newSelected = selected.filter((item) => item !== key);
      setSelected(newSelected);
      onSelect(newSelected, e);
    }
  };

  const handleSelectAll = (e: any) => {
    if (isSelectAll) {
      setSelected([]);
      onSelect([], e);
    } else {
      setSelected(items);
      onSelect(items, e);
    }
  };

  const renderItems = ({ focus, item, disabled, idx }: ItemProps) => {
    if (isMultiple) {
      return (
        <>
          <CustomCheck
            label={item}
            defaultCheckedItems={selected}
            onSelect={handleSelect}
            key={idx}
            active={focus}
            selectAll={isSelectAll}
          />
          {idx === items?.length - 1 && (
            <div className="group flex w-full px-4 py-3">
              <Button
                size="xs"
                kind="simplePrimary"
                className="w-full py-2"
                onClick={handleSelectAll}
              >
                {isSelectAll ? "Show None" : "Show All"}
              </Button>
            </div>
          )}
        </>
      );
    } else {
      return (
        <button
          onClick={(e) => {
            onSelect([item], e);
          }}
          disabled={disabled}
          className={clsx(
            focus
              ? "bg-indigo-50 font-semibold text-theme-purple"
              : disabled
                ? "text-theme-disabled"
                : "font-normal text-black",
            "group flex w-full items-center px-4 py-3 text-left text-sm capitalize"
          )}
        >
          {item}
        </button>
      );
    }
  };

  return (
    <Menu as="div" className="relative w-fit">
      <MenuButton as="div" className="w-fit">
        {base}
      </MenuButton>
      <MenuItems
        className={`absolute z-50 mt-2 w-56 origin-top-right divide-y divide-gray-200 overflow-hidden rounded-lg border border-theme-purple bg-white shadow-lg ring-1 ring-black/5 focus:outline-none ${startFrom}-0 max-h-[70vh] overflow-y-auto`}
      >
        {items?.map((item: string, idx: number) => (
          <MenuItem
            key={`${item}_${idx}`}
            disabled={disabledItems?.includes(item)}
          >
            {({ focus, disabled }) =>
              renderItems({ focus, item, disabled, idx })
            }
          </MenuItem>
        ))}
      </MenuItems>
    </Menu>
  );
}
