import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import isEmpty from "lodash/isEmpty";
import { IAuth } from "../hooks/auth";
import { IUserDetails } from "../stories/ProfileAvatar";

function useLocalStorage<T>(key: string, initialValue: T) {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState<T>(() => {
    if (typeof window === "undefined") {
      return initialValue;
    }
    try {
      // Get from local storage by key
      const item = window.localStorage.getItem(key);
      // Parse stored json or if none return initialValue
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      // If error also return initialValue
      console.log(error);
      return initialValue;
    }
  });
  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = (value: T | ((val: T) => T)) => {
    try {
      if (value === null) {
        window.localStorage.removeItem(key);
        return;
      }
      // Allow value to be a function so we have same API as useState
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;
      // Save state
      setStoredValue(valueToStore);
      // Save to local storage
      if (typeof window !== "undefined") {
        window.localStorage.setItem(key, JSON.stringify(valueToStore));
      }
    } catch (error) {
      // A more advanced implementation would handle the error case
      console.log(error);
    }
  };
  return [storedValue, setValue] as const;
}
interface IAuthContextValue {
  login: (data: IAuth) => void;
  logout: () => void;
  setUserDetails: (user: IUserDetails) => void;
  user: IUserDetails | null;
  auth: IAuth | null;
  getItems: (key: string) => string | { [key: string]: any };
  getSessionItems: (key: string) => string | { [key: string]: any };
}
const AuthContext = createContext<IAuthContextValue>({
  login: () => {},
  logout: () => {},
  setUserDetails: () => {},
  user: null,
  auth: null,
  getItems: (key: string) => {
    return key;
  },
  getSessionItems: (key: string) => {
    return key;
  },
});

export const AuthProvider = ({ children }: { children: JSX.Element }) => {
  const [user, setUser] = useLocalStorage<IUserDetails | null>("user", null);
  const [auth, setAuth] = useLocalStorage<IAuth | null>("auth", null);

  const navigate = useNavigate();

  // call this function when you want to authenticate the user
  const login = (auth: IAuth) => {
    setAuth(auth);
    if (!isEmpty(auth)) navigate("/dashboard");
  };

  const setUserDetails = (userDetails: IUserDetails) => {
    setUser(userDetails);
  };

  // call this function to sign out logged in user
  const logout = () => {
    setUser(null);
    setAuth(null);
    window.location.href = "/";
  };
  const getItems = (key: string) => {
    if (!isEmpty(key)) {
      return JSON.parse(localStorage.getItem(key) || "{}");
    } else {
      throw new Error("Key is required");
    }
  };

  const getSessionItems = (key: string) => {
    if (!isEmpty(key)) {
      return JSON.parse(sessionStorage.getItem(key) || "{}");
    } else {
      throw new Error("Key is required");
    }
  };

  const value = useMemo(
    () => ({
      user,
      auth,
      login,
      logout,
      setUserDetails,
      getItems,
      getSessionItems,
    }),
    [user, auth]
  );

  useEffect(() => {
    if (!auth?.token) {
      if (
        window.location.pathname &&
        window.location.pathname === "/auth/callback"
      ) {
        const queryParams = new URLSearchParams(window.location.search);
        const accessToken = queryParams.get("accessToken");

        if (accessToken) {
          const refreshToken = queryParams.get("refreshToken");

          login({
            token: accessToken,
            refreshToken: refreshToken ?? "",
            authType: "sso",
          });
          // const userDetails = JSON.parse(atob(accessToken.split(".")[1]));

          // Redirect to home or other protected route
          navigate("/dashboard");
        }
        // setLoading(false);
      }
    }
  }, []);

  return (
    <AuthContext.Provider value={value}> {children} </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
