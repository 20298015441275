import clsx from "clsx";

export enum INDICATOR_TYPE {
  success = "success",
  warning = "warning",
  danger = "danger",
  high = "high",
  critical = "high",
  information = "information",
}

export interface IIndicator {
  type: string;
}

export const Indicator = ({ type }: IIndicator) => {
  const newType =
    type in INDICATOR_TYPE
      ? INDICATOR_TYPE[type as keyof typeof INDICATOR_TYPE]
      : INDICATOR_TYPE.information;
  const getIndicatorStyle = ({ type }: { type: INDICATOR_TYPE }) => {
    const IndicatorDefaultClass =
      "flex h-5 w-5 items-center justify-center  border";
    const IndicatorTypeClasses: { [key in INDICATOR_TYPE]?: string } = {
      success: "border-indicator-green-200 bg-indicator-green-100",
      danger: "border-indicator-red-200 bg-indicator-red-100",
      high: "border-indicator-red-200 bg-indicator-red-100",
      warning: "border-indicator-orange-200 bg-indicator-orange-100",
      information: "border-indicator-green-200 bg-indicator-green-100",
    };
    return clsx([IndicatorDefaultClass, IndicatorTypeClasses[type]]);
  };

  const getCircleStyles = ({ type }: { type: INDICATOR_TYPE }) => {
    const CircleDefaultClasses = "h-4 w-4 rounded-full";
    const CircleTypeClasses: { [key in INDICATOR_TYPE]?: string } = {
      success: "bg-indicator-green-200 ",
      danger: "bg-indicator-red-200",
      high: "bg-indicator-red-200",
      warning: "bg-indicator-orange-200",
      information: "bg-indicator-green-200",
    };
    return clsx([CircleDefaultClasses, CircleTypeClasses[type]]);
  };

  return (
    // <div className={clsx(getIndicatorStyle({ type }))}>
    <div>
      <div className={clsx(getCircleStyles({ type: newType }))}></div>
    </div>
  );
};
