import { useEffect, useState } from "react";
import { TickFormatter } from "recharts/types/cartesian/CartesianAxis";
import { formatNumber } from "../../utils/conversions";
import { LineGraph } from "../Graphs";
import { devicePolyData } from "./mockData";
import { Loader, LOADER_SIZE } from "../Loader";

const categories: {
  label: string;
  key: "cpu" | "memory" | "disk" | "network" | "file" | "systemThreshold";
  yAxisKeys: string[];
  yAxisUnit: string;
  yAxisFormatterFxn?: TickFormatter;
  yAxisNames?: string[];
  information?: { heading: string; details: string };
}[] = [
  // {
  //   label: "System Threshold (%)",
  //   key: "systemThreshold",
  //   yAxisKeys: ["metricsAdditional.systemThreshold"],
  //   yAxisNames: ["System Threshold"],
  //   yAxisUnit: "%",
  //   information: { details:  "System Threshold.", }
  // },
  {
    label: "CPU usage (%)",
    key: "cpu",
    yAxisKeys: ["cpuUser"],
    yAxisUnit: "%",
    yAxisNames: ["User"],
    information: {
      heading: "CPU Usage (User CPU)",
      details:
        "This metric represents the processing power consumed by user-level applications. It indicates how much of the CPU is allocated for executing user-space tasks instead of system or kernel operations.",
    },
    // yAxisFormatterFxn: (value) => value.toFixed(2),
  },
  {
    label: "Disk Usage (Bytes)",
    key: "disk",
    yAxisKeys: ["diskUsage.totalReadBytes", "diskUsage.totalWrittenBytes"],
    yAxisNames: ["Total Read", "Total Write"],
    yAxisUnit: "",
    information: {
      heading: "Disk Usage (Total Read, Total Write)",
      details:
        "Disk usage metrics track the amount of data being read from and written to the storage devices. Total Read measures the cumulative bytes of data fetched from the disk, while Total Write indicates the total data written to it.",
    },
  },

  {
    label: "Memory Usage (Bytes)",
    key: "memory",
    yAxisKeys: [
      "metricsAdditional.usedMemory",
      "metricsAdditional.totalSwap",
      "metricsAdditional.usedSwap",
    ],
    yAxisNames: ["Used Memory", "Total Swap", "Used Swap"],
    yAxisUnit: "",
    // yAxisFormatterFxn: (val) => val.toString().concat("MB"),
    information: {
      heading: "Memory Usage (User Memory and User Swap)",

      details:
        "User Memory represents the amount of RAM consumed by user processes, while User Swap shows the portion of data swapped to disk when the physical memory is insufficient.",
    },
  },
  {
    label: "Network (Bytes)",
    key: "network",
    yAxisKeys: ["networkRate.receivedBytes", "networkRate.sentBytes"],
    yAxisUnit: "",
    yAxisFormatterFxn: (val) => val.toString().concat("MB"),
    yAxisNames: ["Network Received", "Network Sent"],
    information: {
      heading: "Network (Received Bytes, Sent Bytes)",

      details:
        "These metrics measure the total amount of data received and sent over the network interface. Received Bytes indicates the incoming traffic, while Sent Bytes tracks the outbound traffic.",
    },
  },

  // {
  //   label: "File changes (Count)",
  //   key: "file",
  //   yAxisKeys: ["fileExtensionChanges", "fileContentChanges"],
  //   yAxisNames: ["Extension Changes", "Content Changes"],
  //   yAxisFormatterFxn: (val) => {
  //     return formatNumber(val);
  //   },
  //   yAxisUnit: "",
  //   information: {
  //     heading: "File Changes (Extension Changes and Content Changes)",

  //     details:
  //       " This field tracks modifications in the file system, including changes in file extensions and content alterations. Extension changes could indicate renaming or attempts to obfuscate file types, while content changes may highlight important updates or unauthorized tampering within the files.",
  //   },
  // },

  // {
  //   label: "Process Shutdowns",
  //   key: "",
  //   yAxisKey: "processShutdownRate",
  // },
  // {
  //   label: "File encryption",
  //   key: "",
  //   yAxisKey: "fileEncryptionRate",
  // },
  // {
  //   label: "File Decryption",
  //   key: "",
  //   yAxisKey: "fileDecryptionRate",
  // },
  // {
  //   label: "File content change",
  //   key: "",
  //   yAxisKey: "fileContentChanges",
  // },
  // {
  //   label: "File size change",
  //   key: "",
  //   yAxisKey: "fileSizeChanges",
  // },
  // {
  //   label: "Decoy file events",
  //   key: "",
  //   yAxisKey: "networkRate",
  // },
];
export function AssetGraphs({
  data,
  dateRange,
  loading = false,
}: {
  data: typeof devicePolyData;
  dateRange: [Date, Date];
  loading?: Boolean;
}) {
  const [formattedData, setFormattedData] = useState(data);

  useEffect(() => {
    setFormattedData(
      data
        .map((v) => {
          return v.metricsAdditional
            ? {
                ...v,
                cpuUser: Math.round(v.cpuUser * 1e2) / 1e2,
                metricsAdditional: {
                  ...v.metricsAdditional,
                  aggregatedTotalMemory: +(
                    v.metricsAdditional.aggregatedTotalMemory /
                    (1024 * 1024 * 1024)
                  ).toFixed(2),
                  // aggregatedNetworkRate: {
                  //   ...v.metricsAdditional.aggregatedNetworkRate,
                  // sent: convertBytesToMB(
                  //   v.metricsAdditional.aggregatedNetworkRate.sentBytes
                  // ),
                  // received: convertBytesToMB(
                  //   v.metricsAdditional.aggregatedNetworkRate.receivedBytes
                  // ),
                  // },
                },
              }
            : {
                ...v,
              };
        })
        .sort((a, b) => a.timestamp - b.timestamp)
    );
  }, [data]);
  return (
    <div className="mt-4 grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-2">
      {categories.map((v, i) => (
        <div
          key={v.key + i}
          className="relative rounded-lg border-0 bg-white p-4"
        >
          <>
            {loading && (
              <div className="absolute left-0 top-0 flex h-[100%]  w-[100%] items-center justify-center rounded-lg bg-neutral-200  bg-opacity-25 ">
                <Loader className="" size={LOADER_SIZE.xl} />
              </div>
            )}
            {renderGraph(v, formattedData)}
          </>
        </div>
      ))}
    </div>
  );

  function renderGraph(
    category: (typeof categories)[number],
    data: typeof devicePolyData
  ) {
    switch (category.key) {
      case "cpu":
        return (
          <LineGraph
            loading={loading}
            data={data}
            heading={category.label}
            xAxisKey={"timestamp"}
            yAxisKeys={category.yAxisKeys}
            yAxisUnit={category.yAxisUnit}
            yAxisFormatterFxn={category.yAxisFormatterFxn}
            showLegend={true}
            yAxisNames={category.yAxisNames}
            information={category.information}
            domain={dateRange}
          />
        );
      case "disk":
        return (
          <LineGraph
            loading={loading}
            data={data}
            heading={category.label}
            xAxisKey={"timestamp"}
            yAxisKeys={category.yAxisKeys}
            yAxisNames={category.yAxisNames}
            showLegend={true}
            information={category.information}
            domain={dateRange}
          />
        );
      case "memory":
        return (
          <LineGraph
            loading={loading}
            data={data}
            heading={category.label}
            xAxisKey={"timestamp"}
            yAxisKeys={category.yAxisKeys}
            yAxisNames={category.yAxisNames}
            showLegend={true}
            information={category.information}
            domain={dateRange}
          />
        );

      case "file":
        return (
          <LineGraph
            loading={loading}
            data={data}
            heading={category.label}
            xAxisKey={"timestamp"}
            yAxisKeys={category.yAxisKeys}
            yAxisUnit={category.yAxisUnit}
            yAxisNames={category.yAxisNames}
            yAxisFormatterFxn={category.yAxisFormatterFxn}
            showLegend={true}
            information={category.information}
            domain={dateRange}
          />
        );
      case "network":
      default:
        return (
          <LineGraph
            loading={loading}
            data={data}
            heading={category.label}
            xAxisKey={"timestamp"}
            yAxisKeys={category.yAxisKeys}
            yAxisUnit={category.yAxisUnit}
            // yAxisFormatterFxn={category.yAxisFormatterFxn}
            showLegend={true}
            yAxisNames={category.yAxisNames}
            information={category.information}
            domain={dateRange}
          />
        );
    }
  }
}
