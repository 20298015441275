import { BUTTON_KIND, BUTTON_SIZE, Button } from "../Button";
import { InputField } from "../InputField";
import Placeholder from "../assets/root_register_vector.svg";
import { AuthLayout } from "../AuthLayout";
import { LogoWithTitle } from "../LogoWithTitle";
import { RegisterSteps } from "../RegisterSteps";
import { ErrorMessage, Form, Formik } from "formik";
import { ICON_NAME } from "../Icon";
import * as Yup from "yup";
import { endpoints } from "../../services/api";
import { BASE_URL } from "../../utils/const";
import { useNavigate } from "react-router-dom";

export const RegisterRootAccountPage: React.FC = () => {
  return (
    <AuthLayout
      leftSection={
        <RegisterSteps
          heading={"Continue with Single Sign-On (SSO)"}
          description={
            <div>
              <div className="pb-10 text-white">
                Single Sign-On (SSO) allows you to access multiple applications
                or services using your domain credentials. Please ensure that
                your organization is set to log in using SSO.
              </div>
              {/* <div className="font-bold text-white">
                Please safeguard this account credentials.
              </div> */}
            </div>
          }
          placeholder={Placeholder}
        />
      }
      rightSection={<RegisterSection />}
    />
  );
};

const RegisterSection = () => {
  const registerFormValidationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Email is not valid")
      .required("Email is required"),
  });

  const navigate = useNavigate();
  return (
    <div className="max-w-md">
      <LogoWithTitle title={<span>Login with SSO</span>} />

      <Formik
        initialValues={{ email: "" }}
        onSubmit={(value) => {
          window.location.href = (
            process.env?.REACT_APP_BASE_URL ?? BASE_URL
          ).concat(endpoints.auth.sso(value.email));
        }}
        validationSchema={registerFormValidationSchema}
      >
        <Form>
          <div className="flex flex-col gap-8 pb-6">
            <div>
              <InputField
                name="email"
                icon={ICON_NAME.email}
                label="Work email"
              />
              <ErrorMessage
                name={"email"}
                component="div"
                className="mtext-sm mt-1 text-red-600"
              />
            </div>
            <Button
              kind={BUTTON_KIND.secondary}
              size={BUTTON_SIZE.base}
              type="submit"
            >
              Continue
            </Button>
          </div>
        </Form>
      </Formik>
      <div className="flex flex-col items-center justify-center gap-12">
        <div>
          <span className="text-base font-medium text-slate-700">
            By clicking Continue, you agree to the &nbsp;
          </span>
          <Button
            kind={BUTTON_KIND.plain}
            size={BUTTON_SIZE.lg}
            className="!p-0 !text-theme-purple"
            onClick={() => {
              navigate("/terms-conditions");
            }}
          >
            Terms & Conditions
          </Button>
        </div>
        <div>
          <span className="text-base font-medium text-slate-700">
            Don't have an account yet? &nbsp;
          </span>
          <Button
            kind={BUTTON_KIND.plain}
            size={BUTTON_SIZE.lg}
            className="!p-0"
            url="https://www.armorx.ai/contact-us"
          >
            Contact Us
          </Button>
        </div>
      </div>
    </div>
  );
};
