import { Formik } from "formik";
import { InputField } from "../InputField";
import { ICON_NAME } from "../Icon";
import { Button, ButtonProps } from "../Button";
import { MenuDropdown } from "../MenuDropdown";
import { ISideBarProfile, IUserDetails } from "../ProfileAvatar";
import { ChangeEvent } from "react";
import { tableHeaderValues } from "../../utils/helper";

interface ColumnData {
  id: string;
  getIsVisible: () => boolean;
  getToggleVisibilityHandler: () => (
    event: React.ChangeEvent<HTMLInputElement>
  ) => void;
}

export interface ITableHeader {
  heading: string;
  user?: Partial<IUserDetails> & Partial<ISideBarProfile>;
  sites?: string[];
  searchQuery?: string;
  columnData: ColumnData[];
  headerActionItemsItems?: ButtonProps[];
  showSearchField?: boolean;
}
function createSyntheticEvent(checked: boolean): ChangeEvent<HTMLInputElement> {
  return {
    target: { checked } as EventTarget & HTMLInputElement,
  } as ChangeEvent<HTMLInputElement>;
}

export const TableHeader = ({
  heading,
  searchQuery,
  columnData,
  headerActionItemsItems,
  showSearchField = true,
}: ITableHeader) => {
  const visibleColumns =
    columnData?.length > 0
      ? columnData
          .filter((column) => column.getIsVisible())
          .map((column) => tableHeaderValues(column.id))
      : [];

  return (
    <div className="flex w-full items-center justify-between">
      <h3 className="text-base font-semibold md:text-xl">{heading}</h3>
      <div className="flex items-center justify-center gap-10">
        {/* hiding it temporarily for mobile */}
        <div className=" hidden items-center justify-center gap-3 md:flex">
          {showSearchField && (
            <div className=" min-w-36 md:min-w-[316px]">
              <Formik
                initialValues={{
                  search: searchQuery,
                }}
                onSubmit={(e) => {
                  console.log(e);
                }}
              >
                <InputField
                  icon={ICON_NAME.search}
                  label="Search"
                  name="searchAssets"
                  inputsize="sm"
                />
              </Formik>
            </div>
          )}
          {/* <MenuDropdown
            base={
              <Button
                kind="simple"
                className="px-8"
                iconLeft={{ name: ICON_NAME.filter }}
                size="xs"
              >
                Filters
              </Button>
            }
            startFrom="left"
            isMultiple={true}
            items={["Filter 1", "Filter 2"]}
            onSelect={() => {}}
          /> */}
          {columnData?.length > 0 && (
            <MenuDropdown
              base={
                <Button
                  kind="simple"
                  className="px-8 py-2"
                  iconLeft={{ name: ICON_NAME.filter }}
                  size="xs"
                >
                  Columns
                </Button>
              }
              startFrom="right"
              isMultiple={true}
              items={columnData.map((column) => tableHeaderValues(column.id))}
              onSelect={(selected) => {
                columnData.forEach((column) => {
                  const isVisible = selected.includes(
                    tableHeaderValues(column.id)
                  );
                  if (column.getIsVisible() !== isVisible) {
                    if (column.getIsVisible() !== isVisible) {
                      column.getToggleVisibilityHandler()(
                        createSyntheticEvent(isVisible)
                      );
                    }
                  }
                });
              }}
              checkedItems={visibleColumns}
            />
          )}
          {/* <MenuDropdown
            base={
              <Button
                kind="simple"
                className="px-8"
                iconLeft={{ name: ICON_NAME.filter }}
                size="xs"
              >
                Rows & Columns
              </Button>
            }
            startFrom="right"
            isMultiple={false}
            items={["Rows & Columns", "Card"]}
            onSelect={() => {}}
          /> */}
          {headerActionItemsItems &&
            headerActionItemsItems.length > 0 &&
            headerActionItemsItems.map((props, key) => (
              <Button {...props} key={key} />
            ))}
        </div>
      </div>
    </div>
  );
};
