import { useState } from "react";
import { Form, Formik, FormikHelpers } from "formik";
import { CardSection } from "../CardSection";
import { BUTTON_KIND, BUTTON_SIZE } from "../Button";
import { renderField } from "../../utils/renderField";
import { Tabs } from "../Tabs";
import { editFields, monitorDriverFields } from "./policyForm";
import { Table } from "../Table";
import { createColumnHelper } from "@tanstack/react-table";
import { MenuDropdown } from "../MenuDropdown";
import { Icon, ICON_NAME } from "../Icon";
import ActionModal from "../ActionModal/ActionModal";
import { ModalType } from "../ActionModal";
import * as Yup from "yup";
import {
  IDirectory,
  IDirectoryWithFlag,
  IWhitelist,
  PolicyDetails,
  updatePolicy,
} from "../SecurityPolicy";
import { Placeholder } from "../Placeholder";
import { KeyedMutator } from "swr";
import { Loader, LOADER_SIZE } from "../Loader";
import { useAuth } from "../../context/AuthProvider";
import { notifyError, notifySuccess } from "../../utils/notify";

const SecurityPolicyDetails = ({
  data,
  refetch,
  loading,
}: {
  data: PolicyDetails;
  refetch: KeyedMutator<unknown>;
  loading: boolean;
}) => {
  const [editing, setIsEditing] = useState(false);
  const { user } = useAuth();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [applyGloballyModal, setApplyGloballyModal] = useState(false);
  const onSubmit = async (values: Partial<PolicyDetails>) => {
    setIsSubmitting(true);
    try {
      if (user?.tenantId) {
        const { error } = await updatePolicy(
          data.policyId,
          values,
          user.tenantId
        );
        if (error) {
          notifyError(
            "We couldn't update the Security Policy. Please review the changes and try again."
          );
          return console.log(error);
        }
        notifySuccess(
          `Security Policy ${data.policyName} was updated successfully`
        );
      }
      refetch();
      setIsEditing(false);
    } catch (error) {
      console.error(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div>
      <Formik
        initialValues={
          data ?? {
            policyName: "",
            protectionMode: "",
            description: "",
            applyGlobally: false,
          }
        }
        onSubmit={(e) => {
          onSubmit({
            policyName: e.policyName,
            protectionMode: e.protectionMode,
            description: e.description,
            applyGlobally: e.applyGlobally,
          });
          if (applyGloballyModal) {
            setApplyGloballyModal(false);
          }
        }}
        enableReinitialize={true}
      >
        {(props) => (
          <Form>
            <div className="flex flex-col">
              <CardSection
                title={"Security Policy"}
                className="h-min"
                headerActionItemsItems={
                  editing
                    ? [
                        {
                          children: "Cancel",
                          kind: BUTTON_KIND.simple,
                          size: BUTTON_SIZE.sm,
                          className: "md:!px-12",
                          disabled: isSubmitting,
                          onClick: () => {
                            setIsEditing(false);
                            props.resetForm();
                          },
                        },
                        {
                          children: "Save",
                          kind: BUTTON_KIND.secondary,
                          size: BUTTON_SIZE.sm,
                          className: "md:!px-12",
                          submit: false,
                          disabled: isSubmitting,
                          onClick: (e) => {
                            props.values.applyGlobally &&
                            !props.initialValues.applyGlobally
                              ? setApplyGloballyModal(true)
                              : props.submitForm();
                          },
                        },
                      ]
                    : [
                        {
                          children: "Edit",
                          kind: BUTTON_KIND.secondary,
                          size: BUTTON_SIZE.sm,
                          className: "md:!px-12",
                          // disabled: isSubmitting,
                          onClick: () => {
                            setIsEditing(true);
                          },
                        },
                        //May be required later
                        // {
                        //   children: data?.applyGlobally ? (
                        //     <div className="flex items-center justify-center">
                        //       <Icon
                        //         className="h-4 w-4"
                        //         name={ICON_NAME.check}
                        //       />
                        //       <span className="ml-1">Default Policy</span>
                        //     </div>
                        //   ) : (
                        //     "Apply Globally"
                        //   ),
                        //   kind: BUTTON_KIND.secondary,
                        //   size: BUTTON_SIZE.sm,
                        //   className: "md:!px-12",
                        //   disabled: data?.applyGlobally,
                        //   onClick: () => {
                        //     setIsEditing(true);
                        //   },
                        // },
                      ]
                }
                rightSideComponents={
                  <div
                    title={
                      !data?.isEnforced
                        ? "Default settings are in use."
                        : "Policy is enforced and applied to sites."
                    }
                    className="flex items-center justify-center gap-2"
                  >
                    <div className="flex h-5 w-5 items-center justify-center rounded border bg-gray-200">
                      {data?.isEnforced && (
                        <Icon className="h-5" name={ICON_NAME.check} />
                      )}
                    </div>
                    <p className="text-lg font-medium capitalize text-gray-700">
                      {"Enforced"}
                    </p>
                  </div>
                }
              >
                <div className="space-y-8">
                  {loading ? (
                    <div className="m-1.5 flex h-full min-h-64 items-center justify-center">
                      <Loader
                        className="text-theme-purple"
                        size={LOADER_SIZE.lg}
                      />
                    </div>
                  ) : (
                    editFields.map((field, index) => {
                      return renderField(field, props, undefined, !editing);
                    })
                  )}
                </div>
              </CardSection>
              <Tabs
                className="px-6"
                loading={loading}
                tabs={[
                  "Directory Integrity",
                  "Manage Exclusions",
                  "Driver Integrity",
                  // "Monitor Registry",
                  // "Monitor Commands",
                  // "File Armor",
                ]}
                contents={[
                  data && (
                    <MonitoredDirectoriesTab
                      directorySet={{
                        directories: data?.directories ?? [],
                        userDirectories: data?.userDirectories ?? [],
                      }}
                      refetch={refetch}
                      loading={loading}
                      addOrRemoveDirectory={onSubmit}
                    />
                  ),
                  data && (
                    <WhitelistTab
                      data={data.whitelistedProcesses}
                      refetch={refetch}
                      loading={loading}
                      addOrRemoveDirectory={onSubmit}
                    />
                  ),
                  <MonitorDriverTab />,
                ]}
              />
            </div>
            <ActionModal
              modalType={ModalType.SET_AS_DEFAULT_POLICY}
              isOpen={applyGloballyModal}
              setIsOpen={setApplyGloballyModal}
              dialogClassName="!w-full"
              onConfirm={props.submitForm}
              onCancel={() => {
                setApplyGloballyModal(false);
              }}
              entityName={data?.policyName ?? ""}
              props={props}
              loading={false}
              actionButtonTitles={{
                confirm: "Save",
                cancel: "Cancel",
              }}
            />
          </Form>
        )}
      </Formik>
    </div>
  );
};

const MonitoredDirectoriesTab = ({
  directorySet,
  loading,
  refetch,
  addOrRemoveDirectory,
}: {
  directorySet: {
    directories: IDirectory[];
    userDirectories: IDirectory[];
  };
  loading: boolean;
  refetch: KeyedMutator<unknown>;
  addOrRemoveDirectory: (values: Partial<PolicyDetails>) => Promise<void>;
}) => {
  const [showModal, setShowModal] = useState(false);
  const [currentModalType, setCurrentModalType] = useState<ModalType | null>(
    null
  );
  const [currentRow, setCurrentRow] = useState<IDirectoryWithFlag | null>(null);
  const [addOrDeleteLoading, setAddOrDeleteLoading] = useState(false);

  const columnHelper = createColumnHelper<IDirectoryWithFlag>();
  const columns = [
    columnHelper.accessor("path", {
      header: () => "Folder",
      cell: (info) => {
        const isUserDirectory = info.row.original.isUserDirectory;
        const displayPath = isUserDirectory
          ? `%USERPROFILE%\\${info.getValue()}`
          : info.getValue();
        return <div className="font-medium">{displayPath}</div>;
      },
    }),
    columnHelper.accessor("action", {
      header: () => "Actions",
      cell: (info) => (
        <MenuDropdown
          onSelect={(item: string[]) => {
            if (item[0] === "Delete") {
              handleOpenModal(ModalType.DELETE_DIRECTORY, info.row.original);
            }
          }}
          base={
            <Icon
              className="h-8 w-8 self-center rounded-lg p-1 text-center hover:cursor-pointer hover:bg-theme-gray-250"
              name={ICON_NAME.menuDots}
            />
          }
          startFrom="right"
          items={["Delete"]}
          disabledItems={info.row.original.isDefault ? ["Edit", "Delete"] : []}
        />
      ),
    }),
  ];

  const handleOpenModal = (
    modalType: ModalType,
    directory?: IDirectoryWithFlag | null
  ) => {
    setCurrentModalType(modalType);
    setCurrentRow(directory || null);
    setShowModal(true);
  };

  const handleConfirm = async (
    values: IDirectoryWithFlag,
    { resetForm }: FormikHelpers<IDirectoryWithFlag>
  ) => {
    setAddOrDeleteLoading(true);
    const actionValue = {
      ...values,
      action:
        currentModalType === ModalType.DELETE_DIRECTORY ? "delete" : "add",
    };
    await addOrRemoveDirectory(
      values.isUserDirectory
        ? { userDirectories: [actionValue] }
        : { directories: [actionValue] }
    );
    resetForm();
    setAddOrDeleteLoading(false);
    setShowModal(false);
    setCurrentRow(null);
  };

  const handleCancel = () => {
    setShowModal(false);
    setTimeout(() => {
      setCurrentRow(null);
    }, 300);
  };

  const combinedData = [
    ...directorySet.directories.map((dir) => ({
      ...dir,
      isUserDirectory: false,
    })),
    ...directorySet.userDirectories.map((dir) => ({
      ...dir,
      isUserDirectory: true,
    })),
  ];

  return (
    <CardSection
      title="Directory Integrity"
      className="max-h-min"
      headerActionItemsItems={[
        {
          children: "Add Directory",
          kind: BUTTON_KIND.secondary,
          size: BUTTON_SIZE.sm,
          className: "md:!px-12",
          onClick: () => {
            handleOpenModal(ModalType.ADD_DIRECTORY);
          },
        },
      ]}
    >
      <Table
        columns={columns}
        onClick={() => {}}
        data={combinedData}
        emptyTablePlaceholder={
          <Placeholder
            label="No paths are monitored"
            description="Add directory paths here to monitor your system"
          />
        }
      />
      <Formik<IDirectoryWithFlag>
        initialValues={{
          id: "",
          timestamp: new Date().toISOString(),
          path: currentRow?.path ?? "",
          action: "",
          isUserDirectory: currentRow?.isUserDirectory ?? false,
        }}
        validationSchema={Yup.object().shape({
          path: Yup.string().required("Path should not be empty"),
        })}
        enableReinitialize={true}
        onSubmit={handleConfirm}
      >
        {(props) => (
          <ActionModal
            modalType={currentModalType}
            isOpen={showModal}
            setIsOpen={setShowModal}
            dialogClassName="!w-full"
            onConfirm={props.submitForm}
            onCancel={handleCancel}
            entityName={currentRow?.path || "this directory"}
            props={props}
            loading={addOrDeleteLoading}
            actionButtonTitles={
              currentModalType === ModalType.DELETE_DIRECTORY
                ? { confirm: "Delete", cancel: "Cancel" }
                : {
                    confirm: "Add",
                    cancel: "Cancel",
                  }
            }
          />
        )}
      </Formik>
    </CardSection>
  );
};

const WhitelistTab = ({
  data,
  refetch,
  loading,
  addOrRemoveDirectory,
}: {
  data: IWhitelist[];
  refetch: KeyedMutator<unknown>;
  loading: boolean;
  addOrRemoveDirectory: (values: Partial<PolicyDetails>) => Promise<void>;
}) => {
  const [showModal, setShowModal] = useState(false);
  const [currentModalType, setCurrentModalType] = useState<
    | ModalType.ADD_WHITELIST
    | ModalType.EDIT_WHITELIST
    | ModalType.DELETE_WHITELIST
    | null
  >(null);
  const [currentRow, setCurrentRow] = useState<IWhitelist | null>(null);
  const [addOrDeleteLoading, setAddOrDeleteLoading] = useState(false);
  const columnHelper = createColumnHelper<IWhitelist>();
  const columns = [
    columnHelper.accessor("path", {
      header: () => "Folder",
      cell: (info) => <div className="font-medium">{info.getValue()}</div>,
    }),
    columnHelper.accessor("action", {
      header: () => "Actions",
      cell: (info) => (
        <MenuDropdown
          onSelect={(item: string[]) => {
            if (item[0] === "Delete") {
              handleOpenModal(ModalType.DELETE_WHITELIST, info.row.original);
            }
            // else if (item[0] === "Edit") {
            //   handleOpenModal(ModalType.EDIT_WHITELIST, info.row.original);
            // }
          }}
          base={
            <Icon
              className="h-8 w-8 self-center rounded-lg p-1 text-center hover:cursor-pointer hover:bg-theme-gray-250"
              name={ICON_NAME.menuDots}
            />
          }
          startFrom="right"
          items={["Delete"]}
        />
      ),
    }),
  ];
  const handleOpenModal = (
    modalType:
      | ModalType.ADD_WHITELIST
      | ModalType.EDIT_WHITELIST
      | ModalType.DELETE_WHITELIST,
    path?: IWhitelist | null
  ) => {
    if (modalType === ModalType.ADD_WHITELIST) {
      setCurrentRow(null);
      setCurrentModalType(modalType);
    } else if (
      (modalType === ModalType.EDIT_WHITELIST ||
        modalType === ModalType.DELETE_WHITELIST) &&
      path
    ) {
      setCurrentModalType(modalType);
      setCurrentRow(path);
    }
    setShowModal(true);
  };

  const handleConfirm = async (
    values: IWhitelist,
    { resetForm }: FormikHelpers<IWhitelist>
  ) => {
    setAddOrDeleteLoading(true);
    const actionValue = {
      ...values,
      action:
        currentModalType === ModalType.DELETE_WHITELIST ? "delete" : "add",
    };

    await addOrRemoveDirectory({ whitelistedProcesses: [actionValue] });
    setCurrentRow(null);
    resetForm();
    setAddOrDeleteLoading(false);
    setShowModal(false);
  };

  const handleCancel = () => {
    setShowModal(false);
    setTimeout(() => {
      setCurrentRow(null);
    }, 300);
  };

  return (
    <CardSection
      title={"Manage Exclusions"}
      className="max-h-min"
      headerActionItemsItems={[
        {
          children: "Add Directory",
          kind: BUTTON_KIND.secondary,
          size: BUTTON_SIZE.sm,
          className: "md:!px-12",
          // disabled: isSubmitting,
          onClick: () => {
            handleOpenModal(ModalType.ADD_WHITELIST);
          },
        },
      ]}
    >
      <Table
        columns={columns}
        onClick={() => {}}
        data={data}
        emptyTablePlaceholder={
          <Placeholder
            label="No Apps Whitelisted"
            description="Add applications path here to whitelist processes"
          />
        }
      />
      <Formik<IWhitelist>
        initialValues={{
          id: "",
          timestamp: new Date().toISOString(),
          path: currentRow?.path ?? "",
          action: "",
        }}
        validationSchema={Yup.object().shape({
          path: Yup.string().required("Path should not be empty"),
        })}
        enableReinitialize={true}
        onSubmit={handleConfirm}
      >
        {(props) => (
          <ActionModal
            modalType={currentModalType}
            isOpen={showModal}
            setIsOpen={setShowModal}
            dialogClassName="!w-full"
            onConfirm={props.submitForm}
            onCancel={handleCancel}
            entityName={currentRow?.path || "this directory"}
            props={props}
            loading={addOrDeleteLoading}
            actionButtonTitles={
              currentModalType === ModalType.DELETE_WHITELIST
                ? { confirm: "Delete", cancel: "Cancel" }
                : {
                    confirm: "Add",
                    cancel: "Cancel",
                  }
            }
          />
        )}
      </Formik>
    </CardSection>
  );
};

const MonitorDriverTab = () => {
  return (
    <Formik
      initialValues={{
        allowMicrosoftSignedComponents: true,
        allowWHQLSignedThirdPartyDrivers: true,
        blockUnsignedAndKnownVulnerableDrivers: true,
        enableAuditMode: true,
      }}
      // validationSchema={validationSchema}
      onSubmit={() => {}}
      enableReinitialize={true}
    >
      {(props) => (
        <Form>
          <CardSection title={"Driver Integrity"} className="h-min">
            <div className="space-y-6">
              {monitorDriverFields.map((field, index) => {
                return renderField(field, props, undefined, true);
              })}
            </div>
          </CardSection>
        </Form>
      )}
    </Formik>
  );
};

export default SecurityPolicyDetails;
