import { useField } from "formik";
import clsx from "clsx";
import { Option } from "../../utils/interface";
import { useEffect } from "react";

export interface ToggleFieldProps {
  label: string;
  name: string;
  modes: Option[];
  disabled?: boolean;
  placeholder?: string;
}

export function ToggleField({
  label,
  name,
  modes,
  disabled = false,
  placeholder,
}: ToggleFieldProps) {
  const [field, meta, helpers] = useField(name);
  const { value } = field;
  const { setValue } = helpers;

  const handleModeChange = (newMode: Option) => {
    if (!disabled) {
      setValue(newMode.value);
    }
  };
  return (
    <div
      className="flex h-min w-fit items-center space-x-4"
      title={placeholder}
    >
      <span className="w-full text-sm font-medium">{label}</span>
      <div
        className={clsx(
          "relative flex w-full max-w-56 rounded-md bg-gray-200 p-1"
        )}
      >
        <div
          className={clsx(
            "absolute bottom-1.5 top-1.5 w-1/2 rounded bg-theme-purple transition-all duration-300 ease-in-out",
            value ? "block" : "hidden",
            value === modes[0]?.value ? "left-1" : "left-[48%]"
          )}
        ></div>
        {modes?.map((modeName) => (
          <button
            key={modeName.value}
            type="button"
            className={clsx(
              "relative z-10 w-1/2 px-4 py-2 text-xs font-medium transition-colors duration-300",
              value === modeName.value ? "text-white" : "text-gray-500",
              disabled ? "opacity-80" : "cursor-pointer"
            )}
            onClick={() => handleModeChange(modeName)}
            disabled={disabled}
          >
            {modeName.value.charAt(0).toUpperCase() + modeName.value.slice(1)}
          </button>
        ))}
      </div>
      {meta.touched && meta.error ? (
        <div className="mt-1 text-sm text-red-600">{meta.error}</div>
      ) : null}
    </div>
  );
}
