import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { isEmpty, startsWith } from "lodash";
import clsx from "clsx";
import {
  SidebarMenuButton,
  SidebarMenuButtonProps,
} from "../SidebarMenuButton";
import { ISite } from "../Sites";
import ChangeSiteDropdown from "../Dropdown/ChangeSiteDropdown";
import { Loader, LOADER_SIZE } from "../Loader";

export interface SideNavProps {
  className?: string;
  children?: JSX.Element;
  sites?: ISite[];
  navListItems: Pick<
    SidebarMenuButtonProps,
    "label" | "menuIcon" | "url" | "pseudo" | "submenuContainerItems"
  >[];
  isExpanded: boolean;
  loading?: boolean;
}

export function SideNav(props: SideNavProps): JSX.Element {
  const {
    className,
    navListItems,
    children,
    isExpanded = true,
    sites,
    loading = false,
    ...rest
  } = props;
  const [selectedMenu, setSelectedMenu] = useState(
    navListItems?.[0]?.url || ""
  );
  const [sideNavTree, setSideNavTree] = useState<
    Partial<SidebarMenuButtonProps>[]
  >([]);
  const location = useLocation();
  // Set selectedMenu based on location.pathname
  useEffect(() => {
    setSelectedMenu(location.pathname);
  }, [location]);

  // Generate the side navigation tree based on navListItems and selectedMenu
  useEffect(() => {
    setSideNavTree(generateNavListItems(navListItems));
  }, [selectedMenu]);

  const hasSelectedSubmenu = (
    navListItems: Pick<
      SidebarMenuButtonProps,
      "label" | "menuIcon" | "url" | "submenuContainerItems"
    >[]
  ): boolean => {
    return navListItems.some((item) => {
      return (
        startsWith(selectedMenu, item.url) ||
        (item?.submenuContainerItems && item?.submenuContainerItems?.length > 0
          ? hasSelectedSubmenu(item?.submenuContainerItems || [])
          : false)
      );
    });
  };

  const generateNavListItems = (
    navListItems: Pick<
      SidebarMenuButtonProps,
      "label" | "menuIcon" | "url" | "submenuContainerItems"
    >[]
  ): SidebarMenuButtonProps[] => {
    return navListItems.map((props): SidebarMenuButtonProps => {
      const disclosure =
        props?.submenuContainerItems &&
        props?.submenuContainerItems?.length > 0;
      const selected =
        props.url === selectedMenu || startsWith(selectedMenu, props.url);
      const selectedSubMenu = !isEmpty(props?.submenuContainerItems)
        ? hasSelectedSubmenu(props?.submenuContainerItems!)
        : false;
      const open =
        props?.submenuContainerItems &&
        props?.submenuContainerItems?.length > 0 &&
        (selected || selectedSubMenu);

      return {
        ...props,
        disclosure,
        selected,
        selectedSubMenu,
        open,
        submenuContainerItems: props?.submenuContainerItems
          ? generateNavListItems(props?.submenuContainerItems)
          : [],
      };
    });
  };

  return (
    <div className="flex flex-1 flex-col">
      <div {...rest} className={clsx("flex flex-col flex-1 p-4", className)}>
        <div className="inline-flex flex-1 flex-col gap-6">
          {loading && <Loader size={LOADER_SIZE.xl} className="text-theme-purple" />}
          {sites && isExpanded && !loading && (
            <ChangeSiteDropdown sites={sites} />
          )}
          {navListItems &&
            navListItems.length > 0 &&
            sideNavTree.map((props, key) => {
              return (
                <SidebarMenuButton
                  key={key}
                  {...props}
                  url={props.url || "/"}
                  isExpanded={isExpanded}
                  onClick={(menuProps) => {
                    if (menuProps.selectedSubMenu) {
                      return;
                    }

                    if (props.onClick) {
                      props.onClick(menuProps);
                      return;
                    }
                    if (menuProps.url) {
                      if (menuProps.url !== selectedMenu) {
                        setSelectedMenu(menuProps.url);
                      } else {
                        setSelectedMenu("");
                      }
                    }
                  }}
                />
              );
            })}
        </div>
      </div>
      {children}
    </div>
  );
}

SideNav.defaultProps = {
  navListItems: [],
};
