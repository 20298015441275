import { useState } from "react";
import {
  PieChart,
  Pie,
  ResponsiveContainer,
  Legend,
  Cell,
  Tooltip,
  Sector,
} from "recharts";
import { PieSectorDataItem } from "recharts/types/polar/Pie";
import { GraphHeader } from "../GraphHeader";

import "./pie.css";
export interface IPieGraph {
  data: {
    name: string;
    value: number;
    [key: string]: string | number;
  }[];
  dataKey: string;
  startAngle?: number;
  endAngle?: number;
  heading?: string;
  subText?: string;
  total?: number;
}

interface RGBColor {
  r: number;
  g: number;
  b: number;
}

interface DataEntry {
  name: string;
  value: number;
}

function generateGradient(numGradients: number): string[][] {
  const base: string[][] = [
    ["#0700C4", "#0000FF"],
    ["#0000FF", "#0052FF"],
    ["#0052FF", "#007AFF"],
    ["#007AFF", "#00A3FF"],
    ["#00A3FF", "#00CCFF"],
    ["#00CCFF", "#28F4FF"],
    ["#28F4FF", "#5AFFFF"],
    ["#5AFFFF", "#5A8FFF"],
    ["#5A8FFF", "#5AB2FF"],
  ];

  const generatedGradients: string[][] = [...base];
  let lastColor: string = base[base.length - 1][1];
  let hueIncrement: number = 5; // Adjust this value to control the hue increment

  for (let i: number = base.length; i < numGradients; i++) {
    const lastColorRGB: RGBColor = hexToRgb(lastColor);

    // Adjust the hue to create a different shade of blue
    let nextHue: number = parseInt(lastColor.slice(1), 16) + hueIncrement;

    // Ensure the hue remains within the blue spectrum
    nextHue = nextHue % 360; // Limit the hue value to 360 degrees
    nextHue = nextHue < 0 ? nextHue + 360 : nextHue; // Ensure positive value

    const nextColor: string = hslToHex(nextHue, 100, 50);

    generatedGradients.push([lastColor, nextColor]);
    lastColor = nextColor;
  }

  return generatedGradients;
}

const generateShadesOfBlue = (numColors: number) => {
  const colors = [];
  for (let i = 0; i < numColors; i++) {
    const hue = (80 / numColors) * (numColors - i); // Distribute hues evenly
    const finalHue = hue + 170;
    const color = `hsl(${finalHue}, 100%, 50%)`; // Adjust saturation and lightness as needed
    colors.push(color);
  }
  return colors;
};

const LegendText = (value: string, entry: any) => {
  const { color } = entry;

  return <span className="capitalize text-black">{value}</span>;
};

function hslToHex(h: number, s: number, l: number): string {
  // Convert HSL to RGB, then RGB to HEX
  const rgbColor = hslToRgb(h, s, l);
  return rgbToHex(rgbColor.r, rgbColor.g, rgbColor.b);
}

function hslToRgb(h: number, s: number, l: number): RGBColor {
  // Formula to convert HSL to RGB
  let r, g, b;
  const hue = h / 360;
  const saturation = s / 100;
  const lightness = l / 100;

  if (saturation === 0) {
    r = g = b = lightness;
  } else {
    const hueToRgb = (p: number, q: number, t: number) => {
      if (t < 0) t += 1;
      if (t > 1) t -= 1;
      if (t < 1 / 6) return p + (q - p) * 6 * t;
      if (t < 1 / 2) return q;
      if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
      return p;
    };

    const q =
      lightness < 0.5
        ? lightness * (1 + saturation)
        : lightness + saturation - lightness * saturation;
    const p = 2 * lightness - q;

    r = hueToRgb(p, q, hue + 1 / 3);
    g = hueToRgb(p, q, hue);
    b = hueToRgb(p, q, hue - 1 / 3);
  }

  return {
    r: Math.round(r * 255),
    g: Math.round(g * 255),
    b: Math.round(b * 255),
  };
}

function rgbToHex(r: number, g: number, b: number): string {
  return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
}

function hexToRgb(hex: string): RGBColor {
  const bigint: number = parseInt(hex.slice(1), 16);
  const r: number = (bigint >> 16) & 255;
  const g: number = (bigint >> 8) & 255;
  const b: number = bigint & 255;
  return { r, g, b };
}
const renderActiveShape = (props: PieSectorDataItem) => {
  const RADIAN = Math.PI / 180;
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
    percent,
    value,
    name,
    cornerRadius,
  } = props;

  if (typeof midAngle === "number" && outerRadius && cx && cy && percent) {
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 10) * cos;
    const my = cy + (outerRadius + 15) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? "start" : "end";

    return (
      <g>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
          cornerRadius={cornerRadius}
        />
        <text
          x={ex + (cos >= 0 ? 1 : -1) * 12}
          y={ey}
          textAnchor={textAnchor}
          fill="#333"
          className="capitalize"
        >{`${name}`}</text>
        <text
          x={ex + (cos >= 0 ? 1 : -1) * 12}
          y={ey}
          dy={18}
          textAnchor={textAnchor}
          fill="#999"
        >
          {`${(percent * 100).toFixed(2)}% ( ${value} )`}
        </text>
      </g>
    );
  } else {
    return <g></g>;
  }
};

export const PieGraph: React.FC<IPieGraph> = ({
  data,
  dataKey = "value",
  heading = "",
  subText = "",
  total,
  startAngle,
  endAngle,
}) => {
  const [activeIndex, setActiveIndex] = useState<number | number[]>([]);

  // const gradients = generateGradient(data.length);
  const gradients = generateShadesOfBlue(data.length);

  const handleMouseEnter = (index: number) => {
    setActiveIndex(index);
  };

  const handleMouseLeave = () => {
    setActiveIndex([]);
  };

  return (
    <div>
      <GraphHeader heading={heading} subText={subText} />

      <ResponsiveContainer width="100%" height={450}>
        <PieChart>
          {/* <defs>
          {gradients.map((gradient, index) => (
            <linearGradient
              key={`gradient-${index}`}
              id={`gradient-${index}`}
              x1="0%"
              y1="0%"
              x2="100%"
              y2="100%"
            >
              <stop offset="0%" stopColor={gradient[0]} />
              <stop offset="100%" stopColor={gradient[1]} />
            </linearGradient>
          ))}
        </defs> */}
          <Pie
            data={data}
            innerRadius={activeIndex === null ? 75 : 85} // Adjust innerRadius based on hover state
            outerRadius={activeIndex === null ? 125 : 135} // Adjust outerRadius based on hover state
            fill="url(#pieGradient)"
            activeShape={renderActiveShape}
            activeIndex={activeIndex}
            dataKey={dataKey}
            onMouseLeave={handleMouseLeave}
            onMouseEnter={(e, index) => handleMouseEnter(index)}
            startAngle={startAngle ?? 90}
            endAngle={endAngle ?? -270}
            cornerRadius={4}
          >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={`${gradients[index]}`} />
            ))}
          </Pie>
          <Legend
            iconType="circle"
            iconSize={8}
            // align="right"
            align="center"
            verticalAlign="top"
            layout="horizontal"
            // layout="vertical"
            formatter={LegendText}
          />
          <defs>
            <linearGradient id="pieGradient" x1="0%" y1="0%" x2="0%" y2="100%">
              {gradients.map((_, index) => (
                <stop
                  key={`stop-${index}`}
                  offset={`${index * (100 / gradients.length)}%`}
                  stopColor={`url(#gradient-${index})`}
                />
              ))}
            </linearGradient>
          </defs>
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
};
