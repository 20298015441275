import { Loader } from "../Loader";

export interface IRadioOption {
  id: number;
  value: string;
  label?: string | React.ReactNode;
}

export interface IRadioButtonProps {
  label?: string;
  options: IRadioOption[];
  selected: IRadioOption | null; // Add selected prop
  onChange: (selected: IRadioOption) => void;
  loading?: boolean;
}

export function RadioButton({
  label,
  options,
  selected,
  onChange,
  loading,
}: IRadioButtonProps) {
  const handleSelect = (option: IRadioOption) => {
    onChange(option);
  };

  return (
    <div className="flex flex-col space-y-2">
      {label && (
        <label className="text-sm font-medium text-gray-700">{label}</label>
      )}
      <div className="flex space-x-4">
        {options.map((option) => (
          <div
            key={option.id}
            onClick={() => selected?.id !== option.id && handleSelect(option)}
            className={`select-none place-content-center rounded-md px-2 py-2 shadow-md md:px-4 ${
              selected?.id === option.id
                ? "border border-theme-purple bg-[#7851A933] font-semibold text-theme-purple"
                : "cursor-pointer bg-white text-gray-700"
            }`}
          >
            {loading && selected?.id === option.id ? (
              <Loader className="mx-8 text-theme-purple" />
            ) : (
              option.label || option.value
            )}
          </div>
        ))}
      </div>
    </div>
  );
}
