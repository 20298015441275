import clsx from "clsx";
import { TenantHeader } from "../Header";
import { Loader, LOADER_SIZE } from "../Loader";
import { ErrorMessage, Form, Formik } from "formik";
import * as Yup from "yup";
import { CardSection } from "../CardSection";
import { BUTTON_KIND, BUTTON_SIZE } from "../Button";
import { map } from "lodash";
import { sections } from "./tenantForm";
import {
  renderField,
  isControlDisabled,
  isControlHidden,
} from "../../utils/renderField";
import { ITenant, updateTenant } from "./api";
import { useState } from "react";
import { toast } from "react-toastify";
import { notifyError, notifySuccess } from "../../utils/notify";

const getNestedValue = (obj: any, keyPath: string) => {
  const keys = keyPath.split(".");
  let value = keys.reduce((acc, key) => acc && acc[key], obj);
  return value ?? null;
};

export const TenantDetails = ({
  data,
  loading = false,
  mutate,
}: {
  data: ITenant | undefined;
  loading: boolean;
  mutate: () => void;
}) => {
  const [editing, setIsEditing] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onSubmit = async (
    values: ITenant,
    { setSubmitting, validateForm }: any
  ) => {
    setIsSubmitting(true);
    const errors = await validateForm();
    if (Object.keys(errors).length > 0) {
      setSubmitting(false);
      toast.error("Please correct the validation errors.");
      return;
    }
    const payload: ITenant = {
      tenantName: values.tenantName,
      contact: values.contact,
      firstName: values.firstName,
      lastName: values.lastName,
      street: values.street,
      city: values.city,
      state: values.state,
      country: values.country,
      zipCode: values.zipCode,
      description: values.description,
      email: values.email,
    };
    try {
      const { data: update, error } = await updateTenant(data!.uuid!, payload);
      if (error) {
        notifyError(
          "We couldn't update the Tenant information. Please try again"
        );
        return console.log(error);
      }
      notifySuccess(`Tenant ${payload.tenantName} was updated successfully`);
      mutate();
      setIsEditing(false);
    } catch (error) {
      console.error(error);
    } finally {
      setIsSubmitting(false);
    }
  };
  const validationSchema = Yup.object().shape({
    tenantName: Yup.string().required("Tenant Name is required"),
    firstName: Yup.string().required("First Name is required"),
    lastName: Yup.string().required("Last Name is required"),
    contact: Yup.string().required("Contact is required"),
    street: Yup.string().required("Street is required"),
    city: Yup.string().required("City is required"),
    state: Yup.string().required("State is required"),
    country: Yup.string().required("Country is required"),
    zipCode: Yup.string().required("Zip Code is required"),
  });

  return (
    <Formik
      initialValues={{
        uuid: getNestedValue(data, "uuid") || "",
        tenantName: getNestedValue(data, "tenantName") || "",
        contact: getNestedValue(data, "contact") || "",
        firstName: getNestedValue(data, "firstName") || "",
        lastName: getNestedValue(data, "lastName") || "",
        street: getNestedValue(data, "street") || "",
        city: getNestedValue(data, "city") || "",
        state: getNestedValue(data, "state") || "",
        country: getNestedValue(data, "country") || "",
        zipCode: getNestedValue(data, "zipCode") || "",
        description: getNestedValue(data, "description") || "",
        email: getNestedValue(data, "email") || "",
      }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      enableReinitialize={true}
    >
      {(props) => (
        <Form>
          <div className="flex flex-col space-y-6">
            <CardSection
              title={"Organization"}
              headerActionItemsItems={
                editing
                  ? [
                      {
                        children: "Cancel",
                        kind: BUTTON_KIND.simple,
                        size: BUTTON_SIZE.sm,
                        className: "md:!px-12",
                        disabled: loading,
                        onClick: () => {
                          setIsEditing(false);
                          props.resetForm();
                        },
                      },
                      {
                        children: "Save",
                        kind: BUTTON_KIND.secondary,
                        size: BUTTON_SIZE.sm,
                        className: "md:!px-12",
                        submit: true,
                        loading: isSubmitting,
                      },
                    ]
                  : [
                      {
                        children: "Edit",
                        kind: BUTTON_KIND.simple,
                        size: BUTTON_SIZE.sm,
                        className: "md:!px-12",
                        disabled: isSubmitting,
                        onClick: () => {
                          setIsEditing(true);
                        },
                      },
                    ]
              }
            >
              {loading ? (
                <div className="flex h-full flex-col items-center justify-center gap-6">
                  <Loader
                    size={LOADER_SIZE.lg}
                    className="h-10 w-10 self-center text-theme-purple"
                  />
                </div>
              ) : (
                <div className="space-y-8">
                  {map(sections, ({ sections, title }, key) => (
                    <div key={key}>
                      {title && title.length > 0 && (
                        <p className="mb-4 text-xl font-semibold capitalize text-gray-900">
                          {title}
                        </p>
                      )}
                      <div className="space-y-8">
                        {map(
                          sections,
                          (
                            { children, title, className = "grid-cols-3" },
                            key
                          ) => (
                            <div key={key}>
                              {title && title.length > 0 && (
                                <p className="mb-4 text-lg font-medium capitalize text-gray-700">
                                  {title}
                                </p>
                              )}
                              <div
                                className={clsx("grid gap-6 pl-3", className)}
                                key={key}
                              >
                                {map(children, (value, key) => {
                                  if (isControlHidden(value, props))
                                    return null;
                                  return (
                                    <div key={key}>
                                      <label className="mb-2 block text-sm font-semibold text-gray-700">
                                        {value.label === "Tenant ID" &&
                                          value.label}
                                      </label>
                                      {map(value.fields, (field, fieldKey) => (
                                        <div
                                          key={fieldKey}
                                          className={`mb-4 ${field.type !== "textarea" ? "max-w-96" : "w-full"}`}
                                        >
                                          {renderField(
                                            field,
                                            props,
                                            isControlDisabled,
                                            !editing
                                          )}
                                          <ErrorMessage
                                            name={field.name}
                                            component="div"
                                            className="mt-1 text-sm text-red-600"
                                          />
                                        </div>
                                      ))}
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          )
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </CardSection>
          </div>
        </Form>
      )}
    </Formik>
  );
};
