import { useMemo, useState } from "react";
import { useSite } from "../context/SiteProvider";
import { useSWR } from "../hooks/useSWR";
import { endpoints } from "../services/api";
import { LineGraph, PieGraph } from "../stories";
import { ActivityLog } from "../stories/ActivityLog";
import { IActivityLogItem } from "../stories/ActivityLogTable";
import { CardWithLogo } from "../stories/Card";
import { Loader, LOADER_SIZE } from "../stories/Loader";
import { IRadioOption, RadioButton } from "../stories/RadioButton";
import cleanImg from "../stories/assets/clean.png";
import hazardImg from "../stories/assets/hazard.png";
import warningImg from "../stories/assets/warning.png";
import { formatNumber } from "../utils/conversions";
import { getRangeDateString } from "../utils/helper";
export interface IDashboardAPI {
  totalDevices: number;
  cleanDevices: number;
  quarantinedDevices: number;
  suspiciousDevices: number;
  osCounts: { label: string; value: number }[];
  totalArmors: { timestamp: number; label: string; value: number }[];
  totalThreats: { timestamp: number; label: string; value: number }[];
}
export default function DashboardScreen() {
  const rangeValues = useMemo(
    () => [
      {
        id: 1,
        label: "Last 24 Hours",
        shortLabel: "24hr",
        value: getRangeDateString(1),
      },
      {
        id: 2,
        label: "Last 7 Days",
        shortLabel: "7d",
        value: getRangeDateString(7),
      },
      {
        id: 3,
        label: "Last 14 Days",
        shortLabel: "14d",
        value: getRangeDateString(14),
      },
      {
        id: 4,
        label: "Last 30 Days",
        shortLabel: "30d",
        value: getRangeDateString(30),
      },
    ],
    []
  );
  const { selectedSite } = useSite();
  const [currentPage, setCurrentPage] = useState(1);
  const size = 5;

  const [selectedRange, setSelectedOption] = useState<IRadioOption>(
    rangeValues[0]
  );
  const [currentTimestamp, setCurrentTimestamp] = useState<string>(
    getRangeDateString(0)
  );
  const { data, error, loading, mutate } = useSWR<IDashboardAPI>(
    selectedSite?.uuid
      ? endpoints.dashboard.get(
          selectedSite?.uuid,
          selectedRange.value,
          currentTimestamp
        )
      : null
  );

  const { data: activityData, loading: activityLoading } = useSWR<{
    data: IActivityLogItem[];
    hasNext: boolean;
  }>(
    selectedSite?.uuid
      ? endpoints.activityLogs.get(selectedSite?.uuid, currentPage, size)
      : null
  );

  const handleActivityNextPage = () => {
    const nextPage = currentPage + 1;
    setCurrentPage(nextPage);
  };

  const handleActivityPrevPage = () => {
    if (currentPage > 1) {
      const prevPage = currentPage - 1;
      setCurrentPage(prevPage);
    }
  };

  const handleRadioChange = (selected: IRadioOption) => {
    setSelectedOption(selected);
    setCurrentTimestamp(getRangeDateString(0));
  };
  return (
    <div className="m-4 flex flex-col gap-4 rounded-lg border-0 md:m-6">
      <div className="text-xl font-semibold">Device Protection Overview</div>
      <div className="flex flex-col gap-y-4 md:gap-y-6 ">
        <div className="flex flex-col justify-center gap-6 pt-2">
          <div className="flex flex-grow flex-col gap-x-4 gap-y-4 lg:flex-row">
            <CardWithLogo
              logo={cleanImg}
              heading="Clean"
              description="Secure. No ransomware detected."
              value={data?.cleanDevices}
            />
            <CardWithLogo
              logo={warningImg}
              heading="Quarantined"
              description="Secure. Malicious software quarantined."
              value={data?.quarantinedDevices}
            />
            <CardWithLogo
              logo={hazardImg}
              heading="Suspicious"
              description="Suspicious. Suspicious activity detected."
              value={data?.suspiciousDevices}
            />
          </div>
          <div className="relative  rounded-lg border-0 bg-white p-4 ">
            <div className="flex flex-col justify-between bg-white p-4 lg:flex-row ">
              <div>
                <h3 className="text-xl font-semibold capitalize">
                  Total Threats
                </h3>
                <p className="text-base font-normal text-theme-gray">
                  Total threats detected across all assets
                </p>
              </div>
              <RadioButton
                selected={selectedRange}
                options={rangeValues.map((option) => ({
                  ...option,
                  label: (
                    <>
                      <span className="block md:hidden">
                        {option.shortLabel}
                      </span>
                      <span className="hidden md:block">{option.label}</span>
                    </>
                  ),
                }))}
                onChange={handleRadioChange}
              />
            </div>
            <div className=" rounded-lg border-0 bg-white px-4 pb-4">
              {loading && (
                <div className="absolute left-0 top-0 flex h-[100%]  w-[100%] items-center justify-center rounded-lg bg-neutral-200  bg-opacity-25 ">
                  <Loader className="text-theme-purple" size={LOADER_SIZE.xl} />
                </div>
              )}
              <LineGraph
                data={
                  data?.totalThreats
                    ? data?.totalThreats.map((v) => {
                        return {
                          ...v,
                          timestamp: Math.floor(new Date(v.label).getTime()),
                        };
                      })
                    : []
                }
                heading={""}
                xAxisKey={"timestamp"}
                yAxisKeys={["value"]}
                yAxisUnit={""}
                yAxisFormatterFxn={formatNumber}
                showLegend={true}
                yAxisNames={["Total Threats"]}
                domain={[
                  new Date(selectedRange.value),
                  new Date(currentTimestamp),
                ]}
                loading={loading}
              />
            </div>
          </div>
          <div className="grid grid-cols-1 gap-4 lg:grid-cols-2 ">
            <div className="relative rounded-lg border-0 bg-white p-4">
              {loading && (
                <div className="absolute left-0 top-0 flex h-[100%]  w-[100%] items-center justify-center rounded-lg bg-neutral-200  bg-opacity-25 ">
                  <Loader className="text-theme-purple" size={LOADER_SIZE.xl} />
                </div>
              )}
              <LineGraph
                data={
                  data?.totalArmors
                    ? data?.totalArmors.map((v) => {
                        return {
                          ...v,
                          timestamp: Math.floor(new Date(v.label).getTime()),
                        };
                      })
                    : []
                }
                heading={"Total Armors"}
                xAxisKey={"timestamp"}
                yAxisKeys={["value"]}
                yAxisUnit={""}
                yAxisFormatterFxn={formatNumber}
                showLegend={true}
                yAxisNames={["RansomArmor"]}
                domain={[
                  new Date(data?.totalArmors[0]?.label),
                  new Date(
                    data?.totalArmors[data.totalArmors.length - 1]?.label
                  ),
                ]}
                loading={loading}
              />
            </div>
            <div className="rounded-lg border-0 bg-white p-4">
              {data && data.osCounts && (
                <PieGraph
                  data={data.osCounts
                    .map((v) => {
                      return { ...v, name: v.label };
                    })
                    .sort((a, b) => {
                      return b.value - a.value;
                    })}
                  dataKey={"value"}
                  heading="Operating Systems"
                />
              )}
            </div>
            {/* <div className="rounded-md border-0 bg-white p-4">
                  <BarGraph
                    data={dashboardData.topThreats.data}
                    heading="Top threats"
                    badgeType="threat"
                    // difference={15}
                  />
                </div>
                <div className="rounded-md border-0 bg-white p-4">
                  <PieGraph
                    heading="Normal Vs. Suspicious Behavior"
                  
                    data={dashboardData.behavior.data}
                    dataKey={dashboardData.behavior.dataKey}
                    startAngle={dashboardData.behavior.startAngle}
                    endAngle={dashboardData.behavior.endAngle}
                  />
                </div> */}
          </div>
          <ActivityLog
            data={activityData?.data}
            onClickNext={handleActivityNextPage}
            loading={activityLoading}
            onClickPrevious={handleActivityPrevPage}
            isNextDisabled={activityData ? !activityData?.hasNext : true}
            isPrevDisabled={currentPage === 1}
          />
        </div>
      </div>
    </div>
  );
}
