import { TextareaHTMLAttributes } from "react";
import clsx from "clsx";
import { ICON_NAME, Icon } from "../Icon";
import { useField } from "formik";

export interface ITextAreaField
  extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  label?: string;
  fieldClass?: string;
  name: string;
  inputsize?: "xs" | "sm" | "base"; //using inputsize since size is predefined
}

const InputSizeClasses = {
  xs: "py-1 text-xs/6 rounded",
  sm: "px-4 py-3 text-base/6 rounded-lg",
  base: "px-4 py-3 text-base/6 rounded-lg ",
};

const LabelClasses = {
  xs: "text-xs/6 peer-[:not(:placeholder-shown)]:-mt-[13px] peer-focus:-mt-[13px] peer-[:not(:placeholder-shown)]:left-3.5 peer-focus:left-3.5",
  sm: "text-base/6 peer-[:not(:placeholder-shown)]:-mt-5 peer-focus:-mt-5 peer-[:not(:placeholder-shown)]:left-4 peer-focus:left-4",
  base: "text-base/6 peer-[:not(:placeholder-shown)]:-mt-5 peer-focus:-mt-5 peer-[:not(:placeholder-shown)]:left-4 peer-focus:left-4",
};

export function TextArea(props: ITextAreaField) {
  const { label, name, fieldClass, inputsize = "base", ...rest } = props;
  const [field, meta] = useField(name);

  return (
    <div className="group relative z-0 w-full transition-all focus-within:z-10">
      <textarea
        id={name}
        {...props}
        placeholder=" " // Keep it for Label animation
        className={clsx(
          "peer block w-full border border-neutral-300 bg-transparent text-neutral-950 transition focus:border-theme-gray focus:outline-none focus:ring-theme-gray",
          InputSizeClasses[inputsize],
          fieldClass,
          props.disabled ? "!bg-gray-50" : ""
        )}
        {...field}
        {...rest}
      />
      <label
        htmlFor={name}
        className={clsx(
          "pointer-events-none absolute left-4 top-4 origin-left -translate-y-2 scale-75 transform bg-transparent px-1 font-normal text-theme-gray transition-all duration-200 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:-translate-y-2 peer-focus:scale-75 peer-focus:bg-white peer-[:not(:placeholder-shown)]:bg-white",
          LabelClasses[inputsize],
          !props.disabled ? "after:hidden" : "after:block", //If disabled, then the color should match the background one without border overlapping
          "after:absolute after:inset-0 after:top-1/2 after:-z-10 after:bg-gray-50"
        )}
      >
        {label}
      </label>
      {meta.error && meta.touched && (
        <div className="absolute inset-y-0 right-4 flex items-center gap-1">
          <Icon
            name={ICON_NAME.info}
            className="pointer-events-none h-5 w-5 text-red-500"
            aria-hidden="true"
          />
        </div>
      )}
    </div>
  );
}
