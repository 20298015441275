export const Avatar = ({ initials }: { initials: string }) => {
  return (
    <div className="flex w-fit cursor-pointer items-center gap-4 text-sm font-medium text-[#141414]">
      <button
        type="button"
        className="inline-flex size-12 items-center justify-center rounded-md bg-theme-blue md:size-14"
        onClick={() => {}}
      >
        <p className="text-center font-semibold leading-none text-white">
          {initials}
        </p>
      </button>
    </div>
  );
};
