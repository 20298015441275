import { createColumnHelper, Updater } from "@tanstack/react-table";
import { IAuditLogsItem } from "../../pages/Audits.page";
import { Loader, LOADER_SIZE } from "../Loader";
import { PaginationButton } from "../Pagination";
import { Placeholder } from "../Placeholder";
import { Table } from "../Table";
import { TableHeader } from "../TableHeader";
import moment from "moment";

export interface IAuditLogTable {
  data: IAuditLogsItem[];
  loading: boolean;
  currentPage: number;
  totalPages: number;
  setCurrentPage: (page: number) => void;
  hasNext: boolean;
  size: number;
  updateQueryParams: (pageNo: number) => void;
}

const columnHelper = createColumnHelper<IAuditLogsItem>();

const logColumns = [
  columnHelper.accessor("created", {
    header: "Date and Time",
    cell: (info) => moment(info.getValue()).format("MM/DD H:mm:ss:SS"),
  }),
  columnHelper.accessor("user", {
    header: "User",
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor("sourceIp", {
    header: "Source IP",
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor("action", {
    header: "Action",
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor("resource", {
    header: "Resource",
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor("message", {
    header: "Message",
    cell: (info) => info.getValue(),
    sortingFn: "alphanumeric",
  }),
];

export const AuditLogTable = ({
  data,
  loading,
  currentPage,
  hasNext,
  setCurrentPage,
  totalPages,
  size,
  updateQueryParams,
}: IAuditLogTable) => {
  const handlePageChange = (updater: Updater<number>) => {
    const nextPage =
      typeof updater === "function" ? updater(currentPage) : updater;
    setCurrentPage(nextPage);
    updateQueryParams(nextPage);
  };

  const handleNextPage = () => {
    const nextPage = currentPage + 1;
    setCurrentPage(nextPage);
    updateQueryParams(nextPage);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      const prevPage = currentPage - 1;
      setCurrentPage(prevPage);
      updateQueryParams(prevPage);
    }
  };

  return (
    <div className="m-4 flex h-auto min-h-[calc(100vh-230px)] flex-col gap-4 rounded-lg border-0 bg-white p-4 md:m-6 md:p-6">
      {loading ? (
        <div className="flex h-full flex-1 flex-col items-center justify-center gap-6">
          <Loader
            size={LOADER_SIZE.lg}
            className="h-10 w-10 self-center text-theme-purple"
          />
        </div>
      ) : (
        <Table
          headerComponent={() => (
            <TableHeader
              heading="Audit Log"
              columnData={[]}
              showSearchField={false}
            />
          )}
          columns={logColumns}
          data={data}
          emptyTablePlaceholder={
            <Placeholder
              label="No logs entries found"
              description="Audit log entries will be displayed here"
            />
          }
          cellStyle="normal-case"
          onClick={() => {}}
        />
      )}

      <PaginationButton
        total={totalPages}
        current={currentPage}
        prevLabel="Previous"
        nextLabel="Next"
        perPage={size}
        paginationHandler={handlePageChange}
        handleNext={handleNextPage}
        handlePrev={handlePrevPage}
        isNextDisabled={!hasNext}
        isPrevDisabled={currentPage === 1}
      />
    </div>
  );
};
