import { Icon, ICON_NAME } from "../Icon";
import { TableHeader } from "../TableHeader";
import {
  convertRamInfo,
  convertUptime,
  formatDateToUS,
} from "../../utils/helper";
import { Device } from "./api";
import { useState } from "react";
import { Button } from "../Button";
import clsx from "clsx";
import { NetWrapper } from "../../utils/netwrapper";
import { endpoints } from "../../services/api";
import { notifyError, notifySuccess } from "../../utils/notify";
import TagsInput from "../TagsInput/TagsInput";

const deviceFields: { key: string; label: string }[] = [
  // { key: "deviceType", label: "Device Type" },
  { key: "systemStatus", label: "System Status" },
  // { key: "deviceStatus", label: "Device Status" },
  { key: "deviceUuid", label: "Device ID" },
  { key: "lastActive", label: "Last Active" },
  { key: "osVersion", label: "OS Version" },
  { key: "uptime", label: "Uptime" },
  { key: "deviceInfo.arxRaVersion", label: "ArmorxAI Version" },
  { key: "mac", label: "MAC Address" },
  { key: "ip", label: "IP Address" },
  // { key: "adapterName", label: "Adapter Name" },
  { key: "deviceInfo.domainOrWorkgroup", label: "Domain" },
  { key: "cpuInfo", label: "CPU Information" },
  { key: "ramInfo", label: "Total Memory (RAM)" },
  { key: "arch", label: "Architecture" },
  // { key: "deviceInfo.ransomStatus", label: "Ransomware Status" },
  { key: "deviceInfo.kernelVersion", label: "Kernel Version" },
  { key: "labels", label: "Label" },
  // { key: "deviceInfo.groupName", label: "Group" },
  // { key: "manufacturer", label: "Manufacturer" },
  // { key: "siteName", label: "Site Name" },
  // { key: "customerName", label: "Customer Name" },
];

const getNestedValue = (obj: any, keyPath: string) => {
  const keys = keyPath.split(".");
  let value = keys.reduce((acc, key) => acc && acc[key], obj);

  if (keys.includes("lastActive") && value) {
    value = formatDateToUS(value, false); // Parse the date and format it
  }

  if (keys.includes("uptime")) {
    value = convertUptime(value); // Convert seconds to days/hrs/minutes
  }

  if (keys.includes("ramInfo")) {
    value = convertRamInfo(value);
  }

  if (keys.includes("hostName")) {
    return <p className=" text-theme-purple">{value}</p>;
  }
  if (keys.includes("domainOrWorkgroup")) {
    return (
      <p>
        {value
          ? value.toLowerCase() !== "not_found"
            ? value
            : "Not Available"
          : "Not Available"}
      </p>
    );
  }

  if (keys.includes("mac") || keys.includes("ip")) {
    return (
      <div>
        {value ? (
          value
            .split(",")
            .map((v: string) => <p className="font-medium">{v}</p>)
        ) : (
          <p className="font-medium">{value}</p>
        )}
      </div>
    );
  }
  if (keys?.includes("cpuInfo")) {
    return (
      <div className="grid grid-cols-[auto,1fr] gap-x-4 capitalize">
        {value ? (
          Object.entries(value).map(([key, value]) => (
            <p>
              {`${key}:  `}
              <span className="font-medium">{`${value}`}</span>
            </p>
          ))
        ) : (
          <p className="font-medium">{value}</p>
        )}
      </div>
    );
  }
  if (keys?.includes("osVersion")) {
    return <p className="capitalize">{value}</p>;
  }
  if (keys.includes("labels")) {
    return (
      <div className="flex flex-wrap">
        {value ? (
          value.split(",").map((v: string, index: number) => (
            <p
              key={index}
              className="mr-1 max-w-60 font-medium supports-[overflow-wrap:anywhere]:[overflow-wrap:anywhere]"
            >
              {v.trim()}
              {index < value.split(",").length - 1 && ","}
            </p>
          ))
        ) : (
          <p className="font-medium">
            {value.trim() === "" ? "N/A" : value.trim()}
          </p>
        )}
      </div>
    );
  }

  return value ?? null;
};

export const AssetsDetails = ({
  data,
  updateDevice,
}: {
  data: Device | undefined;
  updateDevice: () => void;
}) => {
  const [editingTags, setEditingTags] = useState<boolean>(false);
  const [tags, setTags] = useState<string[]>([]);
  const [isExpanded, setIsExpanded] = useState(false);

  const handleTagsChange = (newTags: string[]) => {
    setTags(newTags);
  };
  const handleTagsSubmit = async (e: { preventDefault: () => void }) => {
    e.preventDefault();
    try {
      const response = await NetWrapper(
        endpoints.device.update(data?.deviceUuid ?? ""),
        {
          method: "PUT",
          body: JSON.stringify({ labels: tags.join(",") }),
        }
      );
      if (response.status === 200) {
        updateDevice();
        notifySuccess(
          `Device tag(s) for ${data?.hostName} were updated successfully`
        );
      } else {
        notifyError(response.error?.message || "Something went wrong");
      }
    } catch (error) {
      console.error(error);
      notifyError("Something went wrong");
    } finally {
      setEditingTags(false); // Exit edit mode after submission
    }
  };
  return (
    <div className="flex flex-col">
      <div className="mb-2 flex-col gap-4 rounded-md  border-0 bg-white p-4">
        <TableHeader
          heading={"Device: ".concat(data?.hostName ?? "")}
          columnData={[]}
          showSearchField={false}
        />
      </div>
      <div className="flex h-fit flex-col gap-4 rounded-md  border-0 bg-white p-4 ">
        <div className="grid grid-cols-3 gap-4 md:gap-x-5 md:gap-y-8">
          <div>
            <label className="block pb-2 text-sm font-medium text-gray-600">
              Device Type
            </label>
            <span
              title={data?.deviceType}
              className="h-8 w-8 self-center align-middle text-2xl"
            >
              <Icon
                name={
                  ICON_NAME[
                    data?.deviceType?.toLowerCase() as keyof typeof ICON_NAME
                  ]
                }
              />
            </span>
          </div>
          {deviceFields
            .slice(0, !isExpanded ? 5 : undefined)
            .map((field, index) => (
              <div key={index}>
                <label
                  className={clsx(
                    "block text-sm font-medium text-gray-600",
                    field.label === "Label" && editingTags ? "pb-0.5" : "pb-2"
                  )}
                >
                  <span className="flex items-center">
                    {field.label}
                    {field.label === "Label" && (
                      <span
                        title="Click to Edit Label"
                        onClick={() => setEditingTags(!editingTags)}
                      >
                        <Icon
                          name={editingTags ? ICON_NAME.x : ICON_NAME.penIcon}
                          className="ml-2 inline-block cursor-pointer"
                        />
                      </span>
                    )}
                  </span>
                </label>
                {field.key === "labels" && editingTags === true ? (
                  <div className="flex items-center">
                    <TagsInput
                      inputTags={
                        data?.labels && data.labels !== "N/A"
                          ? data?.labels
                          : null
                      }
                      label="Enter Labels..."
                      onTagsChange={handleTagsChange}
                    />

                    <Button
                      kind="primary"
                      submit
                      className="ml-2 py-2"
                      children="Save"
                      size="xs"
                      onClick={handleTagsSubmit}
                    />
                  </div>
                ) : (
                  <div className="text-base text-black">
                    {getNestedValue(data, field.key)}
                  </div>
                )}
              </div>
            ))}
        </div>

        <div
          className="m-auto flex cursor-pointer flex-row items-center gap-3 self-center text-center"
          onClick={() => {
            setIsExpanded(!isExpanded);
          }}
        >
          <span className="text-nowrap font-medium text-theme-purple">
            Show More Details
          </span>
          <Icon
            name={isExpanded ? ICON_NAME.chevronUp : ICON_NAME.chevronDown}
            className=" h-4 w-4 "
          />
        </div>
      </div>
    </div>
  );
};
