import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import {
  AssetGraphs,
  AssetsDetails,
  Device,
  useFetchDevice,
  useFetchMetrics,
} from "../stories/Devices";
import { devicePolyData } from "../stories/Devices/mockData";
import { IRadioOption, RadioButton } from "../stories/RadioButton";
import { getRangeDateString } from "../utils/helper";

export default function DevicesDetailsScreen() {
  const [device, setDevice] = useState<Device>();
  const [polyrationalData, setPolyrationalData] = useState<
    typeof devicePolyData
  >([]);
  const { deviceUuid } = useParams();
  const [currentTimestamp, setCurrentTimestamp] = useState<string>(
    getRangeDateString(0)
  );
  const rangeValues: IRadioOption[] = useMemo(
    () => [
      { id: 1, label: "Last 24 Hours", value: getRangeDateString(1) },
      { id: 2, label: "Last 7 Days", value: getRangeDateString(7) },
      { id: 3, label: "Last 14 Days", value: getRangeDateString(14) },
      { id: 4, label: "Last 30 Days", value: getRangeDateString(30) },
    ],
    []
  );

  const [selectedRange, setSelectedOption] = useState<IRadioOption>(
    rangeValues[0]
  );

  const { data: deviceData, mutate: updateDevice } = useFetchDevice(deviceUuid);
  const { data: metrics, loading: metricsLoading } = useFetchMetrics(
    deviceUuid,
    selectedRange.value,
    currentTimestamp
  );

  useEffect(() => {
    if (deviceData) {
      setDevice(deviceData.data);
    }
  }, [deviceData]);

  useEffect(() => {
    if (metrics) {
      setPolyrationalData(metrics);
    }
  }, [metrics]);

  const handleRadioChange = (selected: IRadioOption) => {
    setSelectedOption(selected);
    setCurrentTimestamp(getRangeDateString(0));
  };

  return (
    <div className="m-4 flex h-auto min-h-[calc(100vh-144px)]   flex-col gap-2 rounded-lg border-0 md:m-6">
      <AssetsDetails data={device} updateDevice={updateDevice} />
      <div className="flex items-end justify-between pt-6">
        <div className=""></div>
        <RadioButton
          selected={selectedRange}
          options={rangeValues}
          onChange={handleRadioChange}
        />
      </div>
      <AssetGraphs
        data={polyrationalData}
        dateRange={[new Date(selectedRange.value), new Date(currentTimestamp)]}
        loading={metricsLoading}
      />
    </div>
  );
}
