import { useSWR } from "../../hooks/useSWR";
import { endpoints } from "../../services/api";
import { IRequest } from "../../utils/net";
import { NetWrapper } from "../../utils/netwrapper";

export interface IPolicy {
  id?: string;
  policyId?: string;
  policyName: string;
  description: string;
  created?: string;
  updated?: string;
  action?: boolean;
  selected?: boolean;
  status: string;
  protectionMode: string;
  applyGlobally: boolean;
  isDefaultPolicy?: boolean;
  isEnforced?: boolean;
  tenantId?: string;
  version?: string;
}

export interface PolicyDetails {
  id: string;
  applyGlobally: boolean;
  description: string;
  directories: IDirectory[];
  isDefaultPolicy: boolean;
  created: string;
  isEnforced?: boolean;
  policyId: string;
  policyName: string;
  protectionMode: string;
  siteIds: any[];
  status: string;
  updated: string;
  selected: boolean;
  userDirectories: IDirectory[];
  whitelistedProcesses: IWhitelist[];
  wdacPolicy?: IWDACPolicy;
}

export interface IDirectoryWithFlag extends IDirectory {
  isUserDirectory?: boolean;
}

export interface IDirectory {
  id: string;
  path: string;
  timestamp: string;
  action: string;
  isDefault?: boolean;
  actionButton?: boolean;
}
export interface IWhitelist {
  id: string;
  path: string;
  action: string;
  timestamp: string;
  isDefault?: boolean;
  actionButton?: boolean;
}
export interface IWDACPolicy {
  enabled: boolean;
  version: string;
  configurable: boolean;
  actionButton?: boolean;
}

export function useFetchPolicies({ tenantId }: { tenantId?: string }) {
  const { data, error, loading, mutate } = useSWR<{
    data: IPolicy[];
    hasNext: boolean;
  }>(tenantId ? endpoints.securityPolicy.all(tenantId) : null);
  return { data, error, loading, mutate };
}

export const createPolicy = async (payload: IPolicy) => {
  const url = "/api/v1/policies";
  const options: IRequest = {
    method: "POST",
    body: JSON.stringify(payload),
  };

  const { data, error } = await NetWrapper(url, options);
  return { data, error };
};

export function useFetchPolicy(deviceId: string | undefined) {
  const { data, error, loading, mutate } = useSWR<{ data: PolicyDetails }>(
    deviceId ? endpoints.securityPolicy.get(deviceId) : null
  );
  return { data, error, loading, mutate };
}

export const updatePolicy = async (
  id: string,
  payload: Partial<PolicyDetails>,
  tenantId: string
) => {
  const url = `/api/v1/policies/${id}`;
  const options: IRequest = {
    method: "PUT",
    body: JSON.stringify({ ...payload, tenantId }),
  };

  const { data, error } = await NetWrapper(url, options);
  return { data, error };
};

export const deletePolicy = async (id: string) => {
  const url = `/api/v1/policies/${id}`;
  const options: IRequest = {
    method: "DELETE",
  };
  const { data, error } = await NetWrapper(url, options);
  return { data, error };
};

export function useFetchPolicyList({ tenantId }: { tenantId?: string }) {
  const { data, error, loading, mutate } = useSWR<IPolicy[]>(
    tenantId ? endpoints.securityPolicy.names(tenantId) : null
  );
  return { data, error, loading, mutate };
}
