import { Badge } from "../../Badge";
import { InfoTooltip } from "../../Tooltip/InfoTooltip";

export interface IGraphHeader {
  heading: string;
  subText?: string;
  total?: number;
  badgeType?: "armor" | "threat";
  difference?: number;
  information?: {
    heading: string;
    details: string;
  };
}

export const GraphHeader = ({
  heading,
  subText,
  total,
  badgeType,
  difference,
  information,
}: IGraphHeader) => {
  return (
    <div className="flex items-center justify-between pl-3 pr-9">
      <div className="flex flex-col gap-1">
        <div className="flex gap-4">
          <h3 className="text-xl font-semibold capitalize">{heading}</h3>
          {difference && badgeType && (
            <Badge
              size="default"
              children={`+${difference}%`}
              kind={badgeType}
            />
          )}
        </div>
        {subText && <p className="text-xs font-medium">{subText}</p>}
      </div>
      {total && <p className="text-base font-medium">{total}</p>}
      {information && (
        <InfoTooltip
          header={information.heading}
          details={information.details}
        ></InfoTooltip>
      )}
    </div>
  );
};
