import clsx from "clsx";

export enum LOADER_SIZE {
  xs = "xs",
  sm = "sm",
  base = "base",
  lg = "lg",
  xl = "xl",
}

const loaderSizeClasses: { [key in LOADER_SIZE]: string } = {
  xs: "h-3 w-3",
  sm: "h-4 w-4",
  base: "h-4 w-5",
  lg: "h-6 w-6",
  xl: "h-7 w-7",
};
export interface LoaderProps {
  className: string;
  size: LOADER_SIZE;
}

export function Loader(props: LoaderProps): JSX.Element {
  const { className, size } = props;
  return (
    <svg
      className={clsx("animate-spin", loaderSizeClasses[size], className)}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
    >
      <circle
        className="opacity-25"
        cx="12"
        cy="12"
        r="10"
        stroke="currentColor"
        strokeWidth="4"
      ></circle>
      <path
        className="opacity-75"
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      ></path>
    </svg>
  );
}

Loader.defaultProps = {
  size: LOADER_SIZE.base,
  className: "text-white",
};
