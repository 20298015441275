import { usePopperTooltip } from "react-popper-tooltip";
import { Icon, ICON_NAME } from "../Icon";
export interface ITooltip {
  header?: string;
  details: string;
}

export const InfoTooltip = ({ details, header }: ITooltip) => {
  const {
    getArrowProps,
    getTooltipProps,
    setTooltipRef,
    setTriggerRef,
    visible,
  } = usePopperTooltip();

  return (
    <>
      <div
        ref={setTriggerRef}
        className=" flex   cursor-pointer items-center  justify-center    text-center leading-none"
      >
        <Icon className="h-6 w-6" name={ICON_NAME.info} />
      </div>

      {visible && (
        <div
          ref={setTooltipRef}
          {...getTooltipProps({
            className:
              "tooltip-container border z-10 rounded-md p-2  max-w-72 !bg-gray-50 text-gray-800 font-regular text-xs",
          })}
        >
          {header && <p className="mb-3 text-sm font-bold">{header}</p>}

          <span className="text-sm ">{details}</span>
        </div>
      )}
    </>
  );
};
