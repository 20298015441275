import clsx from "clsx";
import { Badge, BadgeProps } from "../Badge";
import { Button, ButtonProps, BUTTON_SIZE } from "../Button";
import { useState } from "react";

export interface CardSectionProps {
  className?: string;
  showTitleIcon?: boolean;
  badgesContainerItems?: BadgeProps[];
  headerActionItemsItems?: ButtonProps[];
  footerActionItemsItems?: ButtonProps[];
  title: string;
  subtitle?: string;
  subHeader?: string;
  children: JSX.Element | JSX.Element[];
  rightSideComponents?: JSX.Element;
  status?: BadgeProps;
  // need to find a fix for onClick event
  onClick?: (e: any) => void;
  showMore?: boolean;
  divider?: boolean;
  childClassName?: string;
}

export function CardSection(props: CardSectionProps): JSX.Element {
  const {
    className,
    showTitleIcon,
    rightSideComponents,
    badgesContainerItems,
    headerActionItemsItems,
    footerActionItemsItems,
    title,
    subtitle,
    subHeader,
    children,
    status,
    onClick,
    showMore,
    childClassName,
    divider,
    ...rest
  } = props;

  const [showContent, setShowContent] = useState(false);

  return (
    <div
      {...rest}
      className={clsx(
        className,
        "m-4 flex h-auto  flex-col gap-4 rounded-lg border-0 bg-white py-4 shadow md:m-6 md:py-6"
      )}
      onClick={onClick}
    >
      <div className="flex h-full flex-1 flex-col items-stretch justify-start space-y-4">
        <div className="inline-flex items-start justify-between space-x-4 px-4 md:px-6">
          <div className="inline-flex flex-col items-start justify-start space-y-1 ">
            <div className="flex flex-col items-start justify-start">
              {title && title.length > 0 && (
                <p className="text:lg font-semibold capitalize text-gray-900 md:text-xl">
                  {title}
                </p>
              )}
              {subtitle && subtitle.length > 0 && (
                <p className="text-sm capitalize text-theme-gray">{subtitle}</p>
              )}
            </div>

            {/* {status && <Badge {...status} />} */}
          </div>

          <div className="flex items-start justify-end space-x-4">
            {rightSideComponents && rightSideComponents}
            <div className="flex items-start justify-end space-x-2">
              {badgesContainerItems &&
                badgesContainerItems.length > 0 &&
                badgesContainerItems.map((props, key) => (
                  <Badge {...props} key={key} />
                ))}
            </div>
            <div className="flex items-start justify-end space-x-4">
              {headerActionItemsItems &&
                headerActionItemsItems.length > 0 &&
                headerActionItemsItems.map((props, key) => (
                  <Button {...props} key={key} />
                ))}
            </div>
          </div>
        </div>
        {divider && <div className="w-full border-b border-gray-200"></div>}
        {subHeader && subHeader.length > 0 && (
          <p className="w-full px-4 text-lg font-medium md:px-6">{subHeader}</p>
        )}
        <div
          className={clsx(
            "h-full px-4 md:px-6",
            showMore && (showContent ? "h-full" : "h-36 overflow-hidden"),
            childClassName
          )}
        >
          {children}
        </div>
        {showMore && (
          <Button
            submit={false}
            size={BUTTON_SIZE.xs}
            // kind={BUTTON_KIND.ghost}
            className="w-28"
            onClick={() => setShowContent(!showContent)}
          >
            {showContent ? "Show less" : "Show more"}
          </Button>
        )}
        <div className="inline-flex items-center justify-end ">
          <div className="flex items-start justify-start space-x-4">
            {footerActionItemsItems &&
              footerActionItemsItems.length > 0 &&
              footerActionItemsItems.map((props) => {
                return <Button {...props} />;
              })}
          </div>
        </div>
      </div>
    </div>
  );
}

CardSection.defaultProps = {
  badgesContainerItems: [],
  headerActionItemsItems: [],
  footerActionItemsItems: [],
  title: "",
  subtitle: "",
};
