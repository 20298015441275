import { Icon, ICON_NAME } from "../Icon";
import { Dropdown } from "../Dropdown";
import { ISideBarProfile, IUserDetails, ProfileAvatar } from "../ProfileAvatar";
import { ISite } from "../Sites/api";
import { useEffect } from "react";
import { NetWrapper } from "../../utils/netwrapper";
import { endpoints } from "../../services/api";
import { useAuth } from "../../context/AuthProvider";
import { MenuDropdown } from "../MenuDropdown";
import { useNavigate } from "react-router-dom";
import ChangeSiteDropdown from "../Dropdown/ChangeSiteDropdown";

export interface IHeader {
  heading: string;
  user?: Partial<IUserDetails> & Partial<ISideBarProfile>;
  sites?: ISite[];
  showSites?: boolean;
}

export const Header = ({ heading, user, sites, showSites }: IHeader) => {
  const { setUserDetails, logout } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    NetWrapper(endpoints.auth.userinfo).then((resp) => {
      const userDetails = resp.data;
      setUserDetails(userDetails);
    });
  }, []);

  return (
    <div className="w-full">
      <div className="flex w-full items-center justify-between">
        <div className="flex flex-col items-start">
          <h3 className="min-w-48 text-lg font-bold md:text-2xl">
            {heading}
            <span className="ml-1 self-end text-right text-xs text-gray-500">
              (BETA)
            </span>
          </h3>
        </div>
        <div className="flex items-center justify-center gap-2.5 md:gap-10">
          <div className="flex items-center justify-center gap-3">
            {showSites && (
              <div className="hidden min-w-[316px] md:block">
                {sites && <ChangeSiteDropdown sites={sites} />}
              </div>
            )}

            {/* <div className="rounded-full border-2 p-2">
              <Icon name={ICON_NAME.notification} className="h-5 w-5" />
            </div> */}
          </div>
          {user && (
            <MenuDropdown
              onSelect={(item: string[]) => {
                if (item[0] === "Organization") {
                  navigate(`/settings/organization`);
                } else if (item[0] === "Logout") {
                  logout();
                }
              }}
              base={<ProfileAvatar user={user} />}
              startFrom="right"
              items={["Organization", "Logout"]}
            />
          )}
        </div>
      </div>
      <div className="min-w-[216px] pr-2.5 pt-2.5 md:hidden ">
        {sites && <ChangeSiteDropdown sites={sites} />}
      </div>
    </div>
  );
};
