import { ChangeEvent, InputHTMLAttributes, useState } from "react";
import clsx from "clsx";
import { ICON_NAME, Icon } from "../Icon";
import { useField } from "formik";

export interface IUploadFileInput
  extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  name: string;
  icon?: ICON_NAME;
  inputsize?: "xs" | "sm" | "base"; // inputsize for consistent sizing
  fieldclass?: string;
  onFileSelect?: (e: ChangeEvent<HTMLInputElement>) => void; // Callback for file selection
  handleFileClear?: () => void; // Callback for file selection
}

const InputSizeClasses = {
  xs: "py-1 text-xs/6 rounded",
  sm: "py-1 text-sm/6 rounded",
  base: "px-4 py-2 text-base/6 rounded-lg ",
};

const IconSizeClasses = {
  xs: "h-5 w-5",
  sm: "h-5 w-5",
  base: "h-6 w-6",
};

const LabelClasses = {
  xs: "text-xs/6",
  sm: "text-sm/6",
  base: "text-base/6",
};

export function UploadFileInput(props: IUploadFileInput) {
  const {
    label,
    name,
    fieldclass = "",
    inputsize = "base",
    icon,
    onChange,
    handleFileClear,
    onFileSelect,
    ...rest
  } = props;
  const [field, meta, helpers] = useField(name);

  const [fileName, setFileName] = useState<string | null>(null);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files ? e.target.files[0] : null;
    setFileName(file ? file.name : null);
    helpers.setValue(file);
    // if (onFileSelect) {
    //   onFileSelect(e);
    // }
  };

  const clearFile = () => {
    setFileName(null);

    helpers.setValue(""); // Clear file input value in Formik
    if (handleFileClear) {
      handleFileClear();
    }
  };

  const downloadFile = () => {
    const fileData = field.value;
    const blob = new Blob([fileData], { type: "text/plain" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.download = "metadata.xml";
    link.href = url;
    link.click();
  };

  return (
    <div className="flex flex-row">
      <div
        className={clsx(
          "group relative z-0 flex-1 transition-all focus-within:z-10",
          props.disabled
            ? "border-gray-200 bg-gray-50"
            : "border-neutral-300 bg-transparent"
        )}
      >
        <input
          id={name}
          name={name}
          type="file"
          className="hidden" // Hide the actual file input
          // {...field}
          onChange={handleFileChange}
          {...rest}
        />
        <label
          htmlFor={name}
          className={clsx(
            "peer block w-full cursor-pointer border border-neutral-300 bg-transparent pl-4 text-neutral-950 transition focus:border-theme-gray focus:outline-none focus:ring-theme-gray",
            InputSizeClasses[inputsize],
            props.disabled
              ? "cursor-default border-gray-200 bg-gray-50"
              : "border-neutral-300 bg-transparent",
            fieldclass
          )}
        >
          <div className="flex items-center justify-between">
            <span>{field?.value?.name || label || "Upload File"}</span>
            {!Boolean(field.value) && icon && (
              <Icon
                className={`text-theme-gray ${IconSizeClasses[inputsize]}`}
                name={icon}
              />
            )}
          </div>
        </label>
        {Boolean(field.value) && (
          <div className="absolute inset-y-0 right-4 flex items-center gap-1">
            {!props.disabled && (
              <button
                type="button"
                disabled={props.disabled}
                onClick={clearFile}
              >
                <Icon
                  className={`text-theme-gray ${IconSizeClasses[inputsize]}`}
                  name={ICON_NAME.xCircle}
                  aria-hidden="true"
                />
              </button>
            )}
          </div>
        )}
        {/* {meta.error && meta.touched && (
        <Icon
          name={ICON_NAME.info}
          className="pointer-events-none h-5 w-5 text-red-500"
          aria-hidden="true"
        />
      )} */}
      </div>
      {Boolean(field.value) && (
        <button className="ml-3" type="button" onClick={downloadFile}>
          <Icon
            className={`mr-2 text-theme-gray ${IconSizeClasses[inputsize]}`}
            name={ICON_NAME.download}
            aria-hidden="true"
          />
        </button>
      )}
    </div>
  );
}

export default UploadFileInput;
