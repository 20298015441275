import { useNavigate } from "react-router-dom";
import { IActivityLogItem, logColumns } from "../ActivityLogTable";
import { Button } from "../Button";
import { INDICATOR_TYPE } from "../Indicator";
import { Loader, LOADER_SIZE } from "../Loader";
import { Placeholder } from "../Placeholder";
import { TableHeader } from "../TableHeader";
import { Table } from "../Table";
import { useState } from "react";
import DeviceDetailsModal from "../DeviceDetailsModal.tsx/DeviceDetailsModal";

export interface IActivity {
  time: string;
  type: INDICATOR_TYPE;
  message: string;
  filePath: string;
}

export interface IActivityLog {
  data: IActivityLogItem[];
  loading?: boolean;
  onClickPrevious: React.MouseEventHandler<HTMLButtonElement>;
  onClickNext: React.MouseEventHandler<HTMLButtonElement>;
  isNextDisabled: boolean;
  isPrevDisabled: boolean;
}

export const ActivityLog = ({
  data,
  onClickNext,
  onClickPrevious,
  loading,
  isNextDisabled,
  isPrevDisabled,
}: IActivityLog) => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [currentRow, setCurrentRow] = useState<IActivityLogItem | undefined>(
    undefined
  );

  return (
    <div className="relative flex min-h-56 flex-col justify-between gap-6 rounded-lg bg-white p-6">
      {/* <TableHeader heading="Activity Log" columnData={[]} /> */}
      {loading ? (
        <div className="absolute left-0 top-0 flex h-[100%]   w-[100%] items-center justify-center rounded-lg bg-neutral-200  bg-opacity-25 ">
          <Loader className="text-theme-purple" size={LOADER_SIZE.xl} />
        </div>
      ) : (
        <div className="flex w-full flex-col gap-14">
          <Table
            headerComponent={() => (
              <TableHeader
                heading="Activity Log"
                columnData={[]}
                showSearchField={false}
              />
            )}
            columns={logColumns}
            data={data}
            emptyTablePlaceholder={
              <Placeholder
                label="No activity logs found"
                description="Activity logs will be displayed here"
              />
            }
            cellStyle="normal-case"
            onClick={(e: any, row: any, cell: any) => {
              setShowModal(true);
              setCurrentRow(row.original);
            }}
          />
        </div>
      )}
      <div className="flex justify-between border-t border-theme-gray-100 pt-4">
        <Button kind="plain" onClick={() => navigate("/activity")}>
          View All
        </Button>
        <div className="flex gap-4">
          <Button
            disabled={isPrevDisabled}
            kind="plain"
            onClick={onClickPrevious}
            className="disabled:cursor-not-allowed"
          >
            Prev
          </Button>
          <Button
            className="disabled:cursor-not-allowed"
            disabled={isNextDisabled}
            kind="plain"
            onClick={onClickNext}
          >
            Next
          </Button>
        </div>
      </div>
      <DeviceDetailsModal
        isOpen={showModal}
        setIsOpen={setShowModal}
        dialogClassName="!w-full"
        data={currentRow}
        title="Activity Log Details"
      />
    </div>
  );
};
