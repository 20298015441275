import clsx from "clsx";
import { Icon, ICON_NAME } from "../Icon";
import { SideNav } from "../SideNav";
import logo from "../assets/main_logo.png";
import miniLogo from "../assets/logo.png";
import { Header } from "../Header";
import { Outlet, useNavigate } from "react-router-dom";
import { Suspense, useEffect, useState } from "react";
import { Loader, LOADER_SIZE } from "../Loader";
import { useAuth } from "../../context/AuthProvider";
import { useFetchSites } from "../Sites/api";
import { useSite } from "../../context/SiteProvider";

export const DashboardLayout = ({ children }: { children?: JSX.Element }) => {
  const { data, loading } = useFetchSites();
  const { setSelectedSite } = useSite();
  const { user, logout } = useAuth();
  const [isExpanded, setIsExpanded] = useState(true);
  const navigate = useNavigate();

  const navListItems = [
    {
      label: "Dashboard",
      url: "/dashboard",
      menuIcon: {
        name: ICON_NAME.squaresFour,
      },
    },
    {
      label: "Devices",
      url: "/devices",
      menuIcon: {
        name: ICON_NAME.desktop,
      },
    },
    {
      label: "Sites",
      url: "/sites",
      menuIcon: {
        name: ICON_NAME.window,
      },
    },
    {
      label: "Security Policies",
      url: "/security-policies",
      menuIcon: {
        name: ICON_NAME.documentDuplicate,
      },
    },
    {
      label: "Software",
      url: "/software",
      menuIcon: {
        name: ICON_NAME.window,
      },
    },
    {
      label: "Logs",
      url: "/logs",
      pseudo: true,
      menuIcon: {
        name: ICON_NAME.notebook,
      },
      submenuContainerItems: [
        {
          label: "Activity Log",
          url: "/activity",
          menuIcon: {
            name: ICON_NAME.clock,
          },
        },
        {
          label: "Audit Log",
          url: "/audit",
          menuIcon: {
            name: ICON_NAME.audit,
          },
        },
      ],
    },

    {
      label: "Settings",
      url: "/user",
      pseudo: true,
      submenuContainerItems: [
        // { label: "Entity", url: "/settings/entities" },
        {
          label: "Organization",
          url: "/settings/organization",
          menuIcon: {
            name: ICON_NAME.user,
          },
        },
        {
          label: "Single Sign On",
          url: "/settings/sso",
          menuIcon: {
            name: ICON_NAME.key,
          },
        },
        {
          label: "SIEM",
          url: "/settings/siem",
          menuIcon: {
            name: ICON_NAME.shield,
          },
        },
        {
          label: "Logout",
          url: "/settings/logout",
          menuIcon: {
            name: ICON_NAME.signOut,
          },
          onClick: () => {
            logout();
          },
        },
        // { label: "Users", url: "/settings/users", pseudo: true },
        // { label: "Organization Settings", url: "/settings/organization" },
      ],
      menuIcon: {
        name: ICON_NAME.user,
      },
    },
  ];
  const toggleNav = () => {
    setIsExpanded(!isExpanded);
  };
  //   const { auth } = useAuth();

  //   if (!auth) {
  //     return <Navigate to="/" />;
  //   }
  useEffect(() => {
    if (data) {
      // setSelectedSite(data[0]);

      if (Array.isArray(data.data) && data.data.length > 0) {
        let selectedSite = data.data.find(
          (site) => site.uuid === "88b8dd95-cbf6-4ac1-8ee8-84b1fc4b32c5"
        );

        if (!selectedSite) {
          selectedSite = data.data[0];
        }

        if (selectedSite) {
          setSelectedSite(selectedSite);
        }
      }
    }
  }, [data]);

  useEffect(() => {
    const handleResize = () => {
      setIsExpanded(window.innerWidth >= 450);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // if (loading) {
  //   console.log("Screen for loading ");
  //   return <Loader size={LOADER_SIZE.xl} />;
  // }
  return (
    <div className="absolute inset-0 flex flex-row bg-gray-100">
      <div
        className={`relative h-screen overflow-x-scroll border-r border-theme-gray-150 bg-white transition-all duration-300 ${
          isExpanded ? "w-72" : "w-0 md:w-20"
        }`}
      >
        <div>
          <div className="my-auto flex  items-center justify-center gap-4 py-4 text-2xl font-bold uppercase text-white">
            <img
              onClick={() => {
                navigate("/dashboard");
              }}
              src={isExpanded ? logo : miniLogo}
              alt="logo"
              className={clsx(
                "hover:cursor-pointer",
                isExpanded ? "h-14 " : "h-7"
              )}
            />
          </div>
          <div
            className={clsx(
              "fixed top-8 z-10 mt-0.5 flex h-10 w-10 cursor-pointer items-center justify-center rounded-full bg-theme-purple transition-all duration-300 md:top-5",
              isExpanded ? "left-[268px]" : "-left-4 md:left-14"
            )}
            onClick={toggleNav}
          >
            <Icon
              name={isExpanded ? ICON_NAME.arrowLeft : ICON_NAME.arrowRight}
              className="text-2xl text-white"
            />
          </div>
          {/* Arrow Icon */}
        </div>
        <SideNav
          sites={data?.data}
          navListItems={navListItems}
          isExpanded={isExpanded}
          loading={loading}
        />
      </div>
      <div className="flex h-screen flex-1 flex-col overflow-scroll">
        <div className="h-full w-full">
          <div className="flex w-full flex-row bg-white py-4 pr-2 shadow md:py-6 md:pr-6">
            {/* <div className="py-2 pl-6">
              <Breadcrumbs pages={crumbs} />
            </div> */}
            <div className="xs:flex-1 flex w-full justify-start px-4">
              <div className="ml-4 flex w-full items-center space-x-4 self-end md:ml-6">
                <Header
                  showSites={!isExpanded}
                  heading="ArmorxAI Director"
                  user={user ?? undefined}
                  sites={data?.data}
                />
              </div>
            </div>
          </div>
          <main className={clsx("flex-1 pb-2")}>
            <Suspense
              fallback={
                <Loader className="text-theme-purple" size={LOADER_SIZE.xl} />
              }
            >
              <Outlet />
            </Suspense>
          </main>
        </div>
      </div>
    </div>
  );
};
