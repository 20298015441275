import { useNavigate } from "react-router-dom";
import { useAuth } from "../context/AuthProvider";
import { useSite } from "../context/SiteProvider";
import { useSWR } from "../hooks/useSWR";
import { endpoints } from "../services/api";
import { ActivityLogTable } from "../stories/ActivityLogTable";
import { activityLogs } from "../stories/Devices/mockData";
import { useEffect, useState } from "react";

export default function ActivityScreen() {
  const { selectedSite } = useSite();

  const queryParams = new URLSearchParams(window.location.search);
  const initialPage = parseInt(queryParams.get("page")?.toString() ?? "1");
  const [currentPage, setCurrentPage] = useState(initialPage);
  const size = 10;

  const navigate = useNavigate();

  const updateQueryParams = (pageNumber: number) => {
    navigate(`?page=${pageNumber}`);
  };
  const { data, loading } = useSWR<{
    data: typeof activityLogs;
    hasNext: boolean;
  }>(
    selectedSite?.uuid
      ? endpoints.activityLogs.get(selectedSite?.uuid, currentPage, size)
      : null
  );

  useEffect(() => {
    setCurrentPage(initialPage);
  }, [initialPage]);

  return (
    <ActivityLogTable
      data={data?.data || undefined}
      loading={loading}
      currentPage={currentPage}
      updateQueryParams={updateQueryParams}
      totalPages={data?.hasNext ? currentPage + 1 : currentPage}
      setCurrentPage={setCurrentPage}
      size={size}
      hasNext={data?.hasNext}
    />
  );
}
