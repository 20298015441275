import { createColumnHelper, Updater } from "@tanstack/react-table";
import { Table } from "../Table";
import { Indicator, INDICATOR_TYPE } from "../Indicator";
import { TableHeader } from "../TableHeader";
import { activityLogs } from "../Devices/mockData";
import moment from "moment";
import { Placeholder } from "../Placeholder";
import { Loader, LOADER_SIZE } from "../Loader";
import { PaginationButton } from "../Pagination";
import { useState } from "react";
import DeviceDetailsModal from "../DeviceDetailsModal.tsx/DeviceDetailsModal";

export interface IActivityLogItem {
  id: number;
  siteId: string;
  deviceId: string;
  tenantId: string;
  timestamp: string;
  eventInfo: {
    eventType: string;
    eventLevel: string;
    eventMessage: string;
  };
  threatDetails?: {
    threatName: string;
    threatLevel: string;
    hash: string;
  };
  hostSystem: {
    hostname: string;
    ipAddress: string;
    operatingSystem: string;
    userInfo: string;
  };
}

export interface IActivityLogTable {
  data: typeof activityLogs;
  loading: boolean;
  currentPage: number;
  totalPages: number;
  setCurrentPage: (page: number) => void;
  hasNext: boolean;
  size: number;
  updateQueryParams: (pageNo: number) => void;
}

const columnHelper = createColumnHelper<IActivityLogItem>();

export const logColumns = [
  columnHelper.accessor("eventInfo.eventLevel", {
    header: "",
    cell: (info) => (
      <Indicator
        type={
          info?.getValue()?.includes("critical")
            ? INDICATOR_TYPE.critical
            : info?.getValue()?.toLowerCase()
        }
      />
    ),
  }),
  columnHelper.accessor("timestamp", {
    header: "Date and time",
    cell: (info) => moment(info.getValue()).format("MM/DD H:mm:ss:SS"),
  }),
  columnHelper.accessor("hostSystem", {
    header: "User",
    cell: (info) => {
      const user = info.getValue();
      return <p className="text-sm font-medium">{user.userInfo}</p>;
    },
  }),
  // columnHelper.accessor("threat", {
  //   header: "Threat",
  //   cell: (info) => {
  //     const threat = info.getValue();
  //     return <p className="text-sm font-medium">{threat.name}</p>;
  //   },
  // }),
  columnHelper.accessor("deviceId", {
    header: "Device ID",
    cell: (info) => {
      return info.getValue();
    },
  }),

  columnHelper.accessor("hostSystem.operatingSystem", {
    header: "Operating Systems",
    cell: (info) => {
      return info.getValue();
    },
  }),
  columnHelper.accessor("hostSystem.ipAddress", {
    header: "Source IP",
    cell: (info) => {
      return info.getValue();
    },
  }),
  columnHelper.accessor("eventInfo.eventType", {
    header: "Event Type",
    cell: (info) => {
      return info.getValue();
    },
  }),
  columnHelper.accessor("eventInfo.eventMessage", {
    header: "Message",
    cell: (info) => {
      return info.getValue();
    },
    sortingFn: "alphanumeric",
  }),
];

export const ActivityLogTable = ({
  data,
  loading,
  currentPage,
  hasNext,
  setCurrentPage,
  totalPages,
  size,
  updateQueryParams,
}: IActivityLogTable) => {
  const [showModal, setShowModal] = useState(false);
  const [currentRow, setCurrentRow] = useState<IActivityLogItem | undefined>(
    undefined
  );
  const handlePageChange = (updater: Updater<number>) => {
    const nextPage =
      typeof updater === "function" ? updater(currentPage) : updater;
    setCurrentPage(nextPage);
    updateQueryParams(nextPage);
  };

  const handleNextPage = () => {
    const nextPage = currentPage + 1;
    setCurrentPage(nextPage);
    updateQueryParams(nextPage);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      const prevPage = currentPage - 1;
      setCurrentPage(prevPage);
      updateQueryParams(prevPage);
    }
  };
  return (
    <div className="m-4 flex h-auto min-h-[calc(100vh-230px)] flex-col gap-4 rounded-lg border-0 bg-white p-4 md:m-6 md:p-6">
      {loading ? (
        <div className="flex h-full flex-1 flex-col items-center justify-center gap-6">
          <Loader
            size={LOADER_SIZE.lg}
            className="h-10 w-10 self-center text-theme-purple"
          />
        </div>
      ) : (
        <Table
          headerComponent={() => (
            <TableHeader
              heading="Activity Log"
              columnData={[]}
              showSearchField={false}
            />
          )}
          columns={logColumns}
          data={data}
          emptyTablePlaceholder={
            <Placeholder
              label="No activity log entries found"
              description="Activity log entries will be displayed here"
            />
          }
          cellStyle="normal-case"
          onClick={(e: any, row: any, cell: any) => {
            setShowModal(true);
            setCurrentRow(row.original);
          }}
        />
      )}

      <PaginationButton
        total={totalPages}
        current={currentPage}
        prevLabel="Previous"
        nextLabel="Next"
        perPage={size}
        paginationHandler={handlePageChange}
        handleNext={handleNextPage}
        handlePrev={handlePrevPage}
        isNextDisabled={!hasNext}
        isPrevDisabled={currentPage === 1}
      />

      <DeviceDetailsModal
        isOpen={showModal}
        setIsOpen={setShowModal}
        dialogClassName="!w-full"
        data={currentRow}
        title="Activity Log Details"
      />
    </div>
  );
};
