import { NavLink } from "react-router-dom";
import clsx from "clsx";
import { Icon, IconProps, ICON_NAME } from "../Icon";

export interface SidebarMenuButtonProps {
  parentMenu?: SidebarMenuButtonProps;
  loading?: boolean;
  url: string;
  pseudo?: boolean;
  external?: boolean;
  disabled?: boolean;
  onClick?(menuProps: SidebarMenuButtonProps): any;
  className?: string;
  submenuContainerItems?: SidebarMenuButtonProps[];
  disclosure?: boolean;
  selected?: boolean;
  selectedSubMenu?: boolean;
  open?: boolean;
  label: string;
  isExpanded?: boolean;
  menuIcon?: IconProps;
}

export function SidebarMenuButton(props: SidebarMenuButtonProps): JSX.Element {
  const {
    loading,
    url,
    pseudo,
    external,
    disabled,
    onClick,
    parentMenu,
    className,
    selectedSubMenu,
    submenuContainerItems,
    disclosure,
    selected,
    open,
    label,
    isExpanded = true,
    menuIcon,
    ...rest
  } = props;
  const isAnchor = !pseudo && url && url.length > 0;
  const isInternalLink =
    isAnchor && url && url.startsWith("/") && !url.startsWith("//");
  const ButtonTag = isAnchor ? (isInternalLink ? NavLink : "a") : "button";
  const anchorProps = isAnchor
    ? {
        [isInternalLink ? "to" : "href"]: url,
        className: ({ isActive }: { isActive: boolean }) =>
          isActive ? "active_link" : "",
        target: external ? "_blank" : "_self",
      }
    : ({} as any);

  const menuButtonClassNames = [
    "relative group flex flex-col items-stretch rounded text-xs font-medium disabled:text-gray-500 z-10",
    className,
    !isExpanded ? "w-11" : "w-full",
  ];
  const active = "text-white font-semibold bg-theme-purple ";
  const inactive = "text-black hover:bg-[#f2eef7] bg-white";

  menuButtonClassNames.push(
    selected || selectedSubMenu
      ? active
      : `${inactive} focus:text-white active:bg-theme-purple active:text-white focus:bg-theme-purple `
  );

  const menuClassName = clsx(menuButtonClassNames);
  // let selectedLine = selectedSubMenu;

  return (
    <>
      <ButtonTag
        {...anchorProps}
        onClick={() => {
          if (onClick) onClick(props);
        }}
        className={menuClassName}
        disabled={disabled}
        {...rest}
      >
        <div className="flex w-full min-w-32 flex-1 items-center justify-between space-x-3 p-3">
          <div
            className={clsx(
              "flex items-center justify-start space-x-3 group-hover:space-x-2.5"
            )}
          >
            {menuIcon && (
              <div>
                <Icon {...menuIcon} className="h-5 w-5" />
              </div>
            )}
            {label && label.length > 0 && (
              <p
                className={clsx(
                  "shrink-0 text-base transition-opacity duration-300 ease-in-out",
                  isExpanded ? "opacity-100" : "opacity-0"
                )}
              >
                {label}
              </p>
            )}
          </div>
          <Icon
            className={clsx(
              "h-4 w-4",
              disclosure ? "" : "opacity-0",
              open ? "rotate-180 transform" : ""
            )}
            name={ICON_NAME.chevronDown}
          />
        </div>
      </ButtonTag>
      {disclosure !== false && (
        <div
          className={clsx(
            "relative flex flex-col items-stretch justify-start space-y-2.5 transition-all duration-300 ease-in-out",
            open ? "" : "hidden",
            isExpanded ? "pl-4" : "pl-0"
          )}
        >
          {submenuContainerItems &&
            submenuContainerItems.length > 0 &&
            submenuContainerItems.map((subMenuProps, key) => {
              return (
                <div key={key}>
                  {subMenuProps.selected}
                  <SidebarMenuButton
                    parentMenu={props}
                    {...subMenuProps}
                    key={key}
                    isExpanded={isExpanded}
                    onClick={() => {
                      if (subMenuProps.onClick) {
                        subMenuProps.onClick(subMenuProps);
                        return;
                      }

                      if (onClick) onClick(subMenuProps);
                    }}
                  />
                </div>
              );
            })}
        </div>
      )}
    </>
  );
}

SidebarMenuButton.defaultProps = {
  disabled: false,
  onClick: () => {},
  submenuContainerItems: [],
  disclosure: false,
  selected: false,
  selectedSubMenu: false,
  open: false,
  label: "Menu Name",
  pseudo: false,
};
