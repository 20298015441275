import { useEffect, useState } from "react";
import { Device } from "../stories/Devices";
import { SoftwareListing } from "../stories/Software";

export default function SoftwareScreen() {
  const [software, setSoftwares] = useState<Device[]>([]);
  // const { selectedSite } = useSite();

  // console.log(selectedSite);

  useEffect(() => {
    if (false) {
      // let devicesEndpoint = endpoints.device.get(selectedSite?.uuid);
      // NetWrapper(devicesEndpoint).then((response) => {
      //   if (response.data && response.data.data) {
      //     let data = response.data.data;
      //     if (Array.isArray(data) && data.length === 0) {
      //       console.log(">>>", []);
      //       setSoftwares(mockDevices);
      //     } else if (Array.isArray(data) && data.length > 0) {
      //       console.log("else");

      //       setSoftwares(data);
      //     }
      //   }
      // });
    } else {
      setSoftwares([]);
    }
  }, []);
  return <SoftwareListing heading="Software Versions" />;
}
