import { BASE_URL } from "./const";
import { Auth, refetchToken } from "../hooks/auth";
import { getErrorMessage } from "./helper";
import { Net } from "./net";
import { jwtDecode } from "jwt-decode";
import { useAuth } from "../context/AuthProvider";
import { notifyError } from "./notify";
export interface IRequest extends RequestInit {
  cacheTime?: number;
  defaultFetch?: boolean;
  enableCache?: boolean;
  retries?: number;
  requestInterceptor?: (options: RequestInit) => RequestInit;
}

export const NetWrapper = async <T>(
  url: string,
  options: IRequest = {
    method: "GET",
    cacheTime: 3600000,
    enableCache: true,
    retries: 3,
  },
  injectAuth = true,
  base = process.env.REACT_APP_BASE_URL ?? BASE_URL
  //   base = import.meta.env.VITE_API_URL,
): Promise<{
  data: any;
  error: {
    data: any;
    message: string;
    status: number;
  } | null;
  status: number;
}> => {
  let { auth, login, logout } = Auth();

  if (injectAuth && auth.refreshToken && auth.authType === "sso") {
    const currentTime = Math.floor(Date.now() / 1000);
    const { exp } = jwtDecode(auth.token);
    if (exp) {
      if (exp < currentTime ) {
        try {
          const data = await refetchToken(auth.refreshToken);

          login(
            {
              token: data.token,
              refreshToken: auth.refreshToken,
              authType: "sso",
            },
            "",
            false
          );
          auth = Auth().auth;          
        } catch (error: any) {
          notifyError(error.message || "Something went wrong");
          logout();
        }
      }
    }
  }
  options.headers = options.headers || {
    "Content-Type": "application/json",
    ...(injectAuth && {
      Authorization:
        auth.authType === "sso"
          ? "Bearer ".concat(auth.token)
          : "Basic ".concat(auth.token),
    }),
  };
  if (options.method !== "GET") {
    options.body = options.body || "";
  }
  const loadTrueEvent = new CustomEvent("loaderEvent", {
    detail: {
      status: true,
    },
  });
  window.dispatchEvent(loadTrueEvent);

  const { data, error, status } = await Net<T>(url, options, base);
  if (error) {
    const errorMessage = getErrorMessage(error.data);
    const event = new CustomEvent("toasterEvents", {
      detail: {
        type: "error",
        message: errorMessage,
        duration: 50000,
        dismissible: true,
      },
    });
    window.dispatchEvent(event);
  } else if (data && (data as any).response && (data as any).response.message) {
    const event = new CustomEvent("toasterEvents", {
      detail: {
        type: "success",
        message: (data as any).response.message || "Success",
      },
    });
    window.dispatchEvent(event);
  }
  const loadFalseEvent = new CustomEvent("loaderEvent", {
    detail: {
      status: false,
    },
  });
  window.dispatchEvent(loadFalseEvent);
  return {
    data,
    error,
    status,
  };
};
