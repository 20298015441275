export enum ModalType {
  CHANGE_SITE = "CHANGE_SITE",
  DELETE_SITE = "DELETE_SITE",
  MARK_CLEAN = "MARK_CLEAN",
  SHUT_DOWN_DEVICE = "SHUT_DOWN_DEVICE",
  DEREGISTER_DEVICE = "DEREGISTER_DEVICE",
  ADD_POLICY = "ADD_POLICY",
  ADD_DIRECTORY = "ADD_DIRECTORY",
  ADD_WHITELIST = "ADD_WHITELIST",
  EDIT_DIRECTORY = "EDIT_DIRECTORY",
  EDIT_WHITELIST = "EDIT_WHITELIST",
  DELETE_POLICY = "DELETE_POLICY",
  DELETE_DIRECTORY = "DELETE_DIRECTORY",
  DELETE_WHITELIST = "DELETE_WHITELIST",
  APPLY_GLOBAL_POLICY = "APPLY_GLOBAL_POLICY",
  SET_AS_DEFAULT_POLICY = "SET_AS_DEFAULT_POLICY",
}
