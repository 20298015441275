import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import AppRoutes from "./Routes";
import { BrowserRouter as Router } from "react-router-dom";
import { NetWrapper } from "./utils/netwrapper";
import { IResponse } from "./utils/net";

const App = () => {
  // async function verifyHealth(): Promise<void> {
  //   const { data, error } =
  //     await NetWrapper<IResponse<any>>("/api/healthcheck");

  //   console.log(data, error);
  // }
  // verifyHealth();
  return (
    <Router>
      <AppRoutes />
      <ToastContainer />
    </Router>
  );
};

export default App;
