import { SubSection } from "../../utils/interface";

interface IAddSSO {
  title: string;
  sections: SubSection[];
}

export const sections: IAddSSO[] = [
  {
    title: "",
    sections: [
      {
        title: "IP Address & Port",
        className: "md:grid grid-cols-2",
        children: [
          {
            label: "",
            fields: [
              {
                name: "ip",
                type: "text",
                placeholder: "IP",
              },
            ],
          },
          {
            label: "",
            fields: [
              {
                name: "port",
                type: "text",
                placeholder: "Port",
              },
            ],
          },
        ],
      },
      {
        title: "Token",
        className: "",
        children: [
          {
            label: "",
            fields: [
              {
                name: "tokenOrKey",
                type: "text",
                placeholder: "Token or Key",
              },
            ],
          },
        ],
      },
      {
        title: "Protocol",
        className: "",
        children: [
          {
            label: "",
            fields: [
              {
                name: "protocol",
                type: "select",
                placeholder: "Select Security Policy",
                options: [
                  {
                    label: "HEC (Splunk)",
                    value: "HEC",
                  },
                  { label: "TCP", value: "TCP" },
                  { label: "UDP", value: "UDP" },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
];
