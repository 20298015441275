import { Navigate, RouteObject, useRoutes } from "react-router-dom";
import { useDocumentTitle } from "./hooks";
import { flatMap } from "lodash";
import { Suspense } from "react";
import { AuthLayout, ProtectedLayout } from "./layouts";
import { DashboardLayout } from "./stories/Dashboard";
import { HomeLayout } from "./layouts/LoginLayout";
import {
  LoginPage,
  RegisterRootAccountPage,
  ResetPassword,
} from "./stories/AuthScreens";
import Placeholder from "../src/stories/assets/computer.png";
import DevicesScreen from "./pages/Device.page";
import AddSitesScreen from "./stories/Sites/AddSites";
import SitesScreen from "./pages/Site.page";
import { SiteProvider } from "./context/SiteProvider";
import DevicesDetailsScreen from "./pages/DeviceDetails.page";
import SoftwareScreen from "./pages/Software.page";
import DashboardScreen from "./pages/Dashboard.page";
import TenantsInfoScreen from "./pages/Tenants.page";
import SSOScreen from "./pages/SSO.page";
import ActivityScreen from "./pages/Activity.page";
import TermsAndConditionsScreen from "./pages/Terms.page";
import SIEMSettingsScreen from "./pages/SIEM.page";
import AuditScreen from "./pages/Audits.page";
import SecurityPoliciesScreen from "./pages/SecurityPolicy.page";
import PolicyDetailsScreen from "./pages/PolicyDetails.page";
import { ErrorScreen } from "./pages";

const Page = ({
  title,
  children,
}: {
  title: string;
  children: JSX.Element;
}) => {
  useDocumentTitle(`${title} - ArmorxAI`);
  return children;
};

const clientAppRoutes: RouteObject[] = [
  {
    element: <AuthLayout />,
    children: [
      {
        element: <HomeLayout />,
        children: [
          {
            path: "login",
            element: (
              <Page title="Login">
                <LoginPage
                  titleText="Comprehensive Ransomware Protection Platform"
                  placeholderURL={Placeholder}
                  helperTextArray={[
                    "Protection before an attack",
                    "Protection during an attack",
                    "Protection after an attack",
                  ]}
                />
              </Page>
            ),
          },
          {
            path: "login/reset",
            element: (
              <Page title="Reset Password">
                <ResetPassword />
              </Page>
            ),
          },
          {
            path: "single-sign-on",
            element: (
              <Page title="Create Root Account">
                <RegisterRootAccountPage />
              </Page>
            ),
          },
          {
            path: "/",
            element: <Navigate to="/login" />,
          },
          {
            path: "/auth/callback",
          },
        ],
      },
      {
        element: <ProtectedLayout />,
        children: [
          {
            element: (
              <SiteProvider>
                <DashboardLayout />
              </SiteProvider>
            ),
            children: [
              {
                path: "dashboard",
                element: (
                  <Page title="Dashboard">
                    <>
                      <DashboardScreen />
                      {/* <PlaceholderDashboard /> */}
                    </>
                  </Page>
                ),
              },
              {
                path: "devices",
                element: (
                  <Page title="Devices">
                    <DevicesScreen />
                  </Page>
                ),
              },
              {
                path: "devices/:deviceUuid",
                element: (
                  <Page title="Devices">
                    <DevicesDetailsScreen />
                  </Page>
                ),
              },
              {
                path: "sites",
                element: (
                  <Page title="Sites">
                    <SitesScreen />
                  </Page>
                ),
              },
              {
                path: "sites/add-site",
                element: (
                  <Page title="Add Site">
                    <AddSitesScreen />
                  </Page>
                ),
              },
              {
                path: "security-policies",
                element: (
                  <Page title="Security Policies">
                    <SecurityPoliciesScreen />
                  </Page>
                ),
              },
              {
                path: "security-policies/:policyId",
                element: (
                  <Page title="Security Policy - View Policy">
                    <PolicyDetailsScreen />
                  </Page>
                ),
              },
              {
                path: "software",
                element: (
                  <Page title="Software">
                    <SoftwareScreen />
                  </Page>
                ),
              },
              {
                path: "activity",
                element: (
                  <Page title="Activity">
                    <ActivityScreen />
                  </Page>
                ),
              },
              {
                path: "audit",
                element: (
                  <Page title="Audit Logs">
                    <AuditScreen />
                  </Page>
                ),
              },
              {
                path: "user",
                element: (
                  <Page title="Settings">
                    <div>Settings</div>
                  </Page>
                ),
              },
              {
                path: "settings",
                children: [
                  {
                    path: "organization",
                    element: (
                      <Page title="Organization Information">
                        <TenantsInfoScreen />
                      </Page>
                    ),
                  },
                  {
                    path: "sso",
                    element: (
                      <Page title="Single Sign On">
                        <SSOScreen />
                      </Page>
                    ),
                  },
                  {
                    path: "siem",
                    element: (
                      <Page title="SIEM">
                        <SIEMSettingsScreen />
                      </Page>
                    ),
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: "terms-conditions",
        element: (
          <Page title="Terms & Conditions">
            <TermsAndConditionsScreen />
          </Page>
        ),
      },
      {
        path: "/error",
        element: (
          <Page title="Error">
            <ErrorScreen />
          </Page>
        ),
      },
    ],
  },
];

const getPaths = (routes: RouteObject[], base = ""): { path: string }[] => {
  return flatMap(routes, (route) => {
    const fullPath = route.path ? `${base}/${route.path}` : base;
    if (route.children) {
      return getPaths(route.children, fullPath);
    }
    return route.path ? [{ path: fullPath }] : [];
  });
};

export const clientRouteList = getPaths(clientAppRoutes);

const AppRoutes = () => {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      {useRoutes(clientAppRoutes)}
    </Suspense>
  );
};

export default AppRoutes;
