import moment from "moment";
import { TooltipProps } from "recharts";

interface CustomTooltipProps extends TooltipProps<any, any> {
  yAxisUnit: string;
  colors: string[];
}

const dateFormat = (time: any) => {
  return moment(time).format("MM/DD h:mm A");
};

const CustomTooltip = ({
  active,
  payload,
  label,
  yAxisUnit,
  formatter,
  colors,
}: CustomTooltipProps) => {

  if (active && payload && payload.length) {
    return (
      <div
        className="custom-tooltip"
        style={{
          backgroundColor: "#fff",
          border: "1px solid #ccc",
          padding: "10px",
        }}
      >
        <p className="label text-theme-gray">{dateFormat(label)}</p>
        {payload.map((entry, index) => {
          return (
            entry.type !== "none" && (
              <div key={`item-${index}`} style={{ marginBottom: "5px" }}>
                <span style={{ color: "#000" }}>{entry.name}: </span>
                <span
                  style={{
                    color: entry.color,
                    fontWeight: "600",
                  }}
                >
                  {formatter
                    ? formatter(entry.value, entry.name, entry, index, payload)
                    : entry.value.toString().concat(yAxisUnit)}
                </span>
              </div>
            )
          );
        })}
      </div>
    );
  }

  return null;
};

export default CustomTooltip;
