import { each, get, isEmpty, isEqual, isFunction } from "lodash";
import { Badge, Dropdown, InputField, TextArea, Toggle } from "../stories";
import UploadFileInput from "../stories/InputField/Upload";
import { ICON_NAME } from "../stories/Icon";
import { FormField } from "./interface";
import { ToggleField } from "../stories/ToggleField";
import { QuestionToggle } from "../stories/QuestionToggle";

const isControlHidden = (value: any, props: any) => {
  let hidden = false;
  if (value?.hiddenWhen && !isEmpty(value?.hiddenWhen)) {
    hidden = true;
    each(value.hiddenWhen, (v: any, k: string) => {
      if (!hidden) return;
      const propV = get(props.values, k);
      if (isFunction(v)) {
        hidden = v(propV);
      } else {
        hidden = isEqual(propV, v);
      }
    });
  }
  return hidden;
};

const isControlDisabled = (value: any, props: any) => {
  let disabled = false;
  if (value?.disabledWhen && !isEmpty(value?.disabledWhen)) {
    disabled = true;
    each(value.disabledWhen, (v: any, k: string) => {
      if (!disabled) return;
      const propV = get(props.values, k);
      if (isFunction(v)) {
        disabled = v(propV);
      } else {
        disabled = isEqual(propV, v);
      }
    });
  }
  return disabled;
};

const renderField = (
  field: FormField,
  props: any,
  isControlDisabled?: (field: any, props: any) => boolean,
  disabled?: boolean
) => {
  switch (field.type) {
    case "disabled":
      return (
        <Badge className="px-3.5 py-1.5" children={props.values[field.name]} />
      );

    case "text":
    case "email":
      return (
        <InputField
          name={field.name}
          label={field.placeholder}
          type={field.type}
          inputsize={field.size ?? "sm"}
          fieldclass="py-1.5 rounded-lg"
          disabled={
            field.disabled ||
            disabled ||
            (isControlDisabled && isControlDisabled(field, props))
          }
        />
      );
    case "textarea":
      return (
        <TextArea
          name={field.name}
          inputsize={field.size ?? "sm"}
          label={field.placeholder}
          disabled={
            field.disabled ||
            disabled ||
            (isControlDisabled && isControlDisabled(field, props))
          }
        />
      );
    case "select":
      return (
        <Dropdown
          label={field.placeholder}
          options={field.options ?? []}
          size={field.size ?? "sm"}
          value={props.values[field.name]} // Set the value from Formik's state
          onChange={(val: any) => {
            props.setFieldValue(field.name, val.value);
          }}
          disabled={
            field.disabled ||
            disabled ||
            (isControlDisabled && isControlDisabled(field, props))
          }
        />
      );
    case "file":
      return (
        <UploadFileInput
          name={field.name}
          icon={ICON_NAME.upload}
          accept={field.accept}
          disabled={field.disabled}
        />
      );
    case "boolean":
      return (
        <InputField
          name={field.name}
          label={""}
          type={"checkbox"}
          checked={props.values[field.name]}
          inputsize="base"
          fieldclass=" rounded-lg"
          disabled={field.disabled}
          className="h-4 w-4 align-middle"
        />
      );
    case "toggle":
      return (
        <Toggle
          label={field.label ?? ""}
          name={field.name}
          defaultChecked={props.values[field.name]}
          placeholder={field.placeholder}
          disabled={
            field.disabled ||
            disabled ||
            (isControlDisabled && isControlDisabled(field, props))
          }
        />
      );
    case "toggleField":
      return (
        <ToggleField
          label={field.label ?? field.placeholder}
          name={field.name}
          modes={field.options ?? []}
          placeholder={field.placeholder}
          disabled={
            field.disabled ||
            disabled ||
            (isControlDisabled && isControlDisabled(field, props))
          }
        />
      );
    case "questionToggle":
      return (
        <QuestionToggle
          label={field.label ?? ""}
          description={field.placeholder}
          name={field.name}
          disabled={
            field.disabled ||
            disabled ||
            (isControlDisabled && isControlDisabled(field, props))
          }
        />
      );
    case "plain":
      return (
        <div className="flex items-center gap-2">
          <span className="text-sm">{field.label}</span>
          <span className="text-sm font-semibold">
            {props.values[field.name]}
          </span>
        </div>
      );
    default:
      return null;
  }
};
export { renderField, isControlDisabled, isControlHidden };
