import { createColumnHelper, Updater } from "@tanstack/react-table";
import { ICON_NAME, Icon } from "../Icon";
import { Table } from "../Table";
import { MenuDropdown } from "../MenuDropdown";
import { TableHeader } from "../TableHeader";
import { deleteSite, ISite } from "./api";
import { useNavigate } from "react-router-dom";
import { notifyError, notifySuccess } from "../../utils/notify";
import { useState } from "react";
import ActionModal from "../ActionModal/ActionModal";
import { ModalType } from "../ActionModal";
import { concat } from "lodash";
import { Loader, LOADER_SIZE } from "../Loader";
import { PaginationButton } from "../Pagination";
import { Placeholder } from "../Placeholder";

export const Sites = ({
  data,
  refetch,
  isLoading,
  currentPage,
  totalPages,
  size,
  setCurrentPage,
  hasNext,
}: {
  data: ISite[];
  refetch: any;
  isLoading: boolean;
  currentPage: number;
  totalPages: number;
  size: number;
  setCurrentPage: (page: number) => void;
  hasNext: boolean;
}) => {
  const [showModal, setShowModal] = useState(false);
  const [currentModalType, setCurrentModalType] = useState<ModalType | null>(
    null
  );
  const [currentSite, setCurrentSite] = useState<{
    id: string;
    name: string;
  } | null>(null);
  const navigate = useNavigate();

  const columnHelper = createColumnHelper<ISite>();
  const columns = [
    columnHelper.accessor("siteName", {
      header: () => "Site Name",
      id: "siteName",
      cell: (info) => <div className="font-medium">{info.getValue()}</div>,
    }),
    columnHelper.accessor("contact", {
      header: () => "Contact",
      id: "contact",
      cell: (info) => <div>{info.getValue()}</div>,
    }),
    columnHelper.accessor("zipCode", {
      header: () => "Zip Code",
      id: "zipCode",
    }),
    columnHelper.accessor("city", {
      header: "City",
      id: "city",
    }),
    columnHelper.accessor("street", {
      header: "Street",
      id: "street",
    }),
    columnHelper.accessor("state", {
      header: () => <span>State</span>,
      id: "state",
    }),
    columnHelper.accessor("country", {
      header: () => "Country",
      id: "country",
    }),
    columnHelper.accessor("secPolicy", {
      header: () => "Security Policy",
      id: "secPolicy",
    }),
    columnHelper.accessor("firstName", {
      header: () => "First Name",
      id: "firstName",
    }),
    columnHelper.accessor("lastName", {
      header: () => "Last Name",
      id: "lastName",
    }),
    columnHelper.accessor("description", {
      header: () => "Description",
      id: "description",
    }),
    columnHelper.accessor("created", {
      header: () => "Created",
      id: "created",
      cell: (info) => new Date(info.getValue() ?? "").toLocaleDateString(),
    }),
    columnHelper.accessor("updated", {
      header: () => "Updated",
      id: "updated",
      cell: (info) => new Date(info.getValue() ?? "").toLocaleDateString(),
    }),

    // columnHelper.accessor("email", {
    //   header: () => "Email",
    // }),
    columnHelper.accessor("action", {
      header: () => "Actions",
      id: "actions",
      cell: (info) => (
        <MenuDropdown
          onSelect={(item: string[]) => {
            const siteId = info.row.original.uuid as string;
            const siteName = info.row.original.siteName;
            if (item[0] === "Delete") {
              handleOpenModal(ModalType.DELETE_SITE, {
                id: siteId,
                name: siteName,
              });
            } else if (item[0] === "Edit") {
              navigate(`/sites/add-site?id=${siteId}&edit=true`);
            }
          }}
          base={
            <Icon
              className="h-8 w-8 self-center rounded-lg p-1 text-center hover:cursor-pointer hover:bg-theme-gray-250"
              name={ICON_NAME.menuDots}
            />
          }
          startFrom="right"
          items={["Edit", "Delete"]}
        />
      ),
    }),
  ];

  const [modalActionLoading, setModalActionLoading] = useState(false);

  const updateQueryParams = (pageNumber: number) => {
    navigate(`?page=${pageNumber}`);
  };

  const handlePageChange = (updater: Updater<number>) => {
    const nextPage =
      typeof updater === "function" ? updater(currentPage) : updater;
    setCurrentPage(nextPage);
    updateQueryParams(nextPage);
  };

  const handleNextPage = () => {
    // if (currentPage < totalPages) {
    const nextPage = currentPage + 1;
    setCurrentPage(nextPage);
    updateQueryParams(nextPage);
    // }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      const prevPage = currentPage - 1;
      setCurrentPage(prevPage);
      updateQueryParams(prevPage);
    }
  };

  const handleOpenModal = (
    modalType: ModalType,
    site: { id: string; name: string }
  ) => {
    setCurrentModalType(modalType);
    setCurrentSite(site);
    setShowModal(true);
  };

  const handleConfirm = async () => {
    setModalActionLoading(true);
    if (currentModalType === ModalType.DELETE_SITE && currentSite) {
      await handleDeleteSite(currentSite.id, currentSite.name);
    }
    setModalActionLoading(false);

    setShowModal(false);
    setCurrentSite(null);
  };

  const handleDeleteSite = async (siteId: string, siteName: string) => {
    const { error } = await deleteSite(siteId);
    if (error) {
      notifyError(error.toString());
    } else {
      notifySuccess(`Site ${siteName} was deleted successfully`);
      refetch(); // Re-fetch the site data after deletion
    }
  };

  const handleCancel = () => {
    setShowModal(false);
    setTimeout(() => {
      setCurrentSite(null);
    }, 300);
  };

  return (
    <div className="m-4 flex h-auto min-h-[calc(100vh-230px)] flex-col gap-4 rounded-lg border-0 bg-white p-4 md:m-6 md:p-6">
      {isLoading ? (
        <div className="flex h-full flex-1 flex-col items-center justify-center gap-6">
          <Loader
            size={LOADER_SIZE.lg}
            className="h-10 w-10 self-center text-theme-purple"
          />
        </div>
      ) : (
        <Table
          hiddenColumns={[
            "zipCode",
            "city",
            "street",
            "state",
            "country",
            "firstName",
            "lastName",
            "created",
          ]}
          headerComponent={(e) => (
            <TableHeader
              heading="Sites"
              columnData={e}
              showSearchField={false}
              headerActionItemsItems={[
                {
                  children: "Add Site",
                  kind: "secondary",
                  size: "sm",
                  className: "md:!px-12",
                  onClick: () => navigate("/sites/add-site"),
                },
              ]}
            />
          )}
          columns={columns}
          data={data}
          onClick={(e: any, row: any, cell: any) => {
            if (cell.column.id !== "action") {
              if (row.original?.uuid) {
                navigate(`/sites/add-site?id=${concat(row.original?.uuid)}`);
              }
            }
          }}
          emptyTablePlaceholder={
            <Placeholder
              label="No sites found"
              description="There are no sites registered to this tenant"
            />
          }
        />
      )}
      <PaginationButton
        total={totalPages}
        current={currentPage}
        prevLabel="Previous"
        nextLabel="Next"
        // skip: 2,
        perPage={size}
        paginationHandler={handlePageChange}
        handleNext={handleNextPage}
        handlePrev={handlePrevPage}
        isNextDisabled={!hasNext}
        isPrevDisabled={currentPage === 1}
      />
      <ActionModal
        modalType={currentModalType}
        isOpen={showModal}
        setIsOpen={setShowModal}
        onConfirm={handleConfirm}
        onCancel={handleCancel}
        entityName={currentSite?.name || "this site"}
        loading={modalActionLoading}
      />
    </div>
  );
};
