import { useEffect, useState } from "react";
import { Sites, useFetchSites } from "../stories/Sites";

export default function SitesScreen() {
  const queryParams = new URLSearchParams(window.location.search);
  const initialPage = parseInt(queryParams.get("page")?.toString() ?? "1");
  const [currentPage, setCurrentPage] = useState(initialPage);
  const size = 10;

  const { data, error, loading, mutate } = useFetchSites(currentPage, size);
  useEffect(() => {
    setCurrentPage(initialPage);
  }, [initialPage]);

  return (
    <Sites
      data={data && data.data ? data.data : []}
      refetch={mutate}
      isLoading={loading}
      currentPage={currentPage}
      totalPages={data?.hasNext ? currentPage + 1 : currentPage}
      setCurrentPage={setCurrentPage}
      size={size}
      hasNext={data?.hasNext}
    />
  );
}
