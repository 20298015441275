import { endpoints } from "../../services/api";
import { useSWR } from "../../hooks/useSWR";
import { devicePolyData } from "./mockData";
import { ISite } from "../Sites";

export type Device = {
  id: number;
  deviceUuid: string;
  uptime: number;
  siteId: string;
  deviceType:
    | "workstation"
    | "server"
    | "unknown"
    | "laptop"
    | "newx"
    | "desktop";
  hostName: string;
  deviceStatus: "clean" | "isolated" | "quarantined" | "Operational";
  osVersion: string;
  manufacturer?: string;
  threatsDetected?: number | null;
  label?: string;
  siteName?: string;
  lastActive: string;
  domain?: string;
  arch: string;
  systemStatus: string;
  ip: string;
  ramInfo: string;
  cpuInfo: {
    stepping: string;
    family: string;
    index: number;
    model: string;
    clockSpeedMhz: string;
    manufacturer: string;
    architecture: string;
  };
  mac: string;
  labels: string;
  deviceInfo: {
    domainOrWorkgroup: string;
    arxRaVersion: string;
    kernelVersion: string;
  };
  adapterName: string;
  action?: boolean;
};

export function useFetchDevices(
  page = 1,
  size = 10,
  selectedSite: ISite | null
) {
  // const response = await NetWrapper(url);
  const { data, error, loading, mutate } = useSWR<{
    data: Device[];
    hasNext: boolean;
  }>(
    selectedSite && selectedSite?.uuid
      ? endpoints.device.get(selectedSite?.uuid, page, size)
      : null
  );
  return { data, error, loading, mutate };
}

export function useFetchMetrics(
  deviceId: string | undefined,
  startTime: string,
  endTime: string
) {
  const { data, error, loading, mutate } = useSWR<typeof devicePolyData>(
    deviceId ? endpoints.metrics.get(deviceId, startTime, endTime) : null
  );
  return { data, error, loading, mutate };
}

export function useFetchDevice(deviceId: string | undefined) {
  const { data, error, loading, mutate } = useSWR<{ data: Device }>(
    deviceId ? endpoints.device.getById(deviceId) : null
  );
  return { data, error, loading, mutate };
}
