import { Modal } from "../Modal";
import { Dispatch, SetStateAction } from "react";
import { IActivityLogItem } from "../ActivityLogTable";
import { HardDrive, Shield, Warning } from "@phosphor-icons/react";
import { Icon, ICON_NAME } from "../Icon";

interface DeviceDetailsModalProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  title?: string;
  entityName?: string;
  entityName2?: string;
  dialogClassName?: string;
  formInitValues?: any;
  data?: IActivityLogItem;
  actionButtonTitles?: {
    cancel: string;
    confirm: string;
  };
}

const Card = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) => (
  <div
    className={`bg-card text-card-foreground rounded-lg border shadow-sm ${className}`}
    {...props}
  />
);

const CardContent = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) => (
  <div className={`p-6 ${className}`} {...props} />
);

const DeviceDetailsModal: React.FC<DeviceDetailsModalProps> = ({
  isOpen,
  setIsOpen,
  data,
}) => {
  return (
    <Modal
      setIsOpen={setIsOpen}
      isOpen={isOpen}
      dialogClassName="!max-w-5xl min-h-fit h-[70vh] !max-h-[700px]"
    >
      <div className="mx-auto max-w-5xl p-6">
        <div className="h-full overflow-auto">
          <div className="space-y-4">
            <div className="flex items-center justify-between py-1 pr-1">
              <h2 className="text-2xl font-bold text-theme-purple">
                Activity Information
              </h2>
              <button
                onClick={() => setIsOpen(!isOpen)}
                className="ml-auto"
                title="Close"
              >
                <Icon
                  name={ICON_NAME.x}
                  weight="bold"
                  className="h-5 w-5 cursor-pointer text-theme-gray"
                />
              </button>
            </div>

            <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
              {/* {data?.threatDetails && (
                <Card className="border-l-4 border-l-purple-500">
                  <CardContent>
                    <h3 className="mb-3 flex items-center text-lg font-semibold text-purple-700">
                      <Shield className="mr-2 h-5 w-5" /> Threat Details
                    </h3>
                    <div className="space-y-2">
                      <p className="font-medium">
                        <span className="font-semibold">Name:</span>{" "}
                        {data?.threatDetails.threatName}
                      </p>
                      <p>
                        <span className="font-semibold">Level:</span>{" "}
                       <span
                          className={`${
                            data?.eventInfo.eventLevel
                              .toLowerCase()
                              ?.includes("critical")
                              ? "bg-indicator-red-200"
                              : "bg-badge-orange-200"
                          } rounded-full px-2.5 py-0.5 text-sm font-medium text-white`}
                        >
                          {data?.eventInfo.eventLevel.toUpperCase()}
                        </span>
                      </p>
                      <p className="font-medium">
                        <span className="font-semibold">Hash:</span>{" "}
                        {data?.threatDetails.hash}
                      </p>
                    </div>
                  </CardContent>
                </Card>
              )} */}
              <Card className="col-span-2 border-l-4 border-l-purple-500">
                <CardContent className="p-4">
                  <h3 className="mb-3 flex items-center text-lg font-semibold text-purple-700">
                    {data?.eventInfo.eventLevel?.toLowerCase() ===
                    "information" ? (
                      <Shield className="mr-2 h-5 w-5" />
                    ) : (
                      <Warning className="mr-2 h-5 w-5" />
                    )}
                    Event Information
                  </h3>
                  <div className="space-y-2">
                    <p className="font-medium">
                      <span className="font-semibold">Type:</span>{" "}
                      {data?.eventInfo.eventType}
                    </p>
                    <p className="font-medium">
                      <span className="font-semibold">Level:</span>{" "}
                      {data?.eventInfo.eventLevel?.toLowerCase() ===
                      "information" ? (
                        data?.eventInfo.eventLevel
                      ) : (
                        <span
                          className={`${
                            data?.eventInfo.eventLevel
                              .toLowerCase()
                              ?.includes("critical")
                              ? "bg-indicator-red-200"
                              : "bg-badge-orange-200"
                          } rounded-full px-2.5 py-0.5 text-sm font-medium text-white`}
                        >
                          {data?.eventInfo.eventLevel.toUpperCase()}
                        </span>
                      )}
                    </p>
                    <p className="font-medium">
                      <span className="font-semibold">Message:</span>{" "}
                      {data?.eventInfo.eventMessage}
                    </p>
                  </div>
                </CardContent>
              </Card>
              <Card className="border-l-4 border-l-orange-500">
                <CardContent className="p-4">
                  <h3 className="mb-3 flex items-center text-lg font-semibold text-orange-700">
                    <HardDrive className="mr-2 h-5 w-5" /> Device & User
                    Information
                  </h3>
                  <div className="space-y-2">
                    <p className="font-medium">
                      <span className="font-semibold">Hostname:</span>{" "}
                      {data?.hostSystem.hostname}
                    </p>
                    <p className="font-medium">
                      <span className="font-semibold">IP:</span>{" "}
                      {data?.hostSystem?.ipAddress}
                    </p>
                    <p className="font-medium">
                      <span className="font-semibold">OS:</span>{" "}
                      {data?.hostSystem.operatingSystem}
                    </p>
                    <p className="font-medium">
                      <span className="font-semibold">User:</span>{" "}
                      {data?.hostSystem.userInfo}
                    </p>
                    <p className="font-medium">
                      <span className="font-semibold">Device ID:</span>{" "}
                      <span className="break-all font-mono text-sm">
                        {data?.deviceId}
                      </span>
                    </p>
                  </div>
                </CardContent>
              </Card>
              {/* border-l-green-500,700 */}
              <Card className="border-l-4 border-l-blue-500">
                <CardContent className="p-4">
                  <h3 className="mb-3 flex items-center text-lg font-semibold text-blue-700">
                    Additional Information
                  </h3>
                  <div className="space-y-2">
                    <p className="font-medium">
                      <span className="font-semibold">Site ID:</span>{" "}
                      <span className="break-all font-mono text-sm">
                        {data?.siteId}
                      </span>
                    </p>
                    <p className="font-medium">
                      <span className="font-semibold">Tenant ID:</span>{" "}
                      <span className="break-all font-mono text-sm">
                        {data?.tenantId}
                      </span>
                    </p>
                    <p className="font-medium">
                      <span className="font-semibold">Event ID:</span>{" "}
                      {data?.id}
                    </p>
                    {data?.timestamp && (
                      <p className="font-medium">
                        <span className="font-semibold">Timestamp:</span>{" "}
                        {new Date(data.timestamp).toLocaleString()}
                      </p>
                    )}
                  </div>
                </CardContent>
              </Card>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default DeviceDetailsModal;
