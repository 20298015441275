export interface ICardWithLogoProps {
  logo?: string;
  value?: string | number;
  heading?: string;
  description?: string;
  subValue?: string | number;
}
export const CardWithLogo = ({
  description,
  heading,
  logo,
  value,
  subValue,
}: ICardWithLogoProps) => {
  return (
    <div className="flex flex-1 flex-row items-center justify-start gap-2 rounded-lg bg-white p-4 align-middle xl:gap-4 xl:p-6">
      {logo && <img src={logo} alt="img" className="h-14 w-auto" />}

      <div className="flex flex-col border-0">
        <div>
          <span className="mr-1 text-3xl font-bold xl:text-4xl">{value}</span>
          {subValue && (
            <span className="text-xl font-semibold">/{subValue}</span>
          )}
          <span className="text-xl font-semibold xl:text-2xl"> {heading}</span>
        </div>
        <span className="text-xlg">{description}</span>
        <div></div>
      </div>
    </div>
  );
};
