import AppLogo from "../assets/main_logo.png";

const termsData = {
  title: "Terms & Conditions",
  sections: [
    {
      title: "Definitions",

      subsections: [
        {
          content:
            "'ArmorxAI' refers to the anti-ransomware company headquartered in 1935A Addison Ste, Berkeley, California 94704.",
        },
        {
          content:
            "'Services' refers to the ArmorxAI Director (web management console), API, and any associated features and tools provided by ArmorxAI for ransomware detection, mitigation, ",
        },
        {
          content:
            "'API' refers to the Application Programming Interface provided by ArmorxAI to allow programmatic access to the Services.",
        },
        {
          content:
            "'User' refers to any individual or entity accessing the Services through the web console or API.",
        },
        {
          content:
            "'Customer' refers to any entity or individual participating in a trial or beta or has purchased or subscribed to ArmorxAI's Services.",
        },
        {
          content:
            "'Data' refers to any information, logs, or other materials uploaded or generated by the User through the Services.",
        },
      ],
    },
    {
      title: "Eligibility",

      subsections: [
        {
          content:
            "To use the Services, you must be at least 18 or the age of majority in your jurisdiction. By accessing the Services, you confirm that you meet this eligibility requirement.",
        },
        {
          content:
            "If you are accessing or using the Services on behalf of a business, organization, or another legal entity, you represent that you have the authority to bind that entity to these ",
        },
      ],
    },
    {
      title: "Use of Services",

      subsections: [
        {
          content:
            "You may use the Services solely for lawful purposes and in accordance with these Terms. Unauthorized use of the Services, including but not limited to unauthorized access to data, ",
        },
        {
          content:
            "Access to the Services may require the creation of an account. You agree to provide accurate and complete information when creating your account and to keep this information up to ",
        },
        {
          content:
            "The API is provided for programmatic access to the Services. You must use the API in accordance with the API documentation provided by ArmorxAI. Excessive use of the API that ",
        },
        {
          content:
            "You are responsible for all activity associated with your account, including any use of the Services via API or the web management console.",
        },
      ],
    },
    {
      title: "License Grant",

      subsections: [
        {
          content:
            "ArmorxAI grants you a limited, non-exclusive, non-transferable, revocable license to access and use the Services, including the API, for your business purposes.",
        },
        {
          content:
            "You agree not to sell, license, sublicense, distribute, or otherwise make the Services available to any third party without the express written consent of ArmorxAI.",
        },
        {
          content:
            "ArmorxAI reserves all rights not expressly granted to you in these Terms.",
        },
      ],
    },
    {
      title: "Data and Privacy",

      subsections: [
        {
          content:
            "By using the Services, you agree to the collection, storage, and use of your data in accordance with our Privacy Policy. ArmorxAI implements commercially reasonable measures to ",
        },
        {
          content:
            "You retain ownership of your data. However, you grant ArmorxAI a non-exclusive, royalty-free license to use your data to provide and improve the Services.",
        },
        {
          content:
            "ArmorxAI is not liable for any data breaches or unauthorized access to your account or data unless caused by ArmorxAI's gross negligence or willful misconduct.",
        },
        {
          content:
            "California consumers have certain privacy rights under the California Consumer Privacy Act (CCPA). For more details, refer to our Privacy Policy.",
        },
      ],
    },
    {
      title: "Fees and Payment",

      subsections: [
        {
          content:
            "Access to features or functionalities of the Services may require payment of fees. You agree to pay all applicable fees in accordance with the terms provided at the time of ",
        },
        {
          content:
            "ArmorxAI reserves the right to modify its fees and pricing at any time, with reasonable notice provided to you.",
        },
        {
          content:
            "If you fail to make a payment when due, ArmorxAI may suspend or terminate your access to the Services without further notice.",
        },
      ],
    },
    {
      title: "Termination and Suspension",

      subsections: [
        {
          content:
            "You may terminate your use of the Services anytime by ceasing to access the web console and API. You may submit a written request to delete your account and associated data if ",
        },
        {
          content:
            "ArmorxAI reserves the right to suspend or terminate your access to the Services if you violate these Terms or if necessary to protect the integrity and security of the Services.",
        },
        {
          content:
            "Upon termination, any licenses granted to you under these Terms will automatically terminate, and you must cease all use of the Services.",
        },
      ],
    },
    {
      title: "Intellectual Property",

      subsections: [
        {
          content:
            "All content, features, and functionality provided through the Services, including the API, are the exclusive property of ArmorxAI or its licensors and are protected by copyright, ",
        },
        {
          content:
            "You are prohibited from using any of ArmorxAI’s intellectual property without prior written consent, except as expressly permitted by these Terms.",
        },
      ],
    },
    {
      title: "Limitation of Liability",

      subsections: [
        {
          content:
            "To the maximum extent permitted by applicable law, ArmorxAI is not liable for any indirect, incidental, special, consequential, or punitive damages arising from your use of the ",
        },
        {
          content:
            "ArmorxAI’s liability for any claims arising from or related to the Services is limited to the amount you paid for the Services in the 12 months preceding the claim.",
        },
      ],
    },
    {
      title: "Indemnification",

      subsections: [
        {
          content:
            "You agree to indemnify, defend, and hold harmless ArmorxAI, its affiliates, officers, employees, and agents from any claims, damages, liabilities, or expenses arising from your use of these terms.",
        },
      ],
    },
    {
      title: "Governing Law and Dispute Resolution",

      subsections: [
        {
          content:
            " These Terms will be governed by and construed in accordance with the laws of the State of New York, without regard to its conflict of law principles.",
        },
        {
          content:
            " Any disputes arising from or related to these Terms or the Services will be resolved through binding arbitration under the rules of the American Arbitration Association. The ",
        },
        {
          content:
            " Notwithstanding the foregoing, either party may seek injunctive or equitable relief in any court of competent jurisdiction for any actual or threatened breach of intellectual ",
        },
      ],
    },
    {
      title: "Changes to Terms",

      subsections: [
        {
          content:
            " ArmorxAI reserves the right to modify these Terms at any time. ArmorxAI will update the 'Last Updated' date at the top of this document when changes are made. Your continued use ",
        },
        {
          content:
            " It is your responsibility to review these Terms periodically to stay informed of updates.",
        },
      ],
    },
    {
      title: "Miscellaneous",

      subsections: [
        {
          content:
            " If any provision of these Terms is found invalid or unenforceable, the remaining provisions will remain in full force and effect.",
        },
        {
          content:
            " These Terms constitute the entire agreement between you and ArmorxAI regarding your use of the Services and supersede all prior or contemporaneous agreements, whether written or ",
        },
        {
          content:
            " You may not assign or transfer these Terms without the prior written consent of ArmorxAI.",
        },
        {
          content:
            " ArmorxAI’s failure to enforce any right or provision of these Terms will not constitute a waiver of such right or provision.",
        },
      ],
    },
  ],
};

export const TermsAndConditions = ({ heading }: { heading: string }) => {
  return (
    <div className="container mx-auto p-4">
      <div className="mb-8 flex justify-center">
        <img src={AppLogo} alt="logo" className="h-10 hover:cursor-pointer" />
      </div>

      <h1 className="mb-10 text-center text-3xl font-bold">
        {termsData.title}
      </h1>
      <p className="text-right">Last updated: 19 September 2024</p>
      <p className="mb-2 text-lg">
        Welcome to Kapalya Inc. (dba ArmorAI) ('ArmorxAI')! These Terms and
        Conditions ('Terms') govern your use of ArmorxAI Director and associated
        API (collectively, the 'Services'). You agree to comply with these Terms
        by accessing or using the Services. If you do not agree to these Terms,
        you must not access or use the Services. These Terms apply to all users,
        including customers, partners, and other individuals or entities
        accessing the Services.
      </p>
      <ol className=" space-y-6  pl-6  [counter-reset:section] ">
        {termsData.sections.map((section, index) => (
          <li
            key={index}
            className="text-2xl [counter-increment:section] marker:[content:counters(section,'.')'.']"
          >
            <h2 className="mb-4 ml-1 text-2xl font-semibold">
              {section.title}
            </h2>

            <ol className="list-decimal space-y-2 pl-6 [counter-reset:section]">
              {section.subsections &&
                section.subsections.map((subsection, subIndex) => (
                  <li
                    key={subIndex}
                    className="text-lg [counter-increment:section] marker:[content:counters(section,'.')]"
                  >
                    <p className="ml-4 text-lg leading-relaxed">
                      {subsection.content}
                    </p>
                  </li>
                ))}
            </ol>
          </li>
        ))}
      </ol>
      <p className="mt-2 text-lg">
        Thank you for choosing ArmorxAI. If you have any questions about these
        Terms, please contact us at
        <a href={"mailto:support@armorx.ai"}> support@armorx.ai </a>.
      </p>
    </div>
  );
};
