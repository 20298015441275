import { BASE_URL } from "../utils/const";
import { notifyError } from "../utils/notify";
import { Auth } from "./auth";
import { useAPI } from "./useAPI";

const useSWR = <T, E = any>(path: string | null) => {
  const { auth, logout } = Auth();
  const dispatchEvent = (status = false) => {
    const loadEvent = new CustomEvent("swrEvent", {
      detail: {
        status,
      },
    });
    window.dispatchEvent(loadEvent);
  };

  function eventDispatcher(useSWRNext: any): any {
    return (key: string, fetcher: any, config: any) => {
      // Before hook runs...
      dispatchEvent(true);
      // Handle the next middleware, or the `useSWR` hook if this is the last one.
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const swr = useSWRNext(key, fetcher, config);

      // After hook runs...

      return swr;
    };
  }
  const { data, mutate, isValidating, loading, error } = useAPI<T, E>(path, {
    baseURL: process.env.REACT_APP_BASE_URL ?? BASE_URL,
    token:
      auth.authType === "sso"
        ? "Bearer ".concat(auth.token)
        : "Basic ".concat(auth.token),
    middlewares: [eventDispatcher],
    logout: logout,
  });
  if (error) {
    notifyError(error?.message ?? "Something went wrong");

    const event = new CustomEvent("toasterEvents", {
      detail: {
        type: "error",
        message: error.message,
        duration: 50000,
        dismissible: true,
      },
    });
    window.dispatchEvent(event);
  }
  if (data || !loading || error) {
    dispatchEvent(false);
  }
  return {
    data,
    error,
    loading,
    mutate,
    isValidating,
  };
};

export { useSWR };
