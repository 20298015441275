import { useEffect, useState } from "react";
import { SecurityPolicy, useFetchPolicies } from "../stories/SecurityPolicy";
import { useAuth } from "../context/AuthProvider";

export default function SecurityPoliciesScreen() {
  const queryParams = new URLSearchParams(window.location.search);
  const initialPage = parseInt(queryParams.get("page")?.toString() ?? "1");
  const [currentPage, setCurrentPage] = useState(initialPage);
  const { user } = useAuth();

  const { data, loading, mutate } = useFetchPolicies({
    tenantId: user?.tenantId ?? undefined,
  });
  const size = 10;

  useEffect(() => {
    setCurrentPage(initialPage);
  }, [initialPage]);

  return (
    <SecurityPolicy
      data={data?.data}
      refetch={mutate}
      isLoading={loading}
      currentPage={currentPage}
      totalPages={1}
      setCurrentPage={setCurrentPage}
      size={size}
      hasNext={false}
      tenantId={user?.tenantId ?? ""}
    />
  );
}
