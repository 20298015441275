import { BUTTON_KIND, BUTTON_SIZE, Button } from "../Button";
import { InputField } from "../InputField";
import { AuthLayout, AuthLayoutProps } from "../AuthLayout";
import { LogoWithTitle } from "../LogoWithTitle";
import { ICON_NAME } from "../Icon";
import { ErrorMessage, Form, Formik } from "formik";
import { SlidingText } from "../SliderText";
import { useNavigate } from "react-router-dom";
import { endpoints } from "../../services/api";
import { NetWrapper } from "../../utils/netwrapper";
import { Auth } from "../../hooks/auth";
import * as Yup from "yup";
import { useState } from "react";
import { notifyError, notifySuccess } from "../../utils/notify";

export interface LoginPageProps extends Partial<AuthLayoutProps> {
  titleText: string;
  placeholderURL: string;
  helperTextArray: string[];
}

export const LoginPage: React.FC<LoginPageProps> = (props) => {
  return (
    <AuthLayout
      leftSection={<LeftSection {...props} />}
      rightSection={<LoginSection />}
    />
  );
};
const LeftSection: React.FC<LoginPageProps> = ({
  titleText,
  placeholderURL,
  helperTextArray,
}) => (
  <div className="max-w-lg lg:pt-6 xl:pt-16">
    <div className="flex flex-col items-center">
      <div className="pb-16 text-center text-5xl font-bold text-white">
        {titleText}
      </div>
      <div>
        <img className="h-full w-full" alt="placeholder" src={placeholderURL} />
      </div>
    </div>
    <div className="flex flex-col items-center">
      <div className="py-10 text-2xl font-semibold text-white">
        <SlidingText texts={helperTextArray} />
      </div>
    </div>
  </div>
);

const LoginSection = () => {
  const navigate = useNavigate();
  const { login, setUser } = Auth();
  const [isLoading, setIsLoading] = useState(false);
  const onClickLogin = async (value: { email: string; password: string }) => {
    if (value.email && value.password) {
      setIsLoading(true);
      try {
        const credentials = `${value.email}:${value.password}`;
        const encodedCredentials = btoa(credentials);
        const response = await NetWrapper(
          endpoints.auth.sso(value.email).concat("&authType=root"),
          {
            method: "GET",
            headers: {
              Authorization: `Basic ${encodedCredentials}`,
            },
          },
          false
        );

        if (response && response.status === 200) {
          login({
            token: encodedCredentials,
            authType: "root",
          });
          // navigate("/devices");
        }

        if (response && response.error) {
          notifyError(
            response.status === 403
              ? "Incorrect email address or password. Please check your credentials and try again."
              : response.error?.message ?? "Something went wrong"
          );
        }

        setIsLoading(false);
      } catch (error: any) {
        console.log(error.message);
        notifyError(error.message);
        setIsLoading(false);
      }
    }
  };

  const [isLogin, setIsLogin] = useState(true);

  const loginFormValidationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Please enter a valid email address.")
      .required("Email address is required."),
    password: Yup.string().when("isLogin", {
      is: () => isLogin,
      then: () => Yup.string().required("Password is required."),
      otherwise: () => Yup.string().notRequired(),
    }),
  });

  const onForgotPassword = async (value: { email: string }) => {
    const { data, error } = await NetWrapper<{ email: string }>(
      endpoints.auth.forgotPassword,
      {
        method: "post",
        body: JSON.stringify({
          email: value.email,
        }),
      },
      false
    );
    if (data) {
      notifySuccess(`A password reset link has been sent to your email`);
    }

    if (error) {
      notifyError(error.message || "something went wrong");
    }
  };

  return (
    <div className="max-w-md">
      <LogoWithTitle title={"ArmorxAI Director Login"} />
      <div className="flex flex-col gap-10">
        <Button
          kind={BUTTON_KIND.primary}
          size={BUTTON_SIZE.lg}
          onClick={() => {
            navigate("/single-sign-on");
          }}
          iconLeft={{
            name: ICON_NAME.user,
          }}
        >
          Login with SSO
        </Button>
        <div className="flex items-center gap-2">
          <div className="h-px w-full border border-gray-300 md:w-40"></div>
          <div className="w-min text-center text-base font-normal text-slate-700 md:min-w-24">
            or login with
          </div>
          <div className="h-px w-full border border-gray-300 md:w-40"></div>
        </div>
        <Formik
          initialValues={{ email: "", password: "" }}
          validationSchema={loginFormValidationSchema}
          onSubmit={() => {}}
        >
          {(props) => (
            <Form>
              <div className="flex flex-col gap-12">
                <div className="flex flex-col gap-4">
                  <div className="flex flex-col gap-1">
                    <InputField
                      name="email"
                      label="Enter email"
                      icon={ICON_NAME.email}
                    />
                    <ErrorMessage
                      name={"email"}
                      component="div"
                      className="mt-1 text-sm text-red-600"
                    />
                  </div>
                  <div className="flex flex-col gap-1">
                    <InputField
                      name="password"
                      label="Enter Password"
                      type="password"
                      icon={ICON_NAME.password}
                    />
                    <ErrorMessage
                      name={"password"}
                      component="div"
                      className="mt-1 text-sm text-red-600"
                    />
                  </div>
                  <div>
                    <Button
                      kind={BUTTON_KIND.plainUnderline}
                      size={BUTTON_SIZE.lg}
                      className="!p-0"
                      type="button"
                      onClick={async () => {
                        setIsLogin(false);

                        await props.validateForm();
                        if (props.isValid) {
                          onForgotPassword(props.values);
                        }
                      }}
                    >
                      Forgot password?
                    </Button>
                  </div>
                </div>
                <div className="flex flex-col gap-6">
                  <Button
                    kind={BUTTON_KIND.secondary}
                    size={BUTTON_SIZE.lg}
                    loading={isLoading}
                    type="submit"
                    onClick={() => {
                      setIsLogin(true);
                      if (props.isValid) {
                        onClickLogin(props.values);
                      }
                    }}
                  >
                    Continue
                  </Button>
                  <div>
                    <span className="text-base font-medium text-slate-700">
                      Don't have an account yet? &nbsp;
                    </span>
                    <Button
                      kind={BUTTON_KIND.plain}
                      size={BUTTON_SIZE.lg}
                      className="!p-0"
                      url="https://www.armorx.ai/contact-us"
                    >
                      Contact Us
                    </Button>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};
