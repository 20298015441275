import { HTMLInputTypeAttribute, InputHTMLAttributes, useState } from "react";
import clsx from "clsx";
import { ICON_NAME, Icon } from "../Icon";
import { useField } from "formik";

export interface IInputField extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  name: string;
  icon?: ICON_NAME;
  type?: HTMLInputTypeAttribute;
  fieldclass?: string;
  inputsize?: "xs" | "sm" | "base"; //using inputsize since size is predefined
}

const InputSizeClasses = {
  xs: "py-1 text-xs/6 rounded",
  sm: "py-1 text-sm/6 rounded",
  base: "px-4 py-3 text-base/6 rounded-lg ",
};
const IconSizeClasses = {
  xs: "h-5 w-5",
  sm: "h-5 w-5",
  base: "h-6 w-6",
};
const LabelClasses = {
  xs: "text-xs/6 peer-[:not(:placeholder-shown)]:-mt-[13px] peer-focus:-mt-[13px] peer-[:not(:placeholder-shown)]:left-3.5 peer-focus:left-3.5",
  sm: "text-sm/6 peer-[:not(:placeholder-shown)]:-mt-[13px] peer-focus:-mt-[13px] peer-[:not(:placeholder-shown)]:left-3.5 peer-focus:left-3.5",
  base: "text-base/6 peer-[:not(:placeholder-shown)]:-mt-5 peer-focus:-mt-5 peer-[:not(:placeholder-shown)]:left-2.5 peer-focus:left-2.5",
};

export function InputField(props: IInputField) {
  const {
    label,
    type,
    name,
    fieldclass = "",
    inputsize = "base",
    icon,
    ...rest
  } = props;
  const [field, meta] = useField(name);
  const [show, setShow] = useState(false);
  const PasswordIcon = !show ? ICON_NAME.eyeSlash : ICON_NAME.eyeIcon;
  return (
    <div className="group relative z-0 transition-all focus-within:z-10">
      <input
        id={name}
        {...props}
        type={show ? "text" : type}
        placeholder="" //Keep it for Label animation
        className={clsx(
          "peer block w-full border   text-neutral-950 transition focus:border-theme-gray focus:outline-none focus:ring-theme-gray",
          icon
            ? inputsize === "xs" || inputsize === "sm"
              ? "pl-10"
              : "pl-12"
            : "pl-4",
          InputSizeClasses[inputsize],
          fieldclass,
          props.disabled
            ? "border-gray-200 bg-gray-50"
            : "border-neutral-300 bg-transparent"
        )}
        {...field}
        {...rest}
      />
      <label
        htmlFor={name}
        className={clsx(
          "pointer-events-none absolute top-1/2 -mt-3 origin-left bg-transparent px-1 font-normal text-gray-800 transition-all duration-200 peer-focus:top-[45%] peer-focus:-translate-y-4 peer-focus:scale-75 peer-focus:bg-white peer-focus:font-light peer-focus:text-neutral-950 peer-[:not(:placeholder-shown)]:top-[45%] peer-[:not(:placeholder-shown)]:-translate-y-4 peer-[:not(:placeholder-shown)]:scale-75 peer-[:not(:placeholder-shown)]:bg-white peer-[:not(:placeholder-shown)]:font-light",
          icon
            ? inputsize === "sm"
              ? "peer-[(:placeholder-shown)]:left-10"
              : "peer-[(:placeholder-shown)]:left-12"
            : "peer-[(:placeholder-shown)]:left-4",
          LabelClasses[inputsize],
          !props.disabled ? "after:hidden" : "after:block", //If disabled, then the color should match the background one without border overlapping
          "after:absolute after:inset-0 after:top-1/2 after:-z-10 after:bg-gray-50"
        )}
      >
        {label}
      </label>
      {icon && (
        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-4">
          <Icon className=" text-theme-gray" name={icon} />
        </div>
      )}
      <div className="absolute inset-y-0 right-4 flex items-center gap-1">
        {type === "password" && (
          <button type="button" onClick={() => setShow(!show)}>
            <Icon
              className={`text-theme-gray ${IconSizeClasses[inputsize]}`}
              aria-hidden="true"
              name={PasswordIcon}
            />
          </button>
        )}
        {meta.error && meta.touched && (
          <Icon
            name={ICON_NAME.info}
            className="pointer-events-none h-5 w-5 text-red-500"
            aria-hidden="true"
          />
        )}
      </div>
    </div>
  );
}
