import { Tab, TabGroup, TabList, TabPanel, TabPanels } from "@headlessui/react";
import clsx from "clsx";
import { Fragment, ReactNode } from "react";
import { Loader, LOADER_SIZE } from "../Loader";

export interface TabsProps {
  tabs: string[];
  contents: ReactNode[];
  className?: string;
  tabClassName?: string;
  childClassName?: string;
  loading?: boolean;
}

export function Tabs({
  tabs,
  contents,
  tabClassName,
  className,
  childClassName,
  loading,
}: TabsProps) {
  return (
    <TabGroup>
      <TabList className={clsx("flex space-x-1", className)}>
        {tabs.map((tab, idx) => (
          <Tab as={Fragment} key={idx}>
            {({ selected }) => (
              <button
                className={clsx(
                  "px-4 py-2.5 text-sm font-medium leading-5 ",
                  "ring-0 focus:outline-none",
                  selected
                    ? "border-b-2 border-theme-purple text-theme-purple"
                    : "mb-0.5 text-theme-gray hover:text-theme-purple",
                  tabClassName
                )}
              >
                {tab}
              </button>
            )}
          </Tab>
        ))}
      </TabList>
      <TabPanels>
        {loading ? (
          <div className="m-6 flex h-full min-h-80 items-center justify-center rounded-lg bg-white bg-opacity-50 ">
            <Loader className="text-theme-purple" size={LOADER_SIZE.lg} />
          </div>
        ) : (
          contents.map((content, idx) => (
            <TabPanel key={idx} className={childClassName}>
              {content}
            </TabPanel>
          ))
        )}
      </TabPanels>
    </TabGroup>
  );
}
