import clsx from "clsx";
import { ErrorMessage, Form, Formik } from "formik";
import { map } from "lodash";
import { useEffect, useState } from "react";
import * as Yup from "yup";
import { renderField } from "../../utils/renderField";
import { BUTTON_KIND, BUTTON_SIZE } from "../Button";
import { CardSection } from "../CardSection";
import { sections } from "./SIEMProfileForm";
export interface ISIEMProfile {
  ip: string;
  port: string;
  tokenOrKey: string;
  tag: string;
  logFormat: string;
  protocol: string;
}
const AddSIEMProfile = ({
  onSubmit,
  edititing,
  setIsEditing,
  data,
}: {
  onSubmit: (data: any) => void;
  data: ISIEMProfile;
  edititing: boolean;
  setIsEditing: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [initialValues, setInitialValues] = useState({
    ip: "",
    port: "",
    tokenOrKey: "",
    protocol: "",
  });

  useEffect(() => {
    if (data) {
      setInitialValues({
        ip: data.ip,
        port: data.port,
        tokenOrKey: data.tokenOrKey,
        protocol: data.protocol,
      });
    }
  }, [data]);

  const validationSchema = Yup.object().shape({
    ip: Yup.string().required("IP is required"),
    port: Yup.number().required("Port is required"),
    tokenOrKey: Yup.string(),
    protocol: Yup.string().required("Protocol  is required"),
  });

  const onSubmitForm = (formValues: typeof initialValues) => {
    onSubmit({ ...formValues, tag: "ArmorxAI", logFormat: "json" });
  };

  return (
    <div className="">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmitForm}
        enableReinitialize={true}
      >
        {(props) => (
          <Form>
            <div className="flex flex-col space-y-6">
              <CardSection
                title={"SIEM"}
                headerActionItemsItems={[
                  {
                    children: edititing ? "Save " : "Edit",
                    kind: BUTTON_KIND.secondary,
                    size: BUTTON_SIZE.sm,
                    className: "md:!px-12",
                    disabled: false,
                    type: "button",
                    onClick: () => {
                      if (edititing) {
                        props.submitForm();
                      } else {
                        setIsEditing(!edititing);
                      }
                    },
                  },
                ]}
              >
                <div className="space-y-8">
                  {map(sections, ({ sections, title }, key) => (
                    <div key={key}>
                      {title && title.length > 0 && (
                        <p className="mb-4 text-xl font-semibold capitalize text-gray-900">
                          {title}
                        </p>
                      )}
                      <div className="space-y-2 ">
                        {map(
                          sections,
                          (
                            {
                              children,
                              title,
                              // renderHeader,
                              className = "grid-cols-1",
                            },
                            key
                          ) => (
                            <div key={key}>
                              {title && title.length > 0 && (
                                <p className="mb-4 text-lg font-medium capitalize text-gray-700">
                                  {title}
                                </p>
                              )}
                              <div
                                className={clsx(
                                  "max-w-2xl gap-4 pl-3",
                                  className
                                )}
                                key={key}
                              >
                                {map(children, (value, key) => {
                                  return (
                                    <div key={key}>
                                      <label className="mb-2 block text-sm font-medium text-gray-700">
                                        {value.label}
                                      </label>
                                      {map(value.fields, (field, fieldKey) => (
                                        <div
                                          key={fieldKey}
                                          className={`mb-4 ${field.type !== "textarea" ? "max-w-196" : "w-full"}`}
                                        >
                                          {renderField(
                                            field,
                                            props,
                                            () => false,
                                            edititing ? false : true
                                          )}
                                          <ErrorMessage
                                            name={field.name}
                                            component="div"
                                            className="mt-1 text-sm text-red-600"
                                          />
                                        </div>
                                      ))}
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          )
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </CardSection>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default AddSIEMProfile;
