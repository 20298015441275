import { IForm } from "../../utils/interface";

export const sections: IForm[] = [
  {
    title: "",
    sections: [
      {
        title: "",
        className: "grid-cols-2 md:grid-cols-3",
        children: [
          {
            label: "Tenant ID",
            fields: [
              {
                name: "uuid",
                type: "disabled",
                placeholder: "",
                disabled: true,
              },
            ],
          },
          {
            label: "",
            fields: [
              {
                name: "email",
                type: "text",
                disabled: true,
                placeholder: "Email",
              },
            ],
          },
        ],
      },
      {
        title: "Contact Information",
        className: "grid-cols-2 md:grid-cols-3",
        children: [
          {
            label: "",
            fields: [
              {
                name: "tenantName",
                type: "text",
                disabled: true,
                placeholder: "Domain name",
              },
            ],
          },
          {
            label: "",
            fields: [
              {
                name: "firstName",
                type: "text",
                placeholder: "First Name",
              },
            ],
          },
          {
            label: "",
            fields: [
              {
                name: "lastName",
                type: "text",
                placeholder: "Last Name",
              },
            ],
          },
          {
            label: "",
            fields: [
              {
                name: "contact",
                type: "text",
                placeholder: "Phone Number",
              },
            ],
          },
        ],
      },
      {
        title: "Address Information",
        className: "grid-cols-2 md:grid-cols-3",
        children: [
          {
            label: "",
            fields: [
              {
                name: "street",
                type: "text",
                placeholder: "Street",
              },
            ],
          },
          {
            label: "",
            fields: [
              {
                name: "city",
                type: "text",
                placeholder: "City",
              },
            ],
          },
          {
            label: "",
            fields: [
              {
                name: "state",
                type: "text",
                placeholder: "State",
              },
            ],
          },

          {
            label: "",
            fields: [
              {
                name: "zipCode",
                type: "text",
                placeholder: "ZipCode",
              },
            ],
          },
          {
            label: "",
            fields: [
              {
                name: "country",
                type: "text",
                placeholder: "Country",
              },
            ],
          },
        ],
      },
      {
        title: "Other Information",
        className: "grid-cols-1",
        children: [
          {
            label: "",
            fields: [
              {
                name: "description",
                label: "Details",
                type: "textarea",
                placeholder: "Details",
              },
            ],
          },
        ],
      },
    ],
  },
];
