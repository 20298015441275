import { BUTTON_KIND, BUTTON_SIZE, Button } from "../Button";
import { InputField } from "../InputField";
import Placeholder from "../assets/register_final_vector.svg";
import { AuthLayout } from "../AuthLayout";
import { LogoWithTitle } from "../LogoWithTitle";
import { RegisterSteps } from "../RegisterSteps";
import { Formik, Form, ErrorMessage } from "formik";
import { useEffect, useState } from "react";
import { NetWrapper } from "../../utils/netwrapper";
import { endpoints } from "../../services/api";
import * as Yup from "yup";
import { notifyError, notifySuccess } from "../../utils/notify";
import { useNavigate } from "react-router-dom";

export const ResetPassword: React.FC = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const email = queryParams.get("email");
  const accessToken = queryParams.get("token");

  const onClickReset = ({
    oldPassword,
    password,
  }: {
    oldPassword: string;
    password: string;
  }) => {
    setIsLoading(true);
    const body = JSON.stringify({
      tenantEmail: email,
      oldPassword: oldPassword,
      newPassword: password,
    });
    NetWrapper(
      endpoints.auth.resetPassword,
      {
        method: "POST",
        body: body,
        headers: {
          "Content-Type": "Application/json",
        },
      },
      false
    )
      .then((resp) => {
        if (resp.status === 200) {
          notifySuccess("Please log in with your new password");
          navigate("/login");
        } else {
          notifyError(resp.error?.message || "Something went wrong");
        }
        setIsLoading(false);
      })
      .catch((error: any) => {
        console.log(error);
        notifyError("Something went wrong");
        setIsLoading(false);
      });
  };

  return (
    <AuthLayout
      leftSection={
        <RegisterSteps
          heading={"Temporary Password..."}
          description={
            <div>
              <div className="pb-10 text-white">
                Enter a new password to complete a new account setup or change
                your password.
              </div>
              <div className="font-bold text-white">
                Please safeguard this account credentials.
              </div>
            </div>
          }
          placeholder={Placeholder}
        />
      }
      rightSection={
        email && accessToken ? (
          <RegisterSection
            email={email}
            resetPass={onClickReset}
            isLoading={isLoading}
          />
        ) : (
          <div>invalid</div>
        )
      }
    />
  );
};

const RegisterSection = ({
  email,
  resetPass,
  isLoading,
}: {
  email: string;
  resetPass: Function;
  isLoading: boolean;
}) => {
  const resetPassFormValidations = Yup.object().shape({
    oldPassword: Yup.string().required("Old password is required"),
    password: Yup.string()
      .min(8, "Password must be at least 8 characters long")
      .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
      .matches(/[a-z]/, "Password must contain at least one lowercase letter")
      .matches(/\d/, "Password must contain at least one number")
      .matches(
        /[!@#$%^&*(),.?":{}|<>]/,
        "Password must contain at least one special character"
      )
      .required("New Password is required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password")], "Passwords must match")
      .required("Confirm Password is required"),
  });
  return (
    <div className="w-full max-w-md">
      <LogoWithTitle title={<span>Temporary Password</span>} />
      <Formik
        initialValues={{
          email: email,
          oldPassword: "",
          password: "",
          confirmPassword: "",
        }}
        onSubmit={(value) => {
          if (value.password === value.confirmPassword) {
            resetPass(value);
          }
        }}
        validationSchema={resetPassFormValidations}
      >
        <Form>
          <div className="flex flex-col gap-8 pb-6">
            <InputField name="email" type="email" disabled={true} />
            <div className="flex flex-col gap-1">
              <InputField
                name="oldPassword"
                label="Enter Temporary Password"
                type="password"
              />
              <ErrorMessage
                name={"oldPassword"}
                component="div"
                className="mt-1 text-sm text-red-600"
              />
            </div>
            <div className="flex flex-col gap-1">
              <InputField
                name="password"
                label="Enter New Password"
                type="password"
              />
              <ErrorMessage
                name={"password"}
                component="div"
                className="mt-1 text-sm text-red-600"
              />
            </div>
            <div className="flex flex-col gap-1">
              <InputField
                name="confirmPassword"
                label="Confirm New Password"
                type="password"
              />
              <ErrorMessage
                name={"confirmPassword"}
                component="div"
                className="mt-1 text-sm text-red-600"
              />
            </div>
            <Button
              kind={BUTTON_KIND.secondary}
              size={BUTTON_SIZE.base}
              type="submit"
              loading={isLoading}
            >
              Create Account
            </Button>
          </div>
        </Form>
      </Formik>
    </div>
  );
};
