import React, { useState, useEffect } from "react";

export const SlidingText: React.FC<{ texts: string[] }> = ({
  texts = [""],
}) => {
  const [currentTextIndex, setCurrentTextIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTextIndex((prevIndex) => (prevIndex + 1) % texts.length);
    }, 3000);

    return () => clearInterval(intervalId);
  }, [texts.length]);

  const handleDotClick = (index: number) => {
    setCurrentTextIndex(index);
  };

  return (
    <div className="flex h-16 max-w-fit flex-col gap-4 overflow-hidden">
      <div
        className="flex snap-x snap-center"
        style={{
          width: `${texts.length * 100}%`,
          transform: `translateX(-${(currentTextIndex * 100) / texts.length}%)`,
          transition: "transform 0.5s ease",
        }}
      >
        {texts.map((text, index) => (
          <div key={index} className="flex w-full items-center justify-center">
            <p className="text-center">{text}</p>
          </div>
        ))}
      </div>
      <div className="flex w-full items-center justify-center pb-2">
        {texts.map((_, index) => (
          <div
            key={index}
            className={`mx-1 cursor-pointer rounded-full ${
              index === currentTextIndex
                ? "h-2.5 w-2.5 bg-white"
                : "h-2 w-2 bg-white/20"
            } transition-all duration-500 ease-in-out`}
            onClick={() => handleDotClick(index)}
          />
        ))}
      </div>
    </div>
  );
};
