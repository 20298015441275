import clsx from "clsx";
import { ErrorMessage, Form, Formik, FormikProps } from "formik";
import { map } from "lodash";
import { useState } from "react";
import * as Yup from "yup";
import { renderField } from "../../utils/renderField";
import { BUTTON_KIND, BUTTON_SIZE } from "../Button";
import { CardSection } from "../CardSection";
import { SSOData } from "../Devices/mockData";
import { sections } from "./SSOForm";

const AddSSO = ({
  onSubmit,

  isTestSuccess,
  setIsTestSuccess,
  data,
}: {
  onSubmit: (data: any) => void;
  data: typeof SSOData;
  isTestSuccess: boolean;
  setIsTestSuccess: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [isTest, setIsTest] = useState(true);

  const [initialValues, setInitialValues] = useState(
    data
      ? {
          domain: data.domain,
          idpEntity: data.idpEntity,
          requireSSO: data.requireSSO,
          metadataFile: new File([data.metadataXml], "metadata.xml", {
            type: "text/plain",
          }),
          ssoUrl: data.loginUrl,
        }
      : {
          domain: "",
          idpEntity: "",
          requireSSO: true,
          metadataFile: "",
          ssoUrl: "",
        }
  );

  const InputComponents = (
    field: any,
    props: FormikProps<typeof initialValues>
  ) => {
    switch (field.type) {
      case "boolean":
        return (
          <div className="flex flex-col justify-start gap-y-2">
            <div className="flex flex-row content-center items-center gap-2 text-center align-middle">
              {renderField(field, props, () => false)}
              <p className=" text-lg font-medium capitalize text-gray-700">
                Require SSO
              </p>
            </div>
            <p className="text-sm tracking-wide">
              Requiring SSO will force other corporate users to be authenticated
              through SSO, but the root account will always remain enabled for
              access
            </p>
          </div>
        );
      default:
        return renderField(field, props, () => false);
    }
  };

  const validationSchema = Yup.object().shape({
    domain: Yup.string().required("Domain name is required"),
    ssoUrl: Yup.string().required("Login URL is required"),
    idpEntity: Yup.string().required("IDP Entity is required"),
    requireSSO: Yup.boolean(),
    // requireSSO: Yup.boolean().isTrue().required("Required SSO for auth"),
    metadataFile: Yup.mixed().required("File is required"),
  });

  const onSubmitForm = (formValues: typeof initialValues) => {
    onSubmit({ ...formValues, isTest: !isTestSuccess });
  };

  return (
    <div className="">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmitForm}
        enableReinitialize={true}
      >
        {(props) => (
          <Form>
            <div className="flex flex-col space-y-6">
              <CardSection
                title={"Single Sign On"}
                headerActionItemsItems={[
                  {
                    children: isTestSuccess ? "Edit " : "Test Configuration",
                    kind: BUTTON_KIND.secondary,
                    size: BUTTON_SIZE.sm,
                    className: "md:!px-12",
                    disabled: false,
                    type: "button",
                    onClick: () => {
                      if (isTestSuccess) {
                        setIsTestSuccess(false);
                      } else {
                        props.submitForm();
                      }
                    },
                  },
                  {
                    children: "Save Configuration",
                    kind: BUTTON_KIND.secondary,
                    size: BUTTON_SIZE.sm,
                    className: "md:!px-12",
                    submit: true,
                    loading: false,
                    disabled: !isTestSuccess,
                  },
                ]}
              >
                <div className="space-y-8">
                  {map(sections, ({ sections, title }, key) => (
                    <div key={key}>
                      {title && title.length > 0 && (
                        <p className="mb-4 text-xl font-semibold capitalize text-gray-900">
                          {title}
                        </p>
                      )}
                      <div className="space-y-2 ">
                        {map(
                          sections,
                          (
                            {
                              children,
                              title,
                              // renderHeader,
                              className = "grid-cols-1",
                            },
                            key
                          ) => (
                            <div key={key}>
                              {title && title.length > 0 && (
                                <p className="mb-4 text-lg font-medium capitalize text-gray-700">
                                  {title}
                                </p>
                              )}
                              <div
                                className={clsx(
                                  "w-full max-w-xl gap-4 pl-3",
                                  className
                                )}
                                key={key}
                              >
                                {map(children, (value, key) => {
                                  return (
                                    <div key={key}>
                                      <label className="mb-2 block text-sm font-medium text-gray-700">
                                        {value.label}
                                      </label>
                                      {map(value.fields, (field, fieldKey) => (
                                        <div
                                          key={fieldKey}
                                          className={`mb-4 ${field.type !== "textarea" ? "max-w-196" : "w-full"}`}
                                        >
                                          {InputComponents(
                                            {
                                              ...field,
                                              disabled: isTestSuccess,
                                            },
                                            props
                                          )}
                                          <ErrorMessage
                                            name={field.name}
                                            component="div"
                                            className="mt-1 text-sm text-red-600"
                                          />
                                        </div>
                                      ))}
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          )
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </CardSection>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default AddSSO;
