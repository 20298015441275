import { useEffect, useRef, useState } from "react";

const useOverflow = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [isOverflow, setIsOverflow] = useState(false);

  useEffect(() => {
    const checkOverflow = () => {
      if (containerRef.current) {
        setIsOverflow(
          containerRef.current.scrollWidth > containerRef.current.clientWidth,
        );
      }
    };

    checkOverflow(); // Initial check
    window.addEventListener("resize", checkOverflow); // Recheck on window resize

    return () => {
      window.removeEventListener("resize", checkOverflow);
    };
  }, []);

  return { containerRef, isOverflow };
};

export default useOverflow;
