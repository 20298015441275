export const endpoints = {
  auth: {
    sso: (email: string) => `/api/v1/login?email=${email}`,
    userinfo: `/api/v1/userinfo`,
    resetPassword: `/api/v1/tenants/reset`,
    token: `/api/v1/token`,
    forgotPassword: "/api/v1/tenants/forgot-password",
  },
  device: {
    get: (siteId: string, page = 1, size = 10) =>
      `/api/v1/sites/${siteId}/devices?page=${page}&size=${size}`,
    getById: (deviceId: string) => `/api/v1/devices/${deviceId}`,
    delete: (deviceId: string) => `/api/v1/devices/${deviceId}`,
    update: (deviceId: string) => `/api/v1/devices/${deviceId}`,
  },
  site: {
    list: (tenantId: string, page = 1, size = 10) =>
      `/api/v1/tenants/${tenantId}/sites?page=${page}&size=${size}`,
    get: (siteId: string) => `/api/v1/sites/${siteId}`,
  },
  metrics: {
    get: (deviceId: string, startTime: string, endTime: string) =>
      `/api/v1/devices/${deviceId}/metrics?startTime=${startTime}&endTime=${endTime}`,
  },
  software: {
    list: `/api/v1/software`,
    get: (fileName: string) => `/api/v1/software/`.concat(fileName),
  },
  tenants: {
    get: (tenantId: string) => `/api/v1/tenants/`.concat(tenantId),
  },
  sso: {
    create: "/api/v1/sso-profile",
    update: (tenantId: string) => "/api/v1/sso-profile/".concat(tenantId),
    get: (tenantId: string) => "/api/v1/sso-profile/".concat(tenantId),
  },
  siem: {
    add: "/api/v1/siem-profile",
    update: (tenantId: string) => "/api/v1/siem-profile/".concat(tenantId),
    get: (tenantId: string) => "/api/v1/siem-profile/".concat(tenantId),
  },
  activityLogs: {
    get: (siteId: string, page = 1, size = 10) =>
      `/api/v1/activity-logs?siteId=${siteId}&page=${page}&size=${size}`,
  },
  auditLogs: {
    get: (page = 1, size = 10) =>
      `/api/v1/audit-logs?page=${page}&size=${size}`,
  },
  dashboard: {
    get: (siteId: string, startTime: string, endTime: string) =>
      `/api/v1/metrics/dashboard?siteId=${siteId}&startTime=${startTime}&endTime=${endTime}`,
  },
  securityPolicy: {
    all: (tenantId: string) => `/api/v1/policies?tenantId=${tenantId}`,
    get: (policyId: string) => `/api/v1/policies/${policyId}`,
    names: (tenantId: string) => `/api/v1/tenants/${tenantId}/policies/names`,
  },
};
