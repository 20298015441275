import { useEffect, useState } from "react";
import { useSite } from "../context/SiteProvider";
import { AssetsScreen, useFetchDevices } from "../stories/Devices";

export default function DevicesScreen() {
  const queryParams = new URLSearchParams(window.location.search);
  const initialPage = parseInt(queryParams.get("page")?.toString() ?? "1");
  const [currentPage, setCurrentPage] = useState(initialPage);
  const size = 10;
  const { selectedSite } = useSite();

  const { data, error, loading, mutate } = useFetchDevices(
    currentPage,
    size,
    selectedSite
  );
  useEffect(() => {
    setCurrentPage(initialPage);
  }, [initialPage]);

  return (
    <AssetsScreen
      data={data?.data}
      refetch={mutate}
      isLoading={loading}
      currentPage={currentPage}
      totalPages={data?.hasNext ? currentPage + 1 : currentPage}
      setCurrentPage={setCurrentPage}
      size={size}
      hasNext={data?.hasNext}
    />
  );
}
