import { useEffect, useState } from "react";
import { useAuth } from "../context/AuthProvider";
import { Auth } from "../hooks/auth";
import { endpoints } from "../services/api";
import AddSSO from "../stories/SSO/AddSSO";
import { NetWrapper } from "../utils/netwrapper";
import { notifyError, notifySuccess } from "../utils/notify";
import { useSWR } from "../hooks/useSWR";
import { Loader, LOADER_SIZE } from "../stories/Loader";

export default function SSOScreen() {
  const { user, auth } = useAuth();
  const {
    data: ssoData,
    loading,
    mutate,
  } = useSWR<{ data: any }>(
    user?.tenantId ? endpoints.sso.get(user?.tenantId) : null
  );
  const [isTestSuccess, setIsTestSuccess] = useState(Boolean(ssoData?.data));

  const onSubmit = async (formValues: {
    domain: string;
    idpEntity: string;
    requireSSO: boolean;
    metadataFile: File;
    ssoUrl: string;
  }) => {
    if (user?.tenantId && auth) {
      let body: any = null;
      let response: { data: any; error: any } = { data: null, error: null };
      console.log(!isTestSuccess, Boolean(isTestSuccess && !ssoData));

      if (!isTestSuccess || (isTestSuccess && !ssoData.data)) {
        body = new FormData();
        body.append("tenantId", user?.tenantId); // s
        body.append(
          "metadataXml",
          formValues.metadataFile,
          formValues.metadataFile.name
        );
        body.append("domain", formValues.domain);
        body.append("loginUrl", formValues.ssoUrl);
        body.append("idpEntity", formValues.idpEntity);
        body.append("requireSSO", formValues.requireSSO.toString());
        body.append("isTest", Boolean(!isTestSuccess).toString());
        response = await NetWrapper(
          ssoData.data && isTestSuccess
            ? endpoints.sso.update(user.tenantId)
            : endpoints.sso.create,
          {
            method: "post",
            body: body,
            headers: {
              // "content-type": "multipart/form-data",
              Authorization:
                auth.authType === "sso"
                  ? "Bearer ".concat(auth.token)
                  : "Basic ".concat(auth.token),
            },
          },
          true
        );
      } else {
        // const body = new FormData();
        body = {
          tenantId: user?.tenantId,
          metadataXml: btoa(await formValues.metadataFile.text()),

          domain: formValues.domain,
          loginUrl: formValues.ssoUrl,
          idpEntity: formValues.idpEntity,
          requireSSO: formValues.requireSSO,
          isTest: Boolean(!isTestSuccess),
        };
        response = await NetWrapper(
          ssoData.data && isTestSuccess
            ? endpoints.sso.update(user.tenantId)
            : endpoints.sso.create,
          {
            method: "put",
            body: JSON.stringify(body),
          },
          true
        );
      }

      // body.append("metadataXml", formValues.metadataFile, formValues.metadataFile.name);

      if (response.data) {
        if (!isTestSuccess) {
          notifySuccess(
            "The Single Sign On (SSO) configuration was tested and is working correctly"
          );
        } else {
          mutate();
          notifySuccess(
            "The Single Sign On (SSO) profile was updated successfully"
          );
        }
        setIsTestSuccess(true);
      } else if (response.error) {
        notifyError(
          response.error.message ||
            response.error?.data?.message ||
            response.error.data ||
            "Something went wrong"
        );
      }
    }
  };

  useEffect(() => {
    if (!loading) {
      setIsTestSuccess(Boolean(ssoData?.data));
    }
  }, [ssoData?.data, loading]);

  return !loading ? (
    <AddSSO
      onSubmit={onSubmit}
      isTestSuccess={isTestSuccess}
      setIsTestSuccess={setIsTestSuccess}
      data={ssoData?.data || null}
    />
  ) : (
    <div className="m-6 flex h-[calc(100vh-144px)] flex-col items-center justify-center gap-4 rounded-lg border-0 bg-white p-6">
      <Loader size={LOADER_SIZE.lg} className="text-theme-purple" />
    </div>
  );
}
