import { Check, Copy } from "@phosphor-icons/react";
import { useState } from "react";

export interface CopyButtonProps {
  token: string;
  label?: string;
}

export default function CopyButton({ token, label }: CopyButtonProps) {
  const [copied, setCopied] = useState(false);

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(token);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    } catch (err) {
      console.error("Failed to copy: ", err);
    }
  };

  const truncatedToken = token.length > 10 ? token.slice(0, 14) + "..." : token;

  return (
    <div className="flex items-center gap-x-2">
      {label && <p className="text-sm font-semibold">{label}:</p>}
      <div
        className="group relative flex h-10 w-48 cursor-pointer items-center justify-between overflow-hidden rounded-md bg-gray-100"
        onClick={handleCopy}
      >
        <div className="truncate pl-3 pr-1 font-mono text-sm">
          {truncatedToken}
        </div>
        <div className="flex h-full w-8 items-center justify-center bg-gray-200 transition-colors group-hover:bg-gray-300">
          {copied ? (
            <Check className="h-4 w-4 text-theme-purple transition-all duration-300 ease-in-out" />
          ) : (
            <Copy className="h-4 w-4 text-gray-600 transition-all duration-300 ease-in-out" />
          )}
        </div>

        <div
          className={`absolute inset-0 flex items-center justify-center bg-theme-purple text-sm font-semibold text-white transition-transform duration-300 ease-in-out ${copied ? "translate-x-0" : "translate-x-full"}`}
        >
          Copied!
        </div>
      </div>
    </div>
  );
}
