import { Dropdown } from "..";
import { ISite } from "../Sites";
import { useState } from "react";
import { ModalType } from "../ActionModal";
import ActionModal from "../ActionModal/ActionModal";
import { useSite } from "../../context/SiteProvider";

const ChangeSiteDropdown = ({ sites }: { sites: ISite[] }) => {
  const { selectedSite, setSelectedSite } = useSite();

  const [showModal, setShowModal] = useState(false);
  const [nextSite, setNextSite] = useState<ISite | null>(null);

  const handleCancel = () => {
    setShowModal(false);
  };
  const handleConfirm = () => {
    if (nextSite) {
      setSelectedSite(nextSite);
      setShowModal(false);
    }
  };

  return (
    <div className="z-50">
      <p className="select-none font-semibold">Site</p>
      <Dropdown
        label={
          selectedSite && selectedSite.siteName
            ? "Default Site".concat(`: ${selectedSite?.siteName}`)
            : "No sites available"
        }
        customClass="!bg-theme-purple/5 !border-theme-gray"
        initialValue={
          selectedSite
            ? {
                label: selectedSite.siteName,
                value: selectedSite.uuid ?? "",
              }
            : null
        }
        onChange={(e: { label: string; value: string }) => {
          const newSite = sites?.find((site) => site.uuid === e.value);
          if (newSite && selectedSite?.siteName !== newSite.siteName) {
            setNextSite(newSite ?? undefined);
            setShowModal(true);
          }
        }}
        options={
          sites?.map((site) => ({
            label: site.siteName,
            value: site.uuid ?? "",
          })) || []
        }
        disabled={sites && sites.length === 0 ? true : false}
      />
      <ActionModal
        modalType={ModalType.CHANGE_SITE}
        isOpen={showModal}
        setIsOpen={setShowModal}
        onConfirm={handleConfirm}
        onCancel={handleCancel}
        entityName={selectedSite?.siteName}
        entityName2={nextSite?.siteName}
      />
    </div>
  );
};

export default ChangeSiteDropdown;
