import { useState } from "react";
import { useAuth } from "../context/AuthProvider";
import { useSWR } from "../hooks/useSWR";
import { endpoints } from "../services/api";
import { Loader, LOADER_SIZE } from "../stories/Loader";
import AddSIEMProfile from "../stories/SIEM/AddSIEMProfile";
import { NetWrapper } from "../utils/netwrapper";
import { notifyError, notifySuccess } from "../utils/notify";

export default function SIEMSettingsScreen() {
  const { user } = useAuth();
  const { data, loading, mutate } = useSWR<{ data: any }>(
    user?.tenantId ? endpoints.siem.get(user?.tenantId) : null
  );
  const [edititing, setIsEditing] = useState(Boolean(data?.data));

  const onSubmit = async (formValues: {
    ip: string;
    port: string;
    tokenOrKey: string;
    tag: string;
    logFormat: string;
    protocol: string;
  }) => {
    if (user?.tenantId) {
      const body = {
        tenantId: user.tenantId,
        ip: formValues.ip,
        port: formValues.port,
        tokenOrKey: formValues.tokenOrKey,
        tag: formValues.tag,
        logFormat: formValues.logFormat,
        protocol: formValues.protocol,
      };
      const { data: status, error } = await NetWrapper(
        data?.data.id
          ? endpoints.siem.update(user.tenantId)
          : endpoints.siem.add,
        {
          method: data?.data.id ? "PUT" : "POST",
          body: JSON.stringify(body),
        }
      );
      if (status) {
        notifySuccess("The SIEM server settings were updated successfully");
        mutate();
        setIsEditing(false);
      } else if (error) {
        notifyError(
          error.message ||
            error?.data?.message ||
            error.data ||
            "Something went wrong"
        );
      }
    }
  };

  return !loading ? (
    <AddSIEMProfile
      onSubmit={onSubmit}
      edititing={edititing}
      setIsEditing={setIsEditing}
      data={data?.data || null}
    />
  ) : (
    <div className="m-6 flex h-[calc(100vh-144px)] flex-col items-center justify-center gap-4 rounded-lg border-0 bg-white p-6">
      <Loader size={LOADER_SIZE.lg} className="text-theme-purple" />
    </div>
  );
}
