import {
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  SortingState,
  Updater,
  useReactTable,
} from "@tanstack/react-table";
import clsx from "clsx";
import { useState } from "react";
import useOverflow from "../../hooks/useOverflow";
import { Icon, ICON_NAME } from "../Icon";
import { PaginationButton } from "../Pagination";
import "./Table.css";

export interface ITableProps<T> {
  data: T[];
  columns: any;
  perPage?: number;
  headerComponent?: (columns: any[]) => JSX.Element;
  onClick?: Function;
  emptyTablePlaceholder?: JSX.Element | string;
  cellStyle?: string;
  totalPages?: number;
  hiddenColumns?: string[];
}
export function Table<T>({
  data,
  columns,
  headerComponent,
  perPage = 15,
  onClick,
  emptyTablePlaceholder,
  cellStyle,
  totalPages,
  hiddenColumns,
}: ITableProps<T>): JSX.Element {
  const [sorting, setSorting] = useState<SortingState>([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0, //initial page index
    pageSize: perPage, //default page size
  });
  const { containerRef, isOverflow } = useOverflow();

  const table = useReactTable({
    columns: columns,
    data: data ?? [],
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    state: {
      sorting: sorting,
      pagination: pagination,
    },
    enableColumnResizing: true,
    enableHiding: true,
    initialState: {
      columnVisibility: columns.reduce((acc: any, column: any) => {
        acc[column.id] = !hiddenColumns?.includes(column.id);
        return acc;
      }, {}),
    },
    columnResizeMode: "onChange",
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
  });

  return (
    <div className="min-h-[inherit] flex h-[95%] flex-col gap-6">
      {headerComponent && headerComponent(table.getAllLeafColumns())}

      {Array.isArray(data) && data.length > 0 ? (
        <>
          <div
            ref={containerRef}
            className={`flex-1 table-scroll scroll h-full  w-full ${isOverflow ? "overflow-x-auto" : "overflow-x-hidden"}`}
          >
            <table className="h-fit w-full">
              <thead>
                {table.getHeaderGroups().map((headerGroup) => (
                  <tr key={headerGroup.id}>
                    {headerGroup.headers.map((header, idx) => (
                      <th
                        onClick={header.column.getToggleSortingHandler()}
                        key={`${header.id}_${idx}`}
                        className="h-11 max-w-56 select-none border-r-[1.5px] border-white bg-theme-gray-300 p-2.5 text-left text-xs font-semibold uppercase leading-4 text-theme-gray"
                      >
                        <div
                          className={clsx(
                            header.column.getCanSort()
                              ? "cursor-pointer"
                              : "cursor-auto",
                            "flex items-center justify-between gap-4 overflow-clip text-nowrap"
                          )}
                          title={
                            header.column.getCanSort()
                              ? header.column.getNextSortingOrder() === "asc"
                                ? "Sort ascending"
                                : header.column.getNextSortingOrder() === "desc"
                                  ? "Sort descending"
                                  : "Clear sort"
                              : undefined
                          }
                        >
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                          <span className="w-3.5">
                            {{
                              asc: (
                                <Icon
                                  name={ICON_NAME.caretUpDown}
                                  size="14px"
                                />
                              ),
                              desc: (
                                <Icon
                                  name={ICON_NAME.caretUpDown}
                                  size="14px"
                                />
                              ),
                            }[header.column.getIsSorted() as string] ?? null}
                          </span>
                        </div>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody key={"table-body"} className="table-body">
                {table.getRowModel().rows.map((row) => (
                  <tr key={row.id}>
                    {row.getVisibleCells().map((cell, i) => (
                      <td
                        key={i + cell.id}
                        title={cell.getValue() as string}
                        className={clsx(
                          "max-w-256 h-11 min-w-10 cursor-pointer !overflow-visible truncate text-ellipsis p-2.5 text-sm font-normal capitalize leading-5 text-black",
                          cellStyle ? cellStyle : ""
                        )}
                        onClick={(e) => onClick && onClick(e, row, cell)}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {table?.getPageCount() > 1 && (
            <div className="border-t border-theme-gray-100">
              <PaginationButton
                total={totalPages ?? data?.length / perPage}
                current={pagination?.pageIndex + 1}
                prevLabel="Previous"
                nextLabel="Next"
                perPage={pagination?.pageSize}
                paginationHandler={(updater: Updater<number>) =>
                  setPagination((prev) => ({
                    ...prev,
                    pageIndex:
                      typeof updater === "function"
                        ? updater(prev.pageIndex)
                        : updater - 1,
                  }))
                }
                handleNext={table.nextPage}
                handlePrev={table.previousPage}
                isNextDisabled={!table.getCanNextPage()}
                isPrevDisabled={!table.getCanPreviousPage()}
              />
            </div>
          )}
        </>
      ) : (
        <div className="flex h-full justify-center">
          {emptyTablePlaceholder ?? "No data available"}
        </div>
      )}
    </div>
  );
}
