import clsx from "clsx";
import { Icon, IconProps } from "../Icon";

export enum BADGE_KIND {
  default = "default",
  primary = "primary",
  clean = "clean",
  quarantined = "quarantined",
  isolated = "isolated",
  armor = "armor",
  threat = "threat",
  running = "running",
  stopped = "stopped",
}
export enum BADGE_SIZE {
  default = "default",
  xs = "xs",
  xxs = "xxs",
}

export const badgeStatusKeyMap: { [key: string]: BADGE_KIND } = {
  default: BADGE_KIND.default,
  clean: BADGE_KIND.clean,
  quarantined: BADGE_KIND.quarantined,
  isolated: BADGE_KIND.isolated,
  armor: BADGE_KIND.armor,
};

export interface BadgeProps {
  className?: string;
  kind?: BADGE_KIND | keyof typeof BADGE_KIND;
  size?: BADGE_SIZE | keyof typeof BADGE_SIZE;
  children?: string;
  icon?: IconProps;
  // show enabled or disabled instead of API response values
  isEnabled?: boolean;
  lowercase?: boolean;
}
const getBadgeClasses = (props: BadgeProps) => {
  const { kind, size = "default" } = props;
  const BadgeDefaultClass =
    "group inline-flex space-x-1.5 items-center justify-start px-3 rounded-full font-medium";

  const BadgeKindClasses: { [key in BADGE_KIND]?: string } = {
    default: "bg-gray-50 text-black border border-gray-200",
    primary: "bg-badge-purple-200 text-theme-purple",
    clean: "bg-badge-green-100 text-badge-green-300",
    quarantined: "bg-badge-orange-100 text-badge-orange-200",
    isolated: "bg-badge-red-100 text-badge-red-300",
    armor: "bg-badge-green-200 text-badge-green-400",
    threat: "bg-badge-red-200 text-badge-red-400",
  };
  const BadgeSizeClasses: { [key in BADGE_SIZE]?: string } = {
    default: "py-1 px-3",
    xs: "py-1.5 text-xxs",
    xxs: "py-0.5 text-xxs",
  };

  return clsx([
    BadgeDefaultClass,
    BadgeKindClasses[kind!],
    BadgeSizeClasses[size],
  ]);
};

export function Badge(props: BadgeProps): JSX.Element {
  const {
    className,
    children,
    icon,
    isEnabled,
    size,
    kind,
    lowercase,
    ...rest
  } = props;
  let customKind = kind;
  const customSize = size ? size : "default";
  if (!kind) {
    const status =
      children &&
      children.length > 0 &&
      children?.toLowerCase() in badgeStatusKeyMap
        ? children?.toLowerCase()
        : "default";
    customKind = badgeStatusKeyMap[status];
  }

  const modifiedProps = { ...props, kind: customKind, size: customSize };

  return (
    <div {...rest} className={clsx(getBadgeClasses(modifiedProps), className)}>
      {icon && <Icon {...icon} className="relative h-4 w-4" />}
      {children && children.length > 0 && (
        <p
          className={`text-center text-sm leading-4 ${lowercase ? "lowercase" : "capitalize"}`}
        >
          {isEnabled
            ? children.toLowerCase() === "active"
              ? "Enabled"
              : "Disabled"
            : children}
        </p>
      )}
    </div>
  );
}
