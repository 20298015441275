import Logo from "../assets/logo.png";

export interface LogoWithTitleProps {
  title: String | React.ReactNode;
  description?: String | React.ReactNode;
}

export const LogoWithTitle: React.FC<LogoWithTitleProps> = ({
  title,
  description,
}) => {
  return (
    <div className="flex flex-col items-center gap-4 pb-24 text-center">
      <div>
        <img className="h-full w-full" alt="logo" src={Logo} />
      </div>
      <div className="text-3xl font-semibold text-black">{title}</div>
      {description && description}
    </div>
  );
};
