import { useAuth } from "../../context/AuthProvider";
import { useSWR } from "../../hooks/useSWR";
import { endpoints } from "../../services/api";
import { IRequest, NetWrapper } from "../../utils/netwrapper";

export interface ISite {
  id?: string;
  uuid?: string;
  siteName: string;
  tenantId: string;
  contact: string;
  zipCode: string;
  city: string;
  street: string;
  secPolicy: string;
  firstName: string;
  lastName: string;
  state: string;
  country: string;
  description: string;
  created?: string;
  updated?: string;
  action?: boolean;
}

export function useFetchSites(page = 1, size = 10) {
  const { user } = useAuth();

  // const response = await NetWrapper(url);
  const { data, error, loading, mutate } = useSWR<{
    data: ISite[];
    hasNext: boolean;
  }>(user ? endpoints.site.list(user.tenantId, page, size) : null);
  return { data, error, loading, mutate };
}

export const createSite = async (payload: ISite) => {
  const url = "/api/v1/sites";
  const options: IRequest = {
    method: "POST",
    body: JSON.stringify(payload),
  };

  const { data, error } = await NetWrapper(url, options);
  return { data, error };
};

export const deleteSite = async (id: string) => {
  const url = `/api/v1/sites/${id}`;
  const options: IRequest = {
    method: "DELETE",
  };
  const { data, error } = await NetWrapper(url, options);
  return { data, error };
};

export const getSite = async (id: string) => {
  const url = `/api/v1/sites/${id}`;
  const response = await NetWrapper(url);
  return response;
};

export function useFetchSiteDetails(siteId?: string) {
  const { data, error, loading, mutate } = useSWR<{
    data: ISite;
    hasNext: boolean;
  }>(siteId ? endpoints.site.get(siteId) : null);
  return { data, error, loading, mutate };
}

export const updateSite = async (id: string, payload: ISite) => {
  const url = `/api/v1/sites/${id}`;
  const options: IRequest = {
    method: "PUT",
    body: JSON.stringify(payload),
  };

  const { data, error } = await NetWrapper(url, options);
  return { data, error };
};
