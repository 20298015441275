import { endpoints } from "../services/api";
import { NetWrapper } from "../utils/netwrapper";
import { notifyError } from "../utils/notify";

export interface IAuth {
  token: string;
  refreshToken?: string;
  authType?: "sso" | "root";
}

export interface UserDetails {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
}

export const Auth = () => {
  const setLocal = <T>(key: string, data: T) => {
    try {
      if (typeof window !== "undefined") {
        window.localStorage.setItem(key, JSON.stringify(data));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const deleteLocal = (key: string) => {
    try {
      if (typeof window !== "undefined") {
        window.localStorage.removeItem(key);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getLocal = <T>(key: string): T => {
    try {
      const item = window.localStorage.getItem(key);
      return item ? JSON.parse(item) : {};
    } catch (error) {
      console.log(error);
      return {} as T;
    }
  };

  return {
    login: (auth: IAuth, to = "/dashboard", resetHistory = true) => {
      setLocal("auth", auth);
      if (resetHistory) {
        window.location.replace(to);
        window.history.replaceState(null, "", to);
      }
    },
    logout: (to = "/") => {
      deleteLocal("auth");
      deleteLocal("user");
      window.location.replace(to);
      window.history.replaceState(null, "", to);
    },

    setAuth: (auth: IAuth) => {
      setLocal("auth", auth);
    },
    setUser: (user: UserDetails) => {
      setLocal("user", user);
    },
    auth: getLocal("auth") as IAuth,

    user: getLocal("user") as UserDetails,
  };
};

export const refetchToken = (refreshToken: string) => {
  return NetWrapper<{}>(
    endpoints.auth.token,
    {
      method: "POST",
      body: JSON.stringify({
        refreshToken: refreshToken,
      }),
      headers: {
        "Content-Type": "Application/json",
      },
    },
    false
  )
    .then((resp) => resp.data)
    .catch((error: any) => {
      console.log(error);
      notifyError("Something went wrong");
    });
};
