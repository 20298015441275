import { createColumnHelper, Updater } from "@tanstack/react-table";
import { ICON_NAME, Icon } from "../Icon";
import { Table } from "../Table";
import { MenuDropdown } from "../MenuDropdown";
import { TableHeader } from "../TableHeader";
import { useNavigate } from "react-router-dom";
import ActionModal from "../ActionModal/ActionModal";
import { ModalType } from "../ActionModal";
import { useState } from "react";
import { notifyError, notifySuccess } from "../../utils/notify";
import { endpoints } from "../../services/api";
import { IRequest } from "../../utils/net";
import { NetWrapper } from "../../utils/netwrapper";
import { formatDateToUS, processString } from "../../utils/helper";
import { Loader, LOADER_SIZE } from "../Loader";
import { Device } from "./api";
import { PaginationButton } from "../Pagination";
import { Placeholder } from "../Placeholder";

const columnHelper = createColumnHelper<Device>();

export const AssetsScreen = ({
  data,
  refetch,
  isLoading,
  currentPage,
  totalPages,
  size,
  setCurrentPage,
  hasNext,
}: {
  data: Device[];
  refetch?: any;
  isLoading: boolean;
  currentPage: number;
  totalPages: number;
  size: number;
  setCurrentPage: (page: number) => void;
  hasNext: boolean;
}) => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [currentDevice, setCurrentDevice] = useState<{
    id: string;
    name: string;
    ip: string;
  } | null>(null);
  const [currentModalType, setCurrentModalType] = useState<
    ModalType.DEREGISTER_DEVICE | ModalType.MARK_CLEAN | null
  >(null);
  const [modalLoading, setModalLoading] = useState(false);
  const handleOpenModal = (
    modalType: ModalType.DEREGISTER_DEVICE | ModalType.MARK_CLEAN,
    device: { id: string; name: string; ip: string }
  ) => {
    setCurrentModalType(modalType);
    setShowModal(true);
    setCurrentDevice(device);
  };

  const deleteDevice = async (deviceId: string) => {
    const url = endpoints.device.delete(deviceId);
    const options: IRequest = {
      method: "DELETE",
    };
    const { data, error } = await NetWrapper(url, options);
    return { data, error };
  };

  const cleanDevice = async (deviceId: string) => {
    const url = endpoints.device.delete(deviceId);
    const options: IRequest = {
      method: "PUT",
      body: JSON.stringify({
        systemStatus: "Clean",
      }),
    };

    const { data, error } = await NetWrapper(url, options);
    return { data, error };
  };

  const handleConfirm = async () => {
    setModalLoading(true);
    if (currentDevice && currentDevice.id) {
      if (currentModalType === ModalType.MARK_CLEAN) {
        const { error } = await cleanDevice(currentDevice.id);
        if (error) {
          notifyError(error.toString());
        } else {
          notifySuccess(`Device ${currentDevice.name} was marked as clean`);
        }
      } else {
        await handleDeregisterDevice(currentDevice?.id, currentDevice.name);
      }
      setShowModal(false);
      setCurrentDevice(null);
    }
    refetch();
    setModalLoading(false);
  };

  const handleDeregisterDevice = async (deviceId: string, name: string) => {
    const { error } = await deleteDevice(deviceId);
    if (error) {
      notifyError(error.toString());
    } else {
      notifySuccess(`Device ${name} was deregistered successfully. `);
      // mutate(); // Re-fetch the site data after deletion
    }
  };

  const handleCancel = () => {
    setShowModal(false);
    setTimeout(() => {
      setCurrentDevice(null);
    }, 300);
  };

  const updateQueryParams = (pageNumber: number) => {
    navigate(`?page=${pageNumber}`);
  };

  const handlePageChange = (updater: Updater<number>) => {
    const nextPage =
      typeof updater === "function" ? updater(currentPage) : updater;
    setCurrentPage(nextPage);
    updateQueryParams(nextPage);
  };

  const handleNextPage = () => {
    // if (currentPage < totalPages) {
    const nextPage = currentPage + 1;
    setCurrentPage(nextPage);
    updateQueryParams(nextPage);
    // }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      const prevPage = currentPage - 1;
      setCurrentPage(prevPage);
      updateQueryParams(prevPage);
    }
  };

  const columns = [
    columnHelper.accessor("deviceType", {
      header: () => "Type",
      id: "deviceTypeIcon",
      cell: (info) => (
        <div className="relative flex min-w-20 items-center justify-center">
          {info.row
            .getValue<string>("systemStatus")
            .includes("quarantined") && (
            <div className="absolute  left-4 flex h-[6px]  w-[6px] items-center justify-center rounded-full border-none bg-indicator-red-200"></div>
          )}
          <Icon
            className="h-6 w-6 self-center text-center hover:cursor-pointer "
            name={
              ICON_NAME[info.getValue().toLowerCase() as keyof typeof ICON_NAME]
            }
          />
        </div>
      ),
      enableSorting: false,
    }),
    columnHelper.accessor((row) => row.hostName, {
      id: "hostName",
      header: () => <span>Host Name</span>,
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor("systemStatus", {
      header: () => "System Status",
      id: "systemStatus",
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor("ip", {
      header: () => <span>IP Address</span>,
      id: "ipAddress",
      cell: (info) => processString(info.getValue()),
    }),
    columnHelper.accessor("deviceType", {
      header: "Device Type",
      id: "deviceType",
    }),
    columnHelper.accessor("osVersion", {
      header: "OS Version",
      id: "osVersion",
    }),
    // columnHelper.accessor("ip", {
    //   header: () => <span>Visits</span>,
    // }),
    // columnHelper.accessor("manufacturer", {
    //   header: () => "Manufacturer",
    //     }),
    columnHelper.accessor("mac", {
      header: () => "MAC Address",
      id: "macAddress",
      cell: (info) => processString(info.getValue()),
    }),
    // columnHelper.accessor("deviceInfo.ransomStatus", {
    //   header: () => "Threats Detected",
    // }),
    // columnHelper.accessor("deviceStatus", {
    //   header: () => "Device State",
    //   cell: (info) => (
    //     <Badge
    //       kind={
    //         info.getValue() === "running" ? BADGE_KIND.clean : BADGE_KIND.isolated
    //       }
    //       lowercase
    //     >
    //       {info.getValue()}
    //     </Badge>
    //   ),
    // }),
    columnHelper.accessor("labels", {
      header: () => "Label",
      id: "label",
      cell: (info) => {
        return <div>{info.getValue()}</div>;
      },
    }),
    // columnHelper.accessor("customerName", {
    //   header: () => "Customer Name",
    // }),
    // columnHelper.accessor("siteName", {
    //   header: () => "Site Name",
    // }),
    columnHelper.accessor("deviceInfo.arxRaVersion", {
      header: () => "ArmorxAI Version",
      id: "armorxAiVersion",
    }),
    columnHelper.accessor("lastActive", {
      header: () => "Last Active",
      id: "lastActive",
      cell: (info) => {
        if (info.getValue()) {
          return formatDateToUS(info.getValue(), false);
        }
        return "Not Available";
      },
    }),
    // columnHelper.accessor("deviceInfo.groupName", {
    //   header: () => "Group",
    // }),
    columnHelper.accessor("deviceInfo.domainOrWorkgroup", {
      header: () => "Domain",
      id: "domain",
      cell: (info) => {
        if (
          !info.getValue() ||
          info.getValue().toLowerCase() === "not_found" ||
          info.getValue().toLowerCase() === "unknown"
        ) {
          return "Not Available";
        }
        return info.getValue();
      },
    }),
    columnHelper.accessor("action", {
      header: () => "Action",
      id: "action",
      enableSorting: false,

      cell: (info) => (
        <div className="z-50 flex items-center justify-center">
          <MenuDropdown
            items={[
              "Mark as Clean",
              "Deregister Device",
              // "Behaviour",
              // "Processes",
              // "Security Policy",
              // "Shutdown",
              // "Restart",
            ]}
            startFrom="right"
            disabledItems={[
              info.row.original?.systemStatus?.toLowerCase() === "clean"
                ? "Mark as Clean"
                : "",
              "Restart",
            ].filter(Boolean)}
            onSelect={(item: string[], e) => {
              const deviceUuid = info.row.original.deviceUuid;
              if (item[0] === "Mark as Clean") {
                handleOpenModal(ModalType.MARK_CLEAN, {
                  id: deviceUuid,
                  name: info.row.original.hostName,
                  ip: info.row.original.ip,
                });
              } else if (item[0] === "Deregister Device") {
                handleOpenModal(ModalType.DEREGISTER_DEVICE, {
                  id: deviceUuid,
                  name: info.row.original.hostName,
                  ip: info.row.original.ip,
                });
              }
            }}
            base={
              <Icon
                className="h-8 w-8 self-center rounded-lg p-1 text-center hover:cursor-pointer hover:bg-theme-gray-250"
                name={ICON_NAME.menuDots}
              />
            }
          />
        </div>
      ),
    }),
  ];

  return (
    <div className="m-4 flex h-auto min-h-[calc(100vh-230px)] flex-col gap-4 rounded-lg border-0 bg-white p-4 md:m-6 md:p-6">
      {isLoading ? (
        <div className="flex h-full flex-1 flex-col items-center justify-center gap-6">
          <Loader
            size={LOADER_SIZE.lg}
            className="h-10 w-10 self-center text-theme-purple"
          />
        </div>
      ) : (
        <Table
          headerComponent={(e) => (
            <TableHeader
              heading="Devices"
              columnData={e}
              showSearchField={false}
            />
          )}
          columns={columns}
          hiddenColumns={["deviceType", "macAddress", "label", "domain", ""]}
          data={data}
          onClick={(e: any, row: any, cell: any) => {
            if (cell.column.id !== "action") {
              if (row.original?.deviceUuid) {
                navigate("/devices/".concat(row.original?.deviceUuid));
              }
            }
          }}
          emptyTablePlaceholder={
            <Placeholder
              label="No devices found"
              description="There are no devices registered on this site"
            />
          }
        />
      )}
      <PaginationButton
        total={totalPages}
        current={currentPage}
        prevLabel="Previous"
        nextLabel="Next"
        // skip: 2,
        perPage={size}
        paginationHandler={handlePageChange}
        handleNext={handleNextPage}
        handlePrev={handlePrevPage}
        isNextDisabled={!hasNext}
        isPrevDisabled={currentPage === 1}
      />
      <ActionModal
        modalType={currentModalType}
        isOpen={showModal}
        loading={modalLoading}
        setIsOpen={setShowModal}
        onConfirm={handleConfirm}
        onCancel={handleCancel}
        entityName={currentDevice?.name || "this device"}
        entityName2={currentDevice?.ip || ""}
        actionButtonTitles={{
          cancel: "Cancel",
          confirm:
            currentModalType === ModalType.MARK_CLEAN
              ? "Confirm"
              : "Deregister",
        }}
      />
    </div>
  );
};
