import { FormField, IForm } from "../../utils/interface";

export const securityTabForm: IForm[] = [
  {
    title: "",
    sections: [
      {
        title: "",
        className: "grid-cols-2 md:grid-cols-3",
        children: [
          {
            label: "",
            fields: [
              {
                label: "Protection Mode",
                name: "protectionMode",
                type: "toggleField",
                options: [
                  { label: "Quarantine", value: "quarantine" },
                  { label: "Inoculate", value: "inoculate" },
                ],
                placeholder:
                  "Quarantine isolates threats without deletion; Inoculate removes threats by deletion or device isolation.",
              },
            ],
          },
        ],
      },

      {
        title: "",
        className: "grid-cols-3 md:grid-cols-4",
        children: [
          {
            label: "",
            fields: [
              {
                label: "Show monitor icon on the tray",
                name: "showMonitorIcon",
                type: "toggle",
                placeholder: "",
              },
            ],
          },
          {
            label: "",
            fields: [
              {
                label: "Logging",
                name: "logging",
                type: "toggle",
                placeholder: "",
              },
            ],
          },
          {
            label: "",
            fields: [
              {
                label: "Show notification",
                name: "fileArmor",
                type: "toggle",
                placeholder: "",
              },
            ],
          },
          {
            label: "",
            fields: [
              {
                label: "Decoy Armor",
                name: "decoyArmor",
                type: "toggle",
                placeholder: "",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    title: "Behaviorial AI",
    sections: [
      {
        title: "",
        className: "grid-cols-3 md:grid-cols-4",
        children: [
          {
            label: "",
            fields: [
              {
                label: "Polyrational",
                name: "polyrational",
                type: "toggle",
                placeholder: "",
              },
            ],
          },
          {
            label: "",
            fields: [
              {
                label: "File Patterns",
                name: "filePatterns",
                type: "toggle",
                placeholder: "",
              },
            ],
          },
          {
            label: "",
            fields: [
              {
                label: "Processes",
                name: "processes",
                type: "toggle",
                placeholder: "",
              },
            ],
          },
        ],
      },
    ],
  },
];

export const editFields: FormField[] = [
  {
    label: "Policy Name",
    type: "text",
    name: "policyName",
    placeholder: "Enter the policy name",
    size: "base",
  },
  {
    label: "Description",
    type: "text",
    name: "description",
    placeholder: "Enter the description",
    size: "base",
  },
  {
    label: "Set as Default",
    type: "toggle",
    name: "applyGlobally",
    size: "base",
    placeholder: "",
  },
  // {
  //   label: "Protection Mode",
  //   type: "toggleField",
  //   name: "protectionMode",
  //   size: "base",
  //   placeholder:
  //     "Quarantine isolates threats without deletion; Inoculate removes threats by deletion or device isolation.",
  //   options: [
  //     { label: "Quarantine", value: "quarantine" },
  //     { label: "Inoculate", value: "inoculate" },
  //   ],
  // },
];

export const monitorDriverFields: FormField[] = [
  {
    label: "Allow Microsoft-Signed Components?",
    type: "questionToggle",
    name: "allowMicrosoftSignedComponents",
    size: "base",
    placeholder:
      "Ensures all trusted Windows components run without interruption.",
  },
  {
    label: "Allow WHQL-Signed Third-Party Drivers?",
    type: "questionToggle",
    name: "allowWHQLSignedThirdPartyDrivers",
    size: "base",
    placeholder:
      "Only allow legitimate drivers that have passed Windows Hardware Quality Labs (WHQL) testing.",
  },
  {
    label: "Block Unsigned and Known Vulnerable Drivers?",
    type: "questionToggle",
    name: "blockUnsignedAndKnownVulnerableDrivers",
    size: "base",
    placeholder:
      "Prevents the execution of untrusted drivers, protecting against exploits like Bring Your Own Vulnerable Driver (BYOVD).",
  },
  {
    label: "Enable Audit Mode?",
    type: "questionToggle",
    name: "enableAuditMode",
    size: "base",
    placeholder: "Monitor without enforcing restrictions.",
  },
];
