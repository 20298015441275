import {
  XCircle,
  EyeSlash,
  Eye,
  CaretDown,
  CaretUp,
  SquaresFour,
  Browser,
  Files,
  ClockCountdown,
  User,
  Vault,
  Columns,
  CaretUpDown,
  ArrowLeft,
  ArrowRight,
  Info,
  Check,
  X,
  MagnifyingGlass,
  Funnel,
  DotsThree,
  DesktopTower,
  HardDrives,
  EnvelopeSimple,
  Lock,
  Bell,
  PencilSimple,
  SignOut,
  FileArrowUp,
  Key,
  DownloadSimple,
  ArrowSquareOut,
  Laptop,
  FileArrowDown,
  ShieldCheck,
  Notebook,
  FileMagnifyingGlass,
  WarningCircle,
  Warning,
} from "@phosphor-icons/react";

export enum ICON_NAME {
  x = "x",
  xCircle = "x-circle",
  eyeSlash = "eye-slash",
  eyeIcon = "eye-icon",
  arrowLeft = "arrow-left",
  arrowRight = "arrow-right",
  chevronDown = "chevron-down",
  squaresFour = "squares-four",
  desktop = "desktop",
  window = "window-icon",
  documentDuplicate = "document-duplicate",
  clock = "clock-icon",
  user = "user-icon",
  vault = "vault-icon",
  columns = "columns-icon",
  caretUpDown = "caret-up-down",
  info = "info-icon",
  check = "check-icon",
  search = "search",
  filter = "filter",
  menuDots = "menu-dots",
  workstation = "workstation",
  server = "server",
  unknown = "unknown",
  email = "email-icon",
  password = "password-icon",
  notification = "bell-icon",
  laptop = "laptop",
  newx = "laptop",
  penIcon = "pen-icon",
  chevronUp = "chevron-up",
  signOut = "sign-out",
  upload = "file-arrow-up",
  key = "key",
  download = "download",
  externalLink = "external-link",
  fileDownload = "file-download",
  shield = "shield-check",
  notebook = "notebook",
  audit = "audit",
  "enterprise server" = "server",
  "warning" = "warning",
  warningTriangle = "warning-triangle",
}

export interface IconProps {
  className?: string;
  name: ICON_NAME | keyof typeof ICON_NAME;
  color?: string;
  size?: number | string;
  weight?: "thin" | "light" | "regular" | "bold" | "fill" | "duotone";
  mirrored?: boolean;
  alt?: string;
  // style?: React.CSSProperties;
}

export function Icon(props: IconProps): JSX.Element {
  const { name } = props;

  switch (name) {
    case "x":
      return <X {...props} />;
    case "x-circle":
      return <XCircle {...props} />;
    case "eye-slash":
      return <EyeSlash {...props} />;
    case "eye-icon":
      return <Eye {...props} />;
    case "chevron-down":
      return <CaretDown {...props} />;
    case "chevron-up":
      return <CaretUp {...props} />;
    case "squares-four":
      return <SquaresFour {...props} />;
    case "laptop":
      return <Laptop {...props} />;
    case "window-icon":
      return <Browser {...props} />;
    case "document-duplicate":
      return <Files {...props} />;
    case "clock-icon":
      return <ClockCountdown {...props} />;
    case "user-icon":
      return <User {...props} />;
    case "vault-icon":
      return <Vault {...props} />;
    case "arrow-left":
      return <ArrowLeft {...props} />;
    case "arrow-right":
      return <ArrowRight {...props} />;
    case "columns-icon":
      return <Columns {...props} />;
    case "caret-up-down":
      return <CaretUpDown {...props} />;
    case "info-icon":
      return <Info {...props} />;
    case "check-icon":
      return <Check {...props} />;
    case "search":
      return <MagnifyingGlass {...props} />;
    case "filter":
      return <Funnel {...props} />;
    case "menu-dots":
      return <DotsThree {...props} />;
    case "desktop":
    case "workstation":
      return <DesktopTower {...props} />;
    case "server":
      return <HardDrives {...props} />;
    case "info":
      return <Info {...props} />;
    case "email-icon":
      return <EnvelopeSimple {...props} />;
    case "password-icon":
      return <Lock {...props} />;
    case "bell-icon":
      return <Bell {...props} />;
    case "pen-icon":
      return <PencilSimple {...props} />;
    case "sign-out":
      return <SignOut {...props} />;
    case "file-arrow-up":
      return <FileArrowUp {...props} />;
    case "key":
      return <Key {...props} />;
    case "download":
      return <DownloadSimple {...props} />;
    case "external-link":
      return <ArrowSquareOut {...props} />;
    case "file-download":
      return <FileArrowDown {...props} />;
    case "shield-check":
      return <ShieldCheck {...props} />;
    case "notebook":
      return <Notebook {...props} />;
    case "audit":
      return <FileMagnifyingGlass {...props} />;
    case "warning":
      return <WarningCircle {...props} />;
    case "warning-triangle":
      return <Warning {...props} />;
    default:
      return <div>{name}</div>;
  }
}
