import { useState, KeyboardEvent, ChangeEvent, useRef, useEffect } from "react";
import { Icon, ICON_NAME } from "../Icon";

const CHAR_LIMIT = 60;

export default function TagsInput({
  label = "Enter tags...",
  inputTags = "",
  onTagsChange,
}: {
  inputTags?: string | null;
  label?: string;
  onTagsChange: (tags: string[]) => void;
}) {
  const [tags, setTags] = useState<string[]>(
    inputTags !== null ? inputTags.split(",") : []
  );
  const [input, setInput] = useState("");
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    onTagsChange(tags);
  }, [tags, onTagsChange]);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setInput(e.target.value);
  };

  const handleInputKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" || e.key === "," || e.key === " ") {
      e.preventDefault();
      addTag();
    } else if (e.key === "Backspace" && input === "" && tags.length > 0) {
      e.preventDefault();
      editLastTag();
    }
  };

  const handleInputBlur = () => {
    addTag();
  };

  const addTag = () => {
    const newTag = input.trim();
    const totalChars = getTotalCharCount(tags) + newTag.length;

    if (newTag !== "" && !tags.includes(newTag) && totalChars <= CHAR_LIMIT) {
      setTags([...tags, newTag]);
      setInput("");
    }
  };

  const editLastTag = () => {
    const lastTag = tags[tags.length - 1];
    setInput(lastTag);
    setTags(tags.slice(0, -1));
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const removeTag = (tagToRemove: string) => {
    setTags(tags.filter((tag) => tag !== tagToRemove));
  };

  const getTotalCharCount = (tags: string[]) => {
    return tags.reduce((total, tag) => total + tag.length, 0);
  };

  return (
    <div className="w-full max-w-48">
      <div className="flex flex-wrap items-start rounded border border-gray-300 p-0.5 focus-within:ring-1 focus-within:ring-theme-gray focus:border-theme-gray break-words supports-[overflow-wrap:anywhere]:[overflow-wrap:anywhere]">
        {tags.map((tag: string) => (
          <span
            key={tag}
            className="m-1 flex items-center rounded border border-gray-300 bg-gray-100 px-2 py-0.5 text-sm text-wrap"
          >
            {tag}
            <button
              type="button"
              onClick={() => removeTag(tag)}
              className="ml-1 focus:outline-none"
            >
              <Icon name={ICON_NAME.x} size={14} />
            </button>
          </span>
        ))}
        <input
          type="text"
          value={input}
          onChange={handleInputChange}
          onBlur={handleInputBlur}
          onKeyDown={handleInputKeyDown}
          ref={inputRef}
          maxLength={CHAR_LIMIT - getTotalCharCount(tags)}
          className="h-8 w-5 min-w-4 flex-grow p-1 focus:outline-none"
          placeholder={tags.length === 0 ? label : ""}
        />
      </div>
    </div>
  );
}
