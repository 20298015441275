export interface AuthLayoutProps {
  leftSection?: React.ReactNode;
  rightSection?: React.ReactNode;
}

export const AuthLayout: React.FC<AuthLayoutProps> = ({
  leftSection,
  rightSection,
}) => {
  return (
    <div className="h-full w-full bg-white bg-opacity-80">
      <div className="flex w-full">
        <div className="hidden h-full min-h-screen w-full basis-2/3 items-center justify-center bg-gradient-to-b from-[#7851BD] to-theme-purple-grad px-10 md:flex">
          {leftSection}
        </div>
        <div className="flex h-screen w-full items-center justify-center bg-white px-10">
          {rightSection}
        </div>
      </div>
    </div>
  );
};
