import React, { createContext, useContext, useState, ReactNode } from "react";
import { ISite } from "../stories/Sites";

interface SiteContextType {
  selectedSite: ISite | null;
  setSelectedSite: (site: ISite) => void;
}

const SiteContext = createContext<SiteContextType | undefined>(undefined);

const SiteProvider = ({ children }: { children: ReactNode }) => {
  const [selectedSite, setSelectedSite] = useState<ISite | null>(null);

  return (
    <SiteContext.Provider value={{ selectedSite, setSelectedSite }}>
      {children}
    </SiteContext.Provider>
  );
};

const useSite = () => {
  const context = useContext(SiteContext);
  if (!context) {
    throw new Error("useSite must be used within a SiteProvider");
  }
  return context;
};

export { SiteProvider, useSite };
