import { Formik, Form, ErrorMessage } from "formik";
import { CardSection } from "../CardSection";
import { BUTTON_KIND, BUTTON_SIZE } from "../Button";
import { map } from "lodash";
import clsx from "clsx";
import * as Yup from "yup";
import { sections } from "./addSitesForm";
import {
  createSite,
  ISite,
  updateSite,
  useFetchSiteDetails,
  useFetchSites,
} from "./api";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { notifyError, notifySuccess } from "../../utils/notify";
import { useAuth } from "../../context/AuthProvider";
import {
  isControlDisabled,
  isControlHidden,
  renderField,
} from "../../utils/renderField";
import { useFetchPolicyList } from "../SecurityPolicy";
import { generateBase64Token } from "../../utils/helper";
import CopyButton from "../CopyButton/CopyButton";

const AddSitesScreen: React.FC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { mutate } = useFetchSites();
  const { user } = useAuth();
  const uuid = searchParams.get("id") ?? null;
  const isEdit = searchParams.get("edit") ?? "";
  const [editing, setIsEditing] = useState<boolean>(
    (!uuid || (isEdit as unknown as boolean)) ?? true
  );
  const [siteToken, setSiteToken] = useState<string | null>(null);
  const { data: siteDetails } = useFetchSiteDetails(uuid ?? undefined);
  const { data: policyList } = useFetchPolicyList({
    tenantId: user?.tenantId,
  });

  const [initialValues, setInitialValues] = useState<ISite>({
    tenantId: user?.tenantId ?? "",
    id: uuid ?? undefined,
    siteName: "",
    contact: "",
    zipCode: "",
    city: "",
    secPolicy: "",
    firstName: "",
    lastName: "",
    street: "",
    state: "",
    country: "",
    description: "",
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (uuid && siteDetails) {
      setInitialValues(siteDetails.data);
    }
  }, [uuid, siteDetails]);

  const onSubmit = async (
    values: ISite,
    { setSubmitting, validateForm }: any
  ) => {
    // Validate the form before submission
    //THIS IS NOT WORKING
    const errors = await validateForm();
    if (Object.keys(errors).length > 0) {
      setSubmitting(false);
      toast.error("Please correct the validation errors.");
      return;
    }
    setIsSubmitting(true);
    const payload: ISite = {
      siteName: values.siteName,
      tenantId: user?.tenantId ?? "",
      uuid: values.uuid,
      contact: values.contact,
      zipCode: values.zipCode,
      city: values.city,
      street: values.street,
      secPolicy: values.secPolicy,
      firstName: values.firstName,
      lastName: values.lastName,
      state: values.state,
      country: values.country,
      description: values.description,
    };
    try {
      if (uuid && editing) {
        const { data, error } = await updateSite(uuid, payload);
        if (error) {
          notifyError(
            "We couldn't update the Site details. Please review the changes and try again."
          );
          return console.log(error);
        }
        notifySuccess(`Site ${payload.siteName} was updated successfully. `);
      } else {
        const { data, error } = await createSite(payload);
        if (error) {
          notifyError(
            "We couldn't add the Site. Please check the information and try again. "
          );
          return console.log(error);
        }
        notifySuccess(`Site ${payload.siteName} was added successfully`);
      }
      await mutate();
      navigate("/sites", { state: { refresh: true } });
    } catch (error) {
      console.error(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const validationSchema = Yup.object().shape({
    siteName: Yup.string().required("Site name is required"),
    contact: Yup.string().required("Phone number is required"),
    zipCode: Yup.string().required("Zip code is required"),
    city: Yup.string().required("City is required"),
    secPolicy: Yup.string().required("Security policy is required"),
    firstName: Yup.string().required("First name is required"),
    lastName: Yup.string().required("Last name is required"),
    street: Yup.string().required("Street is required"),
    state: Yup.string().required("State is required"),
    country: Yup.string().required("Country is required"),
  });

  useEffect(() => {
    const apiURL = process.env.REACT_APP_BASE_URL;
    const port = process.env.REACT_APP_SERVER_PORT;
    if (uuid && user?.tenantId && apiURL && port) {
      const generatedToken = generateBase64Token(
        user?.tenantId,
        uuid,
        apiURL,
        port
      );
      setSiteToken(generatedToken);
    }
  }, [user?.tenantId, uuid]);

  return (
    <div className="">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        enableReinitialize={true}
      >
        {(props) => {
          const handleGlobalChange = (e: any) => {
            const { name, value } = e.target;
            const trimmedValue = value.trimStart().replace(/\s{2,}$/, " ");
            props.setValues({
              ...props.values,
              [name]: trimmedValue,
            });
          };
          return (
            <Form onChange={handleGlobalChange}>
              <div className="flex flex-col space-y-6">
                <CardSection
                  title={"Site Information"}
                  rightSideComponents={
                    <div title="Site Code is required to link a device to this site. Copy and provide this Site Code during installation to associate the device with this site.">
                      {siteToken && (
                        <CopyButton label="Site Code" token={siteToken} />
                      )}
                    </div>
                  }
                  headerActionItemsItems={
                    editing
                      ? [
                          {
                            children: "Cancel",
                            kind: BUTTON_KIND.simple,
                            size: BUTTON_SIZE.sm,
                            className: "md:!px-12",
                            disabled: isSubmitting,
                            onClick: () => {
                              if (uuid) {
                                setIsEditing(false);
                              } else {
                                navigate("/sites");
                              }
                              props.resetForm();
                            },
                          },
                          {
                            children: "Save",
                            kind: BUTTON_KIND.secondary,
                            size: BUTTON_SIZE.sm,
                            className: "md:!px-12",
                            submit: true,
                            loading: isSubmitting,
                          },
                        ]
                      : [
                          {
                            children: "Edit",
                            kind: BUTTON_KIND.simple,
                            size: BUTTON_SIZE.sm,
                            className: "md:!px-12",
                            disabled: isSubmitting,
                            onClick: () => {
                              setIsEditing(true);
                            },
                          },
                        ]
                  }
                >
                  <div className="space-y-4 md:space-y-8">
                    {map(
                      sections({
                        isAddSite: !uuid,
                        policies: policyList ?? [],
                      }),
                      ({ sections, title }, key) => (
                        <div key={key}>
                          {title && title.length > 0 && (
                            <p className="mb-4 text-xl font-semibold capitalize text-gray-900">
                              {title}
                            </p>
                          )}
                          <div className="space-y-4 md:space-y-8">
                            {map(
                              sections,
                              (
                                { children, title, className = "grid-cols-3" },
                                key
                              ) => (
                                <div key={key}>
                                  {title && title.length > 0 && (
                                    <p className="mb-4 text-lg font-medium capitalize text-gray-700">
                                      {title}
                                    </p>
                                  )}
                                  <div
                                    className={clsx(
                                      "grid gap-4 md:gap-6 md:pl-3",
                                      className
                                    )}
                                    key={key}
                                  >
                                    {map(children, (value, key) => {
                                      if (isControlHidden(value, props))
                                        return null;
                                      return (
                                        <div key={key}>
                                          {map(
                                            value.fields,
                                            (field, fieldKey) => (
                                              <div
                                                key={fieldKey}
                                                className={`mb-4 ${field.type !== "textarea" ? "max-w-96" : "w-full"}`}
                                              >
                                                {renderField(
                                                  field,
                                                  props,
                                                  isControlDisabled,
                                                  !editing
                                                )}
                                                <ErrorMessage
                                                  name={field.name}
                                                  component="div"
                                                  className="mt-1 text-sm text-red-600"
                                                />
                                              </div>
                                            )
                                          )}
                                        </div>
                                      );
                                    })}
                                  </div>
                                </div>
                              )
                            )}
                          </div>
                        </div>
                      )
                    )}
                  </div>
                </CardSection>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default AddSitesScreen;
