import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSite } from "../context/SiteProvider";
import { useSWR } from "../hooks/useSWR";
import { endpoints } from "../services/api";
import { AuditLogTable } from "../stories/AuditLogTable";

export interface IAuditLogsItem {
  id: number;
  tenantId: string;
  user: string;
  sourceIp: string;
  action: string;
  resource: string;
  message: string;
  created?: string;
}

export default function AuditScreen() {
  const { selectedSite } = useSite();
  const queryParams = new URLSearchParams(window.location.search);
  const initialPage = parseInt(queryParams.get("page")?.toString() ?? "1");
  const [currentPage, setCurrentPage] = useState(initialPage);
  const size = 10;

  const navigate = useNavigate();

  const updateQueryParams = (pageNumber: number) => {
    navigate(`?page=${pageNumber}`);
  };

  const { data, loading } = useSWR<{
    data: IAuditLogsItem[];
    hasNext: boolean;
  }>(selectedSite?.uuid ? endpoints.auditLogs.get(currentPage, size) : null);

  useEffect(() => {
    setCurrentPage(initialPage);
  }, [initialPage]);

  return (
    <AuditLogTable
      data={data?.data || undefined}
      loading={loading}
      currentPage={currentPage}
      updateQueryParams={updateQueryParams}
      totalPages={data?.hasNext ? currentPage + 1 : currentPage}
      setCurrentPage={setCurrentPage}
      size={size}
      hasNext={data?.hasNext}
    />
  );
}
