import { FormikFormProps } from "formik";
import { renderField } from "../../utils/renderField";
import { BUTTON_KIND, BUTTON_SIZE } from "../Button";
import { Modal } from "../Modal";
import { ModalType } from "./enum";

import { Dispatch, SetStateAction } from "react";
import { editFields } from "../PolicyDetails/policyForm";
import { FormField } from "../../utils/interface";
import { Icon, ICON_NAME } from "../Icon";

interface ActionModalProps {
  modalType: ModalType | null;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  onConfirm: (values?: any) => void;
  onCancel: () => void;
  entityName?: string;
  entityName2?: string;
  dialogClassName?: string;
  formInitValues?: any;
  props?: any;
  actionButtonTitles?: {
    cancel: string;
    confirm: string;
  };
  loading?: boolean;
}

const ActionModal: React.FC<ActionModalProps> = ({
  modalType,
  isOpen,
  setIsOpen,
  onConfirm,
  onCancel,
  entityName,
  entityName2,
  dialogClassName,
  props,
  formInitValues,
  actionButtonTitles,
  loading,
}) => {
  const getTitle = () => {
    switch (modalType) {
      case ModalType.CHANGE_SITE:
        return "Site Change Confirmation";
      case ModalType.DELETE_SITE:
        return "Delete Site";
      case ModalType.SHUT_DOWN_DEVICE:
        return "Shut Down Device";
      case ModalType.DEREGISTER_DEVICE:
        return "Deregister Device";
      case ModalType.ADD_POLICY:
        return "Add Policy";
      case ModalType.ADD_DIRECTORY:
        return "Add Directory";
      case ModalType.EDIT_DIRECTORY:
        return "Update Directory";
      case ModalType.DELETE_DIRECTORY:
        return "Delete Directory";
      case ModalType.ADD_WHITELIST:
        return "Add Exclusions";
      case ModalType.EDIT_WHITELIST:
        return "Update Exclusions";
      case ModalType.DELETE_WHITELIST:
        return "Delete Exclusions";
      case ModalType.DELETE_POLICY:
        return "Delete Policy";
      case ModalType.SET_AS_DEFAULT_POLICY:
        return "Set Policy as Default";
      case ModalType.MARK_CLEAN:
        return "Mark System Status as Clean";
      default:
        return "";
    }
  };

  const getContent = () => {
    switch (modalType) {
      case ModalType.CHANGE_SITE:
        return (
          <div className="mx-6 flex flex-col items-center justify-center gap-y-1">
            <p>You are about to switch to a different site:</p>
            <p className="break-words">
              <span className="font-bold">Current Site</span>: {entityName}
            </p>
            <p className="break-words">
              <span className="font-bold">New Site</span>: {entityName2}
            </p>
            <p>
              You will see its devices and other data. Are you sure you want to
              continue?
            </p>
          </div>
        );

      case ModalType.DELETE_SITE:
        return `Are you sure you want to delete the site ${entityName}? This action cannot be undone.`;
      case ModalType.SHUT_DOWN_DEVICE:
        return `Are you sure you want to shut down the device ${entityName}? Ensure that all data has been backed up.`;
      case ModalType.DEREGISTER_DEVICE:
        return `Are you sure you want to deregister this device? Deregistering will remove it from your account and prevent it from accessing associated services or data. You'll need to re-install ArmorxAI software to register it again. This action cannot be undone. \n\nWould you like to continue?`;
      case ModalType.ADD_POLICY:
        return <AddPolicy props={props} />;
      case ModalType.APPLY_GLOBAL_POLICY:
        return (
          <div className="mx-6 flex flex-col items-center justify-center gap-y-2">
            <div className="h-min w-min rounded-full bg-[#FEE2E2] p-1.5">
              <Icon color="#FF0000" size={24} name={ICON_NAME.warning} />
            </div>
            <p className="font-semibold">
              Are you sure you want to set {entityName} as Default Policy?
            </p>
            <p>
              Setting this as Default will make it as default policy for all
              future Sites and devices associated with those Sites.
            </p>
          </div>
        );
      case ModalType.SET_AS_DEFAULT_POLICY:
        return (
          <div className="mx-6 flex flex-col items-center justify-center gap-y-2">
            <div className="h-min w-min rounded-full bg-[#FEE2E2] p-1.5">
              <Icon color="#FF0000" size={24} name={ICON_NAME.warning} />
            </div>
            <p className="font-semibold">
              Are you sure you want to set {entityName} as Default Policy?
            </p>
            <p>
              Setting this as default will make it the policy selected by
              default for all the Sites.
            </p>
          </div>
        );
      case ModalType.ADD_DIRECTORY:
      case ModalType.EDIT_DIRECTORY:
        return <AddDirectory props={props} />;
      case ModalType.DELETE_DIRECTORY:
        return `Are you sure you want to delete the directory ${entityName}? This action cannot be undone.`;
      case ModalType.ADD_WHITELIST:
      case ModalType.EDIT_WHITELIST:
        return <AddWhitelist props={props} />;
      case ModalType.DELETE_WHITELIST:
        return `Are you sure you want to delete the path ${entityName} from exclusion? This action cannot be undone.`;
      case ModalType.DELETE_POLICY:
        return `Are you sure you want to delete the policy ${entityName}? This action cannot be undone.`;
      case ModalType.MARK_CLEAN:
        return (
          <div className="text-start">
            <p>You are about to clear the status of a quarantined system:</p>
            <br />
            <p className="break-words">
              <span className="font-bold">System Name:</span> {entityName}
            </p>
            <p className="break-words">
              <span className="font-bold">IP Address:</span> {entityName2}
            </p>
            <br />
            <p>Are you sure you want to mark this system as “Clean”?</p>
          </div>
        );
      default:
        return "";
    }
  };

  const getFooterActions = () => [
    {
      children: actionButtonTitles?.cancel ?? "No",
      kind: BUTTON_KIND.simple,
      className: "w-full !py-2",
      size: BUTTON_SIZE.base,
      onClick: onCancel,
    },
    {
      children: actionButtonTitles?.confirm ?? "Yes",
      className: "w-full !py-2",
      kind: BUTTON_KIND.secondary,
      size: BUTTON_SIZE.base,
      onClick: onConfirm,
      loading: loading,
    },
  ];

  return (
    <Modal
      title={getTitle()}
      setIsOpen={setIsOpen}
      isOpen={isOpen}
      footerActionItemsItems={getFooterActions()}
      dialogClassName={dialogClassName}
    >
      <div className=" break-words p-6 text-center text-base text-theme-gray">
        {getContent()}
      </div>
    </Modal>
  );
};

const AddPolicy = ({ props }: { props?: FormikFormProps }) => {
  return (
    <div className="flex flex-col gap-y-6">
      <div className="flex flex-row items-center justify-between">
        <p className="text-left text-sm font-normal text-theme-gray">
          Add a new security policy
        </p>
        <div className="flex items-center gap-2">
          {editFields.map((field, index) => {
            if (field.type === "boolean") {
              return (
                <div
                  className="flex items-center gap-2"
                  key={"policy_form_" + index}
                >
                  {renderField(field, props, () => false)}
                  <p className="text-sm font-medium capitalize text-gray-700">
                    Enforced
                  </p>
                </div>
              );
            }
            return null;
          })}
        </div>
      </div>
      {editFields.map((field, index) => {
        if (field.type !== "boolean") {
          return (
            <div
              className="flex flex-col gap-y-2"
              key={"edit_policy_form_" + index}
            >
              {renderField(field, props, () => false)}
            </div>
          );
        }
        return null;
      })}
    </div>
  );
};
const AddDirectory = ({ props }: { props?: any }) => {
  return (
    <div className="flex flex-col gap-y-6">
      <p className="text-muted-foreground text-left text-sm">
        Please enter the directory you would like to monitor for ransomware
        activity.
      </p>

      <div>
        <label
          htmlFor="path"
          className="block pb-2 text-left text-base font-medium text-gray-700"
        >
          Directory Path
        </label>
        <div className="relative">
          <div className="focus-within:ring-ring flex overflow-hidden rounded-md border p-2 ring-offset-0 focus-within:ring-2 focus-within:ring-theme-gray focus-within:ring-offset-2">
            {props.values.isUserDirectory && (
              <span className="bg-muted text-muted-foreground inline-flex items-center border-r px-3 text-sm">
                %USERPROFILE%\
              </span>
            )}
            <input
              type="text"
              id="path"
              name="path"
              placeholder="Enter directory path"
              className="flex-1 border-0 bg-transparent px-1 text-neutral-950 transition focus-visible:ring-0 focus-visible:ring-offset-0  "
              value={props.values.path}
              onChange={props.handleChange}
              onBlur={props.handleBlur}
            />
          </div>
        </div>
        {props.errors.path && props.touched.path && (
          <p className="mt-2 text-left text-sm text-red-500">
            {props.errors.path}
          </p>
        )}
      </div>
      <div
        className="flex py-4"
        role="group"
        aria-labelledby="path-type-radio-group"
      >
        <label className="mx-1 text-sm font-medium text-gray-700">
          Path Type:
        </label>
        <div className="flex items-center justify-start gap-x-6 px-2">
          <label>
            <input
              type="radio"
              name="isUserDirectory"
              value="false"
              className="mr-2"
              checked={props?.values.isUserDirectory === false}
              onChange={() => props?.setFieldValue("isUserDirectory", false)}
            />
            Absolute
          </label>
          <label>
            <input
              type="radio"
              name="isUserDirectory"
              value="true"
              className="mr-2"
              checked={props?.values.isUserDirectory === true}
              onChange={() => props?.setFieldValue("isUserDirectory", true)}
            />
            Relative
          </label>
        </div>
      </div>
    </div>
  );
};

const AddWhitelist = ({ props }: { props?: any }) => {
  const fields: FormField[] = [
    {
      label: "Whitelist",
      type: "text",
      name: "path",
      placeholder: "Enter the Whitelist",
      size: "base",
    },
  ];

  return (
    <div className="flex flex-col gap-y-6">
      <p className="text-left text-sm font-normal text-theme-gray">
        Please enter the directory you would like to whitelist for ransomware
        activity.
      </p>
      <div>
        {fields.map((field, index) => (
          <div key={"add_whitelist_form_" + index}>
            {renderField(field, props)}
          </div>
        ))}
      </div>
    </div>
  );
};
export default ActionModal;
