export const convertBytesToMB = (bytesPerSecond: number) => {
  return (bytesPerSecond / 1048576).toFixed(2);
};


export function formatNumber(val: number): string {
  if (val >= 1000000) {
    return (val / 1000000).toFixed(1) + "m";
  } else if (val >= 1000) {
    return (val / 1000).toFixed(1) + "k";
  } else {
    return val.toString();
  }
}
