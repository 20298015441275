import { Avatar } from "../ProfileAvatar";
import { CardSection } from "../CardSection";
import { Button } from "../Button";
import { endpoints } from "../../services/api";
import { useSWR } from "../../hooks/useSWR";
import { Loader, LOADER_SIZE } from "../Loader";
import { NetWrapper } from "../../utils/netwrapper";
import { ICON_NAME } from "../Icon";
import { Placeholder } from "../Placeholder";

export interface ISoftware {
  id: number;
  fileName: string;
  fileSize: string;
  version: string;
  extension: string;
  bundleFileHash: string;
  bucketName: string;
}

export const SoftwareListing = ({ heading }: { heading: string }) => {
  const { data, loading } = useSWR<{ data: ISoftware[] }>(
    endpoints.software.list
  );

  const onClickDownload = async (name: string) => {
    if (name) {
      try {
        const response = await NetWrapper<{ data: string }>(
          endpoints.software.get(name)
        );

        if (response && response.data) {
          window.open(response.data.data);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const installers = data?.data.filter(
    (software) => !software.fileName.toLowerCase().includes("uninstaller")
  );
  const uninstallers = data?.data.filter((software) =>
    software.fileName.toLowerCase().includes("uninstaller")
  );

  return (
    <CardSection title="Software Management" subHeader="RansomArmor">
      {loading ? (
        <div className="flex h-full flex-col items-center justify-center gap-6">
          <Loader
            size={LOADER_SIZE.lg}
            className="h-10 w-10 self-center text-theme-purple"
          />
        </div>
      ) : installers && installers.length > 0 ? (
        <div className="space-y-6 md:px-6 md:py-2">
          <section>
            <h2 className="mb-4 text-xl font-semibold text-gray-800">
              Installers
            </h2>
            <div className="space-y-4">
              {installers.map((installer) => (
                <SoftwareCard
                  key={installer.id}
                  software={installer}
                  onDownload={onClickDownload}
                />
              ))}
            </div>
          </section>
          <hr className="border-gray-300" />
          {uninstallers && uninstallers.length > 0 && (
            <section>
              <h2 className="mb-4 text-xl font-semibold text-gray-800">
                Uninstaller
              </h2>
              <div className="space-y-4">
                {uninstallers.map((e) => (
                  <UninstallerCard
                    uninstaller={e}
                    onDownload={onClickDownload}
                  />
                ))}
              </div>
            </section>
          )}
        </div>
      ) : (
        <Placeholder
          label="No Softwares available"
          description="No software versions available at this moment"
        />
      )}
    </CardSection>
  );
};

const SoftwareCard = ({
  software,
  onDownload,
}: {
  software: ISoftware;
  onDownload: (name: string) => {};
}) => {
  return (
    <div
      key={software.id}
      className="flex w-full flex-col items-start space-y-4 rounded-lg border border-gray-300 p-4 sm:flex-row sm:items-center sm:space-x-4 sm:space-y-0"
    >
      <Avatar initials="AX" />
      <div className="flex-grow">
        <h2 className="text-base font-semibold md:text-xl">
          {software.fileName}
        </h2>
        <div className="xs:grid-cols-2 mt-2 grid grid-cols-1 gap-x-4 gap-y-2 md:grid-cols-3">
          <p className="text-muted-foreground text-sm">
            Version: {software.version}
          </p>
          <p className="text-muted-foreground text-sm">
            Size: {software.fileSize}
          </p>
          {/* <p className="text-muted-foreground text-sm">Extension: .exe</p> */}
          <p
            className="text-muted-foreground col-span-full truncate text-sm"
            title={software.bundleFileHash}
          >
            Hash: {software.bundleFileHash}
          </p>
        </div>
      </div>
      <div className="flex w-full flex-row items-center justify-center space-x-4 sm:w-auto sm:flex-col sm:space-x-0 sm:space-y-2">
        <div className="select-none text-sm italic text-theme-orange underline">
          Release Notes
        </div>
        <Button
          kind="secondary"
          onClick={() => onDownload(software.fileName)}
          iconLeft={{
            name: ICON_NAME.fileDownload,
          }}
          size="sm"
        >
          Download
        </Button>
      </div>
    </div>
  );
};

const UninstallerCard = ({
  uninstaller,
  onDownload,
}: {
  uninstaller: ISoftware;
  onDownload: (name: string) => void;
}) => {
  return (
    <div className="flex w-full flex-col items-start space-y-4 rounded-lg border border-gray-300 p-4 sm:flex-row sm:items-center sm:space-x-4 sm:space-y-0">
      <div className="flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-500 text-white">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          className="h-6 w-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
          />
        </svg>
      </div>
      <div className="flex-grow">
        <h3 className="text-lg font-semibold">{uninstaller.fileName}</h3>
        <div className="xs:grid-cols-2 mt-2 grid grid-cols-1 gap-x-4 gap-y-2 md:grid-cols-3">
          <p className="text-muted-foreground text-sm">
            Version: {uninstaller.version}
          </p>
          <p className="text-muted-foreground text-sm">
            Size: {uninstaller.fileSize}
          </p>
          <p
            className="text-muted-foreground col-span-full truncate text-sm"
            title={uninstaller.bundleFileHash}
          >
            Hash: {uninstaller.bundleFileHash}
          </p>
        </div>
      </div>
      <div className="flex w-full flex-row items-center justify-center space-x-4 sm:w-auto sm:flex-col sm:space-x-0 sm:space-y-2">
        <Button
          kind="primary"
          onClick={() => onDownload(uninstaller.fileName)}
          iconLeft={{
            name: ICON_NAME.fileDownload,
          }}
          size="sm"
        >
          Download
        </Button>
      </div>
    </div>
  );
};
