import { useField } from "formik";
import { Switch } from "@headlessui/react";
import clsx from "clsx";
import { InfoTooltip } from "../Tooltip/InfoTooltip";

export interface ToggleProps {
  label: string;
  name: string;
  defaultChecked?: boolean;
  disabled?: boolean;
  placeholder?: string;
}

export function Toggle({
  label,
  name,
  defaultChecked,
  disabled,
  placeholder,
}: ToggleProps) {
  const [field, meta, helpers] = useField({ name, type: "checkbox" });
  const { setValue } = helpers;

  const handleChange = (checked: boolean) => {
    setValue(checked);
  };

  return (
    <div className="flex items-center gap-4">
      <span className="text-sm font-medium">{label}</span>
      <Switch
        disabled={disabled}
        checked={field.value}
        onChange={handleChange}
        defaultChecked={defaultChecked}
        className={clsx(
          field.value ? "bg-theme-purple" : "bg-gray-200",
          disabled ? "opacity-80" : "",
          "relative inline-flex h-[31px] w-[53px] shrink-0 items-center rounded-full"
        )}
      >
        {({ checked }) => (
          <>
            <span className="sr-only">{label}</span>
            <span
              aria-hidden="true"
              className={clsx(
                checked ? "translate-x-6" : "translate-x-0.5",
                "pointer-events-none inline-block h-[27px] w-[27px] transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
              )}
            />
          </>
        )}
      </Switch>
      {placeholder && <InfoTooltip details={placeholder} />}
      {meta.touched && meta.error ? (
        <div className="mt-1 text-sm text-red-600">{meta.error}</div>
      ) : null}
    </div>
  );
}
