import { IPolicy } from "../SecurityPolicy";

interface IAddSites {
  title: string;
  sections: SubSection[];
}

interface SubSection {
  title: string;
  className: string;
  children: Field[];
}

interface Field {
  label: string;
  fields: FormField[];
}

interface FormField {
  name: string;
  type: string;
  placeholder: string;
  disabled?: boolean;
  options?: Option[];
  label?: string;
}

interface Option {
  label: string;
  value: string;
}

export const sections = ({
  isAddSite,
  policies,
}: {
  isAddSite: boolean;
  policies: IPolicy[];
}): IAddSites[] => [
  {
    title: "",
    sections: [
      {
        title: "",
        className: "grid-cols-2 md:grid-cols-3",
        children: [
          ...(isAddSite
            ? []
            : [
                {
                  label: "Site ID",
                  fields: [
                    {
                      name: "uuid",
                      type: "disabled",
                      placeholder: "",
                      disabled: true,
                    },
                  ],
                },
              ]),
          {
            label: "",
            fields: [
              {
                name: "siteName",
                type: "text",
                placeholder: "Site Name",
              },
            ],
          },
          {
            label: "",
            fields: [
              {
                name: "secPolicy",
                type: "select",
                placeholder: "Select Security Policy",
                options: policies.map((policy) => ({
                  label: policy.policyName,
                  value: policy.policyId!,
                })),
              },
            ],
          },
        ],
      },
      {
        title: "Contact Information",
        className: "grid-cols-2 md:grid-cols-3",
        children: [
          {
            label: "",
            fields: [
              {
                name: "firstName",
                type: "text",
                placeholder: "First Name",
              },
            ],
          },
          {
            label: "",
            fields: [
              {
                name: "lastName",
                type: "text",
                placeholder: "Last Name",
              },
            ],
          },
          {
            label: "",
            fields: [
              {
                name: "contact",
                type: "text",
                placeholder: "Phone Number",
              },
            ],
          },
        ],
      },
      {
        title: "Address Information",
        className: "grid-cols-2 md:grid-cols-3",
        children: [
          {
            label: "",
            fields: [
              {
                name: "street",
                type: "text",
                placeholder: "Street",
              },
            ],
          },
          {
            label: "",
            fields: [
              {
                name: "city",
                type: "text",
                placeholder: "City",
              },
            ],
          },
          {
            label: "",
            fields: [
              {
                name: "state",
                type: "text",
                placeholder: "State",
              },
            ],
          },

          {
            label: "",
            fields: [
              {
                name: "zipCode",
                type: "text",
                placeholder: "ZipCode",
              },
            ],
          },
          {
            label: "",
            fields: [
              {
                name: "country",
                type: "text",
                placeholder: "Country",
              },
            ],
          },
        ],
      },
      {
        title: "Other Information",
        className: "grid-cols-1",
        children: [
          {
            label: "",
            fields: [
              {
                name: "description",
                label: "Details",
                type: "textarea",
                placeholder: "Details",
              },
            ],
          },
        ],
      },
    ],
  },
];
