interface IAddSSO {
  title: string;
  sections: SubSection[];
}

interface SubSection {
  title: string;
  className: string;
  children: Field[];
}

interface Field {
  label: string;
  fields: FormField[];
}

interface FormField {
  name: string;
  type: string;
  placeholder: string;
  disabled?: boolean;
  options?: Option[];
  label?: string;
  accept?: string;
}

interface Option {
  label: string;
  value: string;
}

export const sections: IAddSSO[] = [
  {
    title: "",
    sections: [
      {
        title: "Domain name",
        className: "grid-cols-2 ",
        children: [
          {
            label: "",
            fields: [
              {
                name: "domain",
                type: "text",
                placeholder: "Domain",
              },
            ],
          },
        ],
      },
      {
        title: "Identity Provider Entity ID",
        className: "grid-cols-2 ",
        children: [
          {
            label: "",
            fields: [
              {
                name: "idpEntity",
                type: "text",
                placeholder: "Entity Id",
              },
            ],
          },
        ],
      },
      {
        title: "Identity Provider SSO URL",
        className: "grid-cols-2 ",
        children: [
          {
            label: "",
            fields: [
              {
                name: "ssoUrl",
                type: "text",
                placeholder: "SSO Url",
              },
            ],
          },
        ],
      },
      {
        title: "Metadata XML File",
        className: "grid-cols-2 ",
        children: [
          {
            label: "",
            fields: [
              {
                name: "metadataFile",
                type: "file",
                placeholder: "XML file",
                accept: ".xml",
              },
            ],
          },
        ],
      },
      {
        title: "",
        className: "grid-cols-2 ",
        children: [
          {
            label: "",
            fields: [
              {
                name: "requireSSO",
                type: "boolean",
                placeholder: "Require",
              },
            ],
          },
        ],
      },
    ],
  },
];
