import "./button.css";
import clsx from "clsx";
// import { Icon, IconProps } from "./Icon";
import { Loader, LOADER_SIZE } from "../Loader";
import { Icon, IconProps } from "../Icon";

export enum BUTTON_KIND {
  primary = "primary",
  secondary = "secondary",
  plain = "plain",
  plainUnderline = "plainUnderline",
  simplePrimary = "simplePrimary",
  simple = "simple",
  // tertiary = "tertiary",
  // danger = "danger",
  // ghost = "ghost",
  // success = "success",
  // default = "default",
}

export enum BUTTON_SIZE {
  xs = "xs",
  sm = "sm",
  base = "base",
  lg = "lg",
  xl = "xl",
}

const leftIconSizeClasses: { [key in BUTTON_SIZE]: string } = {
  xs: "w-4 h-4",
  sm: "w-5 h-5",
  base: "w-5 h-5",
  lg: "w-6 h-6",
  xl: "w-6 h-6",
};
const rightIconSizeClasses: { [key in BUTTON_SIZE]: string } = {
  xs: "w-4 h-4",
  sm: "w-5 h-5",
  base: "w-5 h-5",
  lg: "w-6 h-6",
  xl: "w-6 h-6",
};
export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  loading?: boolean;
  url?: string;
  external?: boolean;
  disabled?: boolean;
  submit?: boolean;
  className?: string;
  kind?: BUTTON_KIND | keyof typeof BUTTON_KIND;
  size?: BUTTON_SIZE | keyof typeof BUTTON_SIZE;
  children?: string | JSX.Element | JSX.Element[];
  iconRight?: IconProps;
  iconLeft?: IconProps;
  loaderClass?: string;
}

/**
 * Primary UI component for user interaction
 */
const getButtonClasses = (props: ButtonProps) => {
  const { kind, size } = props;

  const ButtonDefaultClass =
    "data-disabled:text-gray-500 group inline-flex items-center justify-center gap-4 font-normal transition duration-150 ease-in-out focus:border focus:outline-none focus:ring-2 focus:ring-offset-2 disabled:text-gray-500 disabled:no-underline";

  const ButtonKindClasses: { [key in BUTTON_KIND]: string } = {
    primary:
      "border border-theme-purple shadow text-theme-purple hover:bg-theme-purple hover:text-white focus:ring-theme-purple",
    secondary:
      "border border-theme-purple shadow bg-theme-purple text-white hover:bg-white hover:text-theme-purple focus:ring-theme-purple disabled:border-purple-300 disabled:text-white disabled:bg-theme-purple disabled:bg-opacity-70 ",
    plain:
      "text-theme-purple hover:underline focus:!ring-0 focus:!ring-offset-0",
    plainUnderline:
      "text-theme-purple underline hover:no-underline focus:!ring-0 focus:!ring-offset-0",
    simplePrimary:
      "border border-theme-purple hover:bg-theme-purple hover:text-white text-theme-purple focus:!ring-0 ",
    simple:
      "border border-theme-gray-250 hover:bg-gray-50 hover:text-gray-800 focus:!ring-theme-gray-250 ",
  };

  const ButtonSizeClasses: { [key in BUTTON_SIZE]: string } = {
    xs: "px-3 py-1.5 text-xs rounded",
    sm: "px-3.5 py-2 text-sm rounded leading-5",
    base: "px-4 py-3 text-base rounded-lg",
    lg: "px-3.5 py-3 text-base rounded-lg",
    xl: "px-6 py-3.5 text-base rounded-lg",
  };

  return clsx([
    ButtonDefaultClass,
    ButtonKindClasses[kind!],
    ButtonSizeClasses[size!],
  ]);
};

export function Button(props: ButtonProps): JSX.Element {
  const {
    loading,
    url,
    external,
    disabled,
    className,
    kind,
    size,
    children,
    iconRight,
    iconLeft,
    loaderClass,
    submit,
    ...rest
  } = props;
  const isAnchor = url && url.length > 0;
  const isInternalLink = url && url.startsWith("/") && !url.startsWith("//");
  const ButtonTag = isAnchor ? "a" : "button";
  const anchorProps = isAnchor
    ? {
        [isInternalLink ? "to" : "href"]: url,
        target: external ? "_blank" : "_self",
      }
    : ({} as any);

  return (
    <ButtonTag
      type={submit ? "submit" : "button"}
      {...anchorProps}
      className={clsx(
        className,
        getButtonClasses(props),
        loading ? "pointer-events-none cursor-wait" : ""
      )}
      disabled={disabled}
      data-disabled={disabled}
      {...rest}
    >
      {loading ? (
        <Loader size={LOADER_SIZE[size ?? "base"]} className={loaderClass} />
      ) : (
        <div className="flex items-center justify-center space-x-2 ">
          {iconLeft && (
            <Icon
              {...iconLeft}
              className={clsx(leftIconSizeClasses[size!], iconLeft.className)}
            />
          )}
          {children && <div>{children}</div>}
          {iconRight && (
            <Icon
              {...iconRight}
              className={clsx(
                rightIconSizeClasses[size!],
                iconRight?.className
              )}
            />
          )}
        </div>
      )}
    </ButtonTag>
  );
}
