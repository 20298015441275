export const CrossIcon = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.1"
        d="M16 3C13.4288 3 10.9154 3.76244 8.77759 5.1909C6.63975 6.61935 4.97351 8.64968 3.98957 11.0251C3.00563 13.4006 2.74819 16.0144 3.2498 18.5362C3.75141 21.0579 4.98953 23.3743 6.80762 25.1924C8.6257 27.0105 10.9421 28.2486 13.4638 28.7502C15.9856 29.2518 18.5995 28.9944 20.9749 28.0104C23.3503 27.0265 25.3807 25.3603 26.8091 23.2224C28.2376 21.0846 29 18.5712 29 16C28.9964 12.5533 27.6256 9.24882 25.1884 6.81163C22.7512 4.37445 19.4467 3.00364 16 3Z"
        fill="#2F4F4F"
      />
      <path
        d="M20.7076 19.2921C20.8005 19.385 20.8742 19.4953 20.9245 19.6167C20.9747 19.7381 21.0006 19.8682 21.0006 19.9996C21.0006 20.131 20.9747 20.2611 20.9245 20.3825C20.8742 20.5039 20.8005 20.6142 20.7076 20.7071C20.6147 20.8 20.5044 20.8737 20.383 20.924C20.2616 20.9743 20.1315 21.0001 20.0001 21.0001C19.8687 21.0001 19.7386 20.9743 19.6172 20.924C19.4958 20.8737 19.3855 20.8 19.2926 20.7071L16.0001 17.4133L12.7076 20.7071C12.6147 20.8 12.5044 20.8737 12.383 20.924C12.2616 20.9743 12.1315 21.0001 12.0001 21.0001C11.8687 21.0001 11.7386 20.9743 11.6172 20.924C11.4958 20.8737 11.3855 20.8 11.2926 20.7071C11.1997 20.6142 11.126 20.5039 11.0757 20.3825C11.0254 20.2611 10.9995 20.131 10.9995 19.9996C10.9995 19.8682 11.0254 19.7381 11.0757 19.6167C11.126 19.4953 11.1997 19.385 11.2926 19.2921L14.5863 15.9996L11.2926 12.7071C11.1049 12.5194 10.9995 12.2649 10.9995 11.9996C10.9995 11.7342 11.1049 11.4797 11.2926 11.2921C11.4802 11.1044 11.7347 10.999 12.0001 10.999C12.2654 10.999 12.5199 11.1044 12.7076 11.2921L16.0001 14.5858L19.2926 11.2921C19.3855 11.1992 19.4958 11.1255 19.6172 11.0752C19.7386 11.0249 19.8687 10.999 20.0001 10.999C20.1315 10.999 20.2616 11.0249 20.383 11.0752C20.5044 11.1255 20.6147 11.1992 20.7076 11.2921C20.8005 11.385 20.8742 11.4953 20.9245 11.6167C20.9747 11.7381 21.0006 11.8682 21.0006 11.9996C21.0006 12.131 20.9747 12.2611 20.9245 12.3825C20.8742 12.5039 20.8005 12.6142 20.7076 12.7071L17.4138 15.9996L20.7076 19.2921Z"
        fill="#2F4F4F"
      />
    </svg>
  );
};
export const CheckIcon = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 0.875C7.39303 0.875 5.82214 1.35152 4.486 2.24431C3.14985 3.1371 2.10844 4.40605 1.49348 5.8907C0.87852 7.37535 0.717618 9.00901 1.03112 10.5851C1.34463 12.1612 2.11846 13.6089 3.25476 14.7452C4.39106 15.8815 5.8388 16.6554 7.4149 16.9689C8.99099 17.2824 10.6247 17.1215 12.1093 16.5065C13.594 15.8916 14.8629 14.8502 15.7557 13.514C16.6485 12.1779 17.125 10.607 17.125 9C17.1227 6.84581 16.266 4.78051 14.7427 3.25727C13.2195 1.73403 11.1542 0.877275 9 0.875ZM12.5672 7.56719L8.19219 11.9422C8.13415 12.0003 8.06522 12.0464 7.98934 12.0779C7.91347 12.1093 7.83214 12.1255 7.75 12.1255C7.66787 12.1255 7.58654 12.1093 7.51067 12.0779C7.43479 12.0464 7.36586 12.0003 7.30782 11.9422L5.43282 10.0672C5.31554 9.94991 5.24966 9.79085 5.24966 9.625C5.24966 9.45915 5.31554 9.30009 5.43282 9.18281C5.55009 9.06554 5.70915 8.99965 5.875 8.99965C6.04086 8.99965 6.19992 9.06554 6.31719 9.18281L7.75 10.6164L11.6828 6.68281C11.7409 6.62474 11.8098 6.57868 11.8857 6.54725C11.9616 6.51583 12.0429 6.49965 12.125 6.49965C12.2071 6.49965 12.2884 6.51583 12.3643 6.54725C12.4402 6.57868 12.5091 6.62474 12.5672 6.68281C12.6253 6.74088 12.6713 6.80982 12.7027 6.88569C12.7342 6.96156 12.7504 7.04288 12.7504 7.125C12.7504 7.20712 12.7342 7.28844 12.7027 7.36431C12.6713 7.44018 12.6253 7.50912 12.5672 7.56719Z"
        fill="#057A55"
      />
    </svg>
  );
};

export const PlaceHolderImage = () => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 375 375"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_1240_37507"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="375"
        height="375"
      >
        <circle cx="187.5" cy="187.5" r="187.5" fill="#EDF2FA" />
      </mask>
      <g mask="url(#mask0_1240_37507)">
        <circle
          cx="187.5"
          cy="185.5"
          r="187.5"
          fill="#EDF2FA"
          fillOpacity="0.5"
        />
        <g opacity="0.8">
          <g opacity="0.5">
            <g filter="url(#filter0_d_1240_37507)">
              <rect
                x="328"
                y="95"
                width="159.903"
                height="180"
                rx="2"
                fill="white"
              />
            </g>
            <path
              d="M338.485 105.486H476.544V156.166H338.485V105.486Z"
              fill="#E2E8F0"
            />
            <path
              d="M338.485 244.418H476.544V264.515H338.485V244.418Z"
              fill="#E2E8F0"
            />
            <rect
              x="338.485"
              y="166.65"
              width="67.2816"
              height="6.99029"
              rx="3.49515"
              fill="#E2E8F0"
            />
            <rect
              x="338.485"
              y="180.631"
              width="42.8155"
              height="6.99029"
              rx="3.49515"
              fill="#E2E8F0"
            />
            <rect
              x="338.485"
              y="212.961"
              width="69.0291"
              height="6.99029"
              rx="3.49515"
              fill="#E2E8F0"
            />
            <rect
              x="338.485"
              y="198.98"
              width="69.0291"
              height="6.99029"
              rx="3.49515"
              fill="#E2E8F0"
            />
            <rect
              x="338.485"
              y="226.941"
              width="36.699"
              height="6.99029"
              rx="3.49515"
              fill="#E2E8F0"
            />
            <circle cx="363.825" cy="130.825" r="16.6019" fill="white" />
          </g>
        </g>
        <g opacity="0.8">
          <g opacity="0.5">
            <g filter="url(#filter1_d_1240_37507)">
              <rect
                x="-113"
                y="95"
                width="159.903"
                height="180"
                rx="2"
                fill="white"
              />
            </g>
            <path
              d="M-102.515 105.486H35.5437V156.166H-102.515V105.486Z"
              fill="#E2E8F0"
            />
            <path
              d="M-102.515 244.418H35.5437V264.515H-102.515V244.418Z"
              fill="#E2E8F0"
            />
            <rect
              x="-50.0874"
              y="119.467"
              width="76.8932"
              height="6.99029"
              rx="3.49515"
              fill="white"
            />
            <rect
              x="-12.5145"
              y="180.631"
              width="29.7087"
              height="6.99029"
              rx="3.49515"
              fill="#E2E8F0"
            />
            <rect
              x="-12.5145"
              y="166.65"
              width="29.7087"
              height="6.99029"
              rx="3.49515"
              fill="#E2E8F0"
            />
            <rect
              x="-12.5145"
              y="212.961"
              width="48.0583"
              height="6.99029"
              rx="3.49515"
              fill="#E2E8F0"
            />
            <rect
              x="-12.5145"
              y="198.98"
              width="48.0583"
              height="6.99029"
              rx="3.49515"
              fill="#E2E8F0"
            />
            <rect
              x="-12.5146"
              y="226.941"
              width="25.3398"
              height="6.99029"
              rx="3.49515"
              fill="#E2E8F0"
            />
          </g>
        </g>
        <g filter="url(#filter2_d_1240_37507)">
          <rect x="96" y="83" width="183" height="206" rx="2" fill="white" />
        </g>
        <path d="M108 95H266V153H108V95Z" fill="#E2E8F0" />
        <path d="M108 254H266V277H108V254Z" fill="#E2E8F0" />
        <rect x="168" y="111" width="88" height="8" rx="4" fill="white" />
        <rect
          x="168"
          y="127.49"
          width="50.58"
          height="8.43"
          rx="4.215"
          fill="white"
        />
        <rect x="108" y="165" width="77" height="8" rx="4" fill="#E2E8F0" />
        <rect x="108" y="181" width="49" height="8" rx="4" fill="#E2E8F0" />
        <rect x="108" y="218" width="79" height="8" rx="4" fill="#E2E8F0" />
        <rect x="108" y="202" width="79" height="8" rx="4" fill="#E2E8F0" />
        <rect x="108" y="234" width="42" height="8" rx="4" fill="#E2E8F0" />
        <rect x="211" y="181" width="34" height="8" rx="4" fill="#E2E8F0" />
        <rect x="211" y="165" width="34" height="8" rx="4" fill="#E2E8F0" />
        <rect x="211" y="218" width="55" height="8" rx="4" fill="#E2E8F0" />
        <rect x="211" y="202" width="55" height="8" rx="4" fill="#E2E8F0" />
        <rect x="211" y="234" width="29" height="8" rx="4" fill="#E2E8F0" />
        <circle cx="137" cy="124" r="19" fill="white" />
      </g>
      <defs>
        <filter
          id="filter0_d_1240_37507"
          x="293"
          y="64"
          width="229.903"
          height="250"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="17.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1240_37507"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1240_37507"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d_1240_37507"
          x="-148"
          y="64"
          width="229.903"
          height="250"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="17.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1240_37507"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1240_37507"
            result="shape"
          />
        </filter>
        <filter
          id="filter2_d_1240_37507"
          x="61"
          y="52"
          width="253"
          height="276"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="17.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1240_37507"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1240_37507"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};
