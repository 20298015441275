import { jwtDecode } from "jwt-decode";
import { Auth, refetchToken } from "./auth";
import useSWR, { Middleware, SWRConfiguration } from "swr";
import { notifyError } from "../utils/notify";
import { ConsoleHelper } from "../utils/helper";
interface Error {
  response: { error?: unknown; message?: string };
  status: number;
}
interface IError {
  message?: string | Array<string> | undefined;
  status?: number;
}
const getError = (
  error: Error,
  logout: (to?: string) => void
): IError | null => {
  const { auth } = Auth();
  if (!error) return null;
  const { response, status } = error;
  if (status === 500) {
    return {
      message: "Unable to connect with server. Try again after sometime",
    };
  } else if (status === 404) {
    return {
      message: "404 Not Found",
    };
  } else if ((status === 401 || status === 403) && auth?.token) {
    if (status === 401 && response?.message === "password_expired") {
      logout("/password-expiry");
    } else {
      logout();
    }
    return {
      message: "Unauthorized user",
    };
  } else {
    return { message: response?.message, status: status };
  }
};

const swrConfig: SWRConfiguration = {
  onErrorRetry: (
    error: any,
    _key: string,
    _option: any,
    revalidate: any,
    { retryCount }: { retryCount: number }
  ) => {
    const { response } = error;
    if (retryCount >= 3) return;
    if (response && response.status === 404) return;
    setTimeout(() => revalidate({ retryCount: retryCount + 1 }), 5000);
  },
};
type CustomSWRConfig = SWRConfiguration & {
  onErrorRetry: (
    error: any,
    key: string,
    option: any,
    revalidate: any,
    { retryCount }: { retryCount: number }
  ) => void;
};

const swrCustomConfig: CustomSWRConfig = {
  onErrorRetry: (
    error: any,
    _key: string,
    _option: any,
    revalidate: any,
    { retryCount }: { retryCount: number }
  ) => {
    const { response } = error;
    if (retryCount >= 3) return;
    if (response && response.status === 404) return;
    setTimeout(() => revalidate({ retryCount: retryCount + 1 }), 5000);
  },
  revalidateOnFocus: false,
};

type SwrDefaults = boolean;

//A
export function useAPI<DataType, ErrorType = IError | null>(
  url: string | null,
  {
    token,
    baseURL,
    swrDefaults = false,
    middlewares,
    logout,
  }: {
    baseURL: string;
    token?: string;
    swrDefaults?: SwrDefaults;
    customFetcher?: boolean;
    middlewares: Middleware[];
    logout: () => void;
  }
) {
  async function fetcher<JSON = any>(
    input: RequestInfo,
    init?: RequestInit
  ): Promise<JSON> {
    let res = null;
    let contentType = null;

    let { auth, login, logout } = Auth();
    try {
      if (auth.refreshToken) {
        const currentTime = Math.floor(Date.now() / 1000);
        // const currentTime = Date.now();
        const { exp } = jwtDecode(auth.token);

        if (exp && exp < currentTime) {
          try {
            const data = await refetchToken(auth.refreshToken);

            login(
              {
                token: data.token,
                refreshToken: auth.refreshToken,
                authType: "sso",
              },
              "",
              false
            );
            auth = Auth().auth;
          } catch (error: any) {
            notifyError(error.message || "Something went wrong");
            logout();
          }
        }
      }
      if (!init && token !== undefined) {
        init = {
          headers: {
            Authorization:
              auth.authType === "sso"
                ? "Bearer ".concat(auth.token)
                : "Basic ".concat(auth.token),
          },
        };
      }
      const url = new URL(input.toString(), baseURL);
      res = await fetch(url, init);
      contentType = res.headers.get("content-type");
      if (!res.ok) {
        const response = contentType?.toLowerCase().includes("text/plain")
          ? await res.text()
          : await res.json();

        ConsoleHelper(res);

        const error = {
          response: response,
          status: res.status,
        };
        throw error;
      }
      return contentType?.toLowerCase().includes("text/plain")
        ? res.text()
        : res.json();
    } catch (error) {
      throw error;
    }
  }

  const config: SWRConfiguration = swrDefaults ? swrConfig : swrCustomConfig;

  const { data, error, mutate, isValidating, isLoading } = useSWR(
    url,
    fetcher,
    { use: middlewares, ...config }
  );

  return {
    data: data as DataType,
    error: error && (getError(error, logout) as ErrorType),
    loading: isLoading,
    mutate,
    isValidating,
  };
}
