function getErrorMessage(response: any): string {
  const { error, message } = response;

  if (error) {
    const { message: errorMessage } = error[0];
    if (typeof errorMessage === "string") {
      return errorMessage;
    } else if (typeof errorMessage === "object") {
      const keys = Object.keys(errorMessage);
      return errorMessage[keys[0]];
    }
  }
  return message || "Invalid data";
}

export { getErrorMessage };

export const convertUptime = (seconds: number) => {
  const days = Math.floor(seconds / (24 * 3600));
  const hours = Math.floor((seconds % (24 * 3600)) / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  // const secs = Math.floor(seconds % 60);

  let result = "";

  if (days === 1) {
    result += `${days} day `;
  } else if (days > 1) {
    result += `${days} days `;
  }

  if (hours === 1) {
    result += `${hours} hour `;
  } else if (hours > 1) {
    result += `${hours} hours `;
  }

  if (minutes === 1) {
    result += `${minutes} minute `;
  } else if (minutes > 1) {
    result += `${minutes} minutes `;
  }

  if (seconds > 0 && seconds < 60 && result === "") {
    result += "<1 minute";
  }

  return result.trim(); // Trim any extra spaces
};

export const convertRamInfo = (mb: string) => {
  const gb = Number(mb) / 1024;
  if (gb) return `${gb.toFixed(2)} GB`;
  return mb;
};

export function processString(input: string): string {
  // Split the input string by commas
  const parts = input.split(",");

  // Get the left-hand side (first element in the array)
  const leftHandSide = parts[0].trim();

  // Count the remaining elements in the array
  const remainingCount = parts.length - 1;

  // Return the left-hand side with a '+' and the count of remaining elements
  return remainingCount > 0
    ? `${leftHandSide} (+ ${remainingCount})`
    : leftHandSide;
}

export function tableHeaderValues(columnName: string): string {
  // Split the string by underscores and handle each part separately
  const parts = columnName.split("_").map((part) => {
    // Convert camelCase to normal case and capitalize each word
    return part
      .replace(/([a-z])([A-Z])/g, "$1 $2")
      .replace(/(^\w|\s\w)/g, (m) => m.toUpperCase());
  });

  // Join the parts back together with spaces
  return parts.join(" ");
}

export function formatDateToUS(dateString: string, showSeconds = true): string {
  const date = new Date(dateString);
  const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  return date.toLocaleString("en-US", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: showSeconds ? "2-digit" : undefined,
    hour12: true, // Change to true if you want 12-hour format with AM/PM
    timeZone: localTimeZone,
    timeZoneName: "shortGeneric",
  });
}

export const formatMemoryValue = (
  value: number,
  preferredUnit: string = ""
) => {
  if (value < 1024) return value.toFixed(2) + preferredUnit;
  // Define the units in order
  const units = ["B", "KB", "MB", "GB", "TB"];

  // Find the index of the preferred unit
  let preferredUnitIndex = units.indexOf(preferredUnit);

  // Check if the preferred unit is valid
  if (preferredUnitIndex === -1) {
    preferredUnitIndex = 0;
  }

  // Adjust the value according to the preferred unit
  for (let i = 0; i < preferredUnitIndex; i++) {
    value /= 1024;
  }

  // Format the value correctly
  let formattedValue = value.toFixed(2) + units[preferredUnitIndex];

  // If the preferred unit is bytes, and value is greater than or equal to 1024, convert up
  while (value >= 1024 && preferredUnitIndex < units.length - 1) {
    value /= 1024;
    preferredUnitIndex++;
    formattedValue = value.toFixed(2) + units[preferredUnitIndex];
  }

  return formattedValue;
};

export const getRangeDateString = (daysAgo: number) => {
  const date = new Date();
  date.setDate(date.getDate() - daysAgo);
  return date.toISOString(); // This will return the date in the format YYYY-MM-DDTHH:mm:SS.sssZ
};

export function generateBase64Token(
  tenantId: string,
  siteId: string,
  ip: string,
  port: string
): string {
  const combinedString = `${tenantId}|${siteId}|${ip}|${port}`;
  return btoa(combinedString); // btoa() is used to encode a string in base64
}

export const ConsoleHelper = (data: any) => {
  if (process.env.NODE_ENV === "production") return;
  return console.log(data);
};
