import { Fragment, useEffect, useState } from "react";
import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
  Transition,
} from "@headlessui/react";
import { Icon, ICON_NAME } from "../Icon";
import { Option } from "../../utils/interface";

export interface IDropdown {
  label?: string;
  options: Option[];
  onChange: Function;
  initialValue?: Option | null;
  value?: string | null;
  customClass?: string;
  disabled?: boolean;
  size?: "xs" | "sm" | "base";
}

const InputSizeClasses = {
  xs: "py-1 text-xs/6 rounded",
  sm: "py-2 text-sm/6 rounded-md",
  base: "px-4 py-3 sm:text-base/6 rounded-lg",
};

export function Dropdown({
  label,
  options,
  onChange,
  value,
  initialValue,
  customClass,
  disabled = false,
  size = "sm",
}: IDropdown) {
  const initValue = initialValue ?? options.find((opt) => opt.value === value);
  const [selected, setSelected] = useState<Option | null>(null);

  const handleSelected = (val: Option) => {
    setSelected(val);
    onChange(val);
  };

  useEffect(() => {
    if (initValue) {
      setSelected(initValue);
    }
  }, [initValue]);

  return (
    <div className="w-full max-w-md ">
      <Listbox
        value={selected}
        onChange={(val: Option | null) => {
          if (!disabled && val !== null) {
            handleSelected(val);
          }
        }}
        disabled={disabled}
      >
        <div className="relative">
          <ListboxButton
            className={`relative w-full cursor-${
              disabled ? "not-allowed" : "pointer"
            } border  bg-white pl-3 pr-10 text-left focus-visible:border-theme-disabled focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-gray-300 sm:text-sm ${
              disabled ? "border-gray-200 !bg-gray-50" : "border-neutral-300"
            }  data-[active]:border-theme-gray ${InputSizeClasses[size]} ${customClass}`}
          >
            <span className="block truncate">
              {selected ? selected.label : label}
            </span>
            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              <Icon name={ICON_NAME.chevronDown} />
            </span>
          </ListboxButton>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <ListboxOptions className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-left text-base shadow-lg ring-1 ring-black/5 focus:outline-none">
              {options.map((option, optionIdx) => (
                <ListboxOption
                  key={optionIdx}
                  className={({ focus }) =>
                    `relative cursor-${
                      disabled ? "not-allowed" : "pointer"
                    } select-none py-2 pl-4 pr-4 ${
                      focus && !disabled ? "bg-[#f2eef7]" : "text-black"
                    } ${disabled ? "opacity-50" : ""}`
                  }
                  value={option}
                  disabled={disabled}
                >
                  {({ selected }) => (
                    <>
                      <span
                        className={`block truncate  pl-4 ${
                          selected ? "font-medium" : "font-normal"
                        }`}
                      >
                        {option.label}
                      </span>
                      {selected ? (
                        <div className="absolute inset-y-0 left-0 flex items-center pl-3 text-black">
                          <Icon name={ICON_NAME.check} />
                        </div>
                      ) : null}
                    </>
                  )}
                </ListboxOption>
              ))}
            </ListboxOptions>
          </Transition>
        </div>
      </Listbox>
    </div>
  );
}
