import { ReactNode } from "react";
import { PlaceHolderImage } from "../assets";

export interface IPlaceholder {
  label: string;
  description: string;
  placeHolderImg?: ReactNode;
}

export const Placeholder = ({
  placeHolderImg,
  label,
  description,
}: IPlaceholder) => {
  return (
    <div className="flex w-full flex-col items-center justify-center gap-6">
      <div> {placeHolderImg ?? <PlaceHolderImage />}</div>
      <div className="flex w-full flex-col items-center gap-1.5">
        <h4 className="text-base font-semibold leading-5 text-black">
          {label}
        </h4>
        <p className="text-sm font-normal leading-4 text-theme-gray">
          {description}
        </p>
      </div>
    </div>
  );
};
