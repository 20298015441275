import { IRequest } from "../../utils/net";
import { NetWrapper } from "../../utils/netwrapper";

export interface ITenant {
  id?: number;
  uuid?: string;
  tenantName: string;
  firstName: string;
  lastName: string;
  contact: string;
  street: string;
  city: string;
  state: string;
  country: string;
  zipCode: string;
  description?: string;
  email?: string;
  externalUserId?: string;
  created?: string;
  updated?: string;
}

export const updateTenant = async (id: string, payload: ITenant) => {
  const url = `/api/v1/tenants/${id}`;
  const options: IRequest = {
    method: "PUT",
    body: JSON.stringify(payload),
  };

  const { data, error } = await NetWrapper(url, options);
  return { data, error };
};
