import { Fragment } from "react";
import {
  Dialog,
  DialogPanel,
  Transition,
  TransitionChild,
} from "@headlessui/react";
import clsx from "clsx";

import { Button, ButtonProps } from "../Button";
import { Icon, ICON_NAME } from "../Icon";

export interface ModalProps {
  id?: string;
  children: React.ReactNode;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  footerActionItemsItems?: ButtonProps[];

  alignMid?: boolean;
  classname?: string;
  dialogClassName?: string;
  clearState?: () => void;
  searchQuery?: string;
  title?: string;
  titleClassName?: string;
  showCloseButton?: boolean;
}

export function Modal({
  children,
  isOpen = false,
  setIsOpen,
  alignMid,
  classname,
  dialogClassName,
  clearState,
  id,
  title,
  searchQuery,
  titleClassName,
  footerActionItemsItems,
  showCloseButton,
}: ModalProps) {
  return (
    <Transition show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-[99]"
        onClose={() => {
          setIsOpen(!isOpen);
        }}
      >
        <TransitionChild
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-50 transition-opacity" />
        </TransitionChild>

        <div
          className={clsx(
            alignMid ? "inset-x-0 inset-y-56" : "inset-0",
            "fixed z-10 overflow-y-auto"
          )}
        >
          <div
            className={clsx(
              "flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0",
              classname
            )}
          >
            <TransitionChild
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <DialogPanel
                className={clsx(
                  "relative w-full min-w-[calc(20%-32px)] max-w-md transform rounded-lg bg-white text-left shadow-xl transition-all sm:w-auto",
                  dialogClassName
                )}
              >
                {title && (
                  <div
                    className={clsx(
                      "flex items-center justify-between border-b p-6 text-lg font-semibold capitalize text-[#111827]",
                      titleClassName
                    )}
                  >
                    <div className="flex-1 text-center">{title}</div>
                    {showCloseButton && (
                      <button
                        onClick={() => setIsOpen(!isOpen)}
                        className="ml-auto"
                        title="Close"
                      >
                        <Icon
                          name={ICON_NAME.x}
                          weight="bold"
                          className="h-5 w-5 cursor-pointer text-theme-gray"
                        />
                      </button>
                    )}
                  </div>
                )}
                {children}
                {footerActionItemsItems &&
                  footerActionItemsItems.length > 0 && (
                    <div className="w-full border-t px-7 py-6">
                      <div className="flex w-full items-start justify-start space-x-4">
                        {footerActionItemsItems.map((props) => {
                          return <Button {...props} />;
                        })}
                      </div>
                    </div>
                  )}
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
