import { Placeholder } from "../stories";
import { Button, BUTTON_KIND, BUTTON_SIZE } from "../stories/Button";
import { Icon, ICON_NAME } from "../stories/Icon";

export const ErrorScreen = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const message = queryParams.get("message");

  return (
    <div className="flex h-lvh flex-col items-center justify-center">
      <Placeholder
        placeHolderImg={
          <Icon name={ICON_NAME.warning} size={70} className=" text-red-600" />
        }
        label={message ?? "Something went wrong"}
        description={
          message?.toLowerCase().includes("not find saml")
            ? "We couldn't find an account associated with this domain. Please ensure it's correct, or reach out to support via the Contact Us link below."
            : ""
        }
      />
      <Button
        kind={BUTTON_KIND.plain}
        size={BUTTON_SIZE.xl}
        className="mt-20 !p-0"
        url="https://www.armorx.ai/contact-us"
      >
        Contact Us
      </Button>
    </div>
  );
};
