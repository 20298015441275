export interface RegisterStepsProps {
  heading: String;
  description?: String | React.ReactNode;
  placeholder: string;
}

export const RegisterSteps: React.FC<RegisterStepsProps> = ({
  heading,
  description,
  placeholder,
}) => {
  return (
    <div className="max-w-lg lg:pt-10 xl:pt-28">
      <div className="flex flex-col">
        <div className="pb-24 text-3xl font-bold text-white">{heading}</div>
        <div className="pb-10 text-white">{description}</div>
        <div>
          <img className="h-full w-full" alt="placeholder" src={placeholder} />
        </div>
      </div>
    </div>
  );
};

RegisterSteps.defaultProps = {
  heading: "false",
  description: (
    <div className="flex flex-col gap-10 ">
      <p>
        We will now create a <b>root</b> account for you. The <b>root</b>
        account grants you full access and control over all your devices
        protected with ArmorxAI. You will be able to login with this account
        even if you enable SSO
      </p>
      <p className="pb-10 font-bold text-white">
        Please safeguard this account credentials.
      </p>
    </div>
  ),
};
